<template>
  <div class="row m-0">
    <div class="col-9">
      {{ label }}
      <validation-errors :messages="errors">
        <Datepicker
          locale="jp"
          cancel-text="キャンセル"
          select-text="選択"
          v-model="datetime"
          format="yyyy/MM/dd HH:mm"
          :flow="['calendar']"
          :text-input="false"
          :clearable="false"
          autoApply
          :closeOnAutoApply="false"
          :error="errors?.length ?? 0 > 0"
        >
          <template #time-picker="{ time, updateTime }">
            <div class="custom-time-picker-component">
              <div class="custom-time-picker-hours">
                <select
                  class="select-input"
                  :value="time.hours"
                  @change="updateTime(+$event.target.value)"
                >
                  <option
                    v-for="h in hoursArray"
                    :key="h.value"
                    :value="h.value"
                  >
                    {{ h.text }}
                  </option>
                </select>
                <span>時</span>
              </div>
              <div class="custom-time-picker-minutes">
                <select
                  class="select-input"
                  :value="time.minutes"
                  @change="updateTime(+$event.target.value, false)"
                >
                  <option
                    v-for="m in minutesArray"
                    :key="m.value"
                    :value="m.value"
                  >
                    {{ m.text }}
                  </option>
                </select>
                <span>分</span>
              </div>
            </div>
          </template>
        </Datepicker>
      </validation-errors>
    </div>
  </div>
</template>

<script setup>
import Datepicker from '@vuepic/vue-datepicker';
import { ref, defineProps, defineEmits, watch, computed } from 'vue';
import ValidationErrors from '@/components/Common/ValidationErrors.vue';

const props = defineProps(['label', 'modelValue', 'errors']);
const label = ref(props.label);

const emit = defineEmits(['update:modelValue']);

const tenMinutesForUnixTime = 10 * 60 * 1000;
const floorByTenMinutes = (time) => {
  return new Date(
    Math.floor(time.getTime() / tenMinutesForUnixTime) * tenMinutesForUnixTime
  );
};

const datetime = computed({
  get() {
    return floorByTenMinutes(props.modelValue);
  },
  set(value) {
    emit("update:modelValue", value);
  },
})

const hoursArray = computed(() => {
  const arr = [];
  for (let i = 0; i < 24; i++) {
    arr.push({ text: i < 10 ? `0${i}` : i, value: i });
  }
  return arr;
});

const minutesArray = computed(() => {
  const arr = [];
  for (let i = 0; i < 6; i++) {
    arr.push({ text: i == 0 ? 0 : i * 10, value: i * 10 });
  }
  return arr;
});
</script>

<style scoped lang="scss">
.custom-time-picker-component {
  margin: 1vh 0vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-time-picker-hours,
.custom-time-picker-minutes {
  width: 50%;
  display: table-column;
  text-align: center;
}
.select-input {
  text-align: end;
  margin: 5px 3px;
  padding: 5px;
  border-radius: 4px;
  border-color: var(--dp-border-color);
  outline: none;
  -webkit-appearance: menulist;
}
</style>
