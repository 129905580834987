import vehicle from '@/types/vehicle'
import subscribeToVehicleResponse from '@/types/responses/subscribeToVehicleResponse'
import subscribeToSensorResponse from '@/types/responses/subscribeToSensorResponse'
import SubscribeProvider from '@/services/subscribe/providers/SubscribeProvider'
import { Sensor } from '@/API'

class DummyProvider implements SubscribeProvider {
  public sensorAction = async (sensor: Sensor, vehicle: vehicle, sensorAction: (
      vehicle: vehicle,
      payload: Sensor
    ) => void,
  ) => {
    return
  }
  public subscribeToVehicleSubscriptions(
    vehicle: vehicle,
    sensorAction: (
      vehicle: vehicle,
      payload: Sensor
    ) => void,
  ): void {
    return
  }
}

export default DummyProvider
