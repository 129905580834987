<script setup>
import { reactive, watch, ref } from 'vue';

const props = defineProps({
  companyName: { type: String },
  companyNameKana: { type: String },
  errors: { type: Object },
  windowInnerWidth: Number,
});

const emits = defineEmits(['inputValue']);
const name = ref('');
const kana = ref('');
const data = reactive({ company_name: '', company_name_japan: '' });

const unwatchName = watch(
  () => props.companyName,
  (propsCompanyName) => {
    name.value = propsCompanyName;
    unwatchName();
  }
);

const unwatchKana = watch(
  () => props.companyNameKana,
  (propsCompanyNameKana) => {
    kana.value = propsCompanyNameKana;
    unwatchKana();
  }
);

watch(name, () => {
  data.company_name = name;
  emits('inputValue', data);
});

watch(kana, () => {
  data.company_name_japan = kana;
  emits('inputValue', data);
});
</script>
<template>
  <v-row class="text-form pl-4 pr-3 mb-3">
    <v-col :cols="windowInnerWidth > 800 ? 2 : 3" class="my-auto">
      <h6 class="pl-4">社名<span style="color: red">✴︎</span></h6>
    </v-col>
    <v-col :cols="windowInnerWidth > 800 ? 10 : 9" class="pt-0">
      <v-text-field
        v-model="name"
        maxlength="255"
        :error-messages="errors?.company_name"
      />
    </v-col>
  </v-row>
  <v-row class="text-form pl-4 pr-3 mb-3">
    <v-col :cols="windowInnerWidth > 800 ? 2 : 3" class="my-auto">
      <h6 class="pl-4">社名(カナ)<span style="color: red">✴︎</span></h6>
    </v-col>
    <v-col :cols="windowInnerWidth > 800 ? 10 : 9" class="pt-0">
      <v-text-field
        v-model="kana"
        maxlength="255"
        :error-messages="errors?.company_name_japan"
      />
    </v-col>
  </v-row>
</template>
