<template>
  <template v-if="!!formInputs?.form?.pulse">
    <p class="vehicle-ini-setting-title">PULSE</p>
    <div class="row mr-3 v-theme--light">
      <template v-for="title, key in keys" v-bind:key="key">
        <Input
          :label="title"
          v-model="form[key]"
          :formKey="key"
          parentKey="pulse"
          :formInputs="formInputs"
          :readonly="selectDefault && readonlyInputs.includes(key)"
          :errors="errors[errorKeyPrefix+'.'+key]"
        ></Input>
      </template>
    </div>
  </template>
</template>

<script>
import Api from "@/services/api/ApiServiceFabrick";
import Input from "@/components/VehicleFormParts/SettingFormInput.vue";

export default {
  props: {
    modelValue: {},
    formInputs: {},
    selectDefault: {
      type: Boolean,
      default: false
    },
    errors: {},
  },
  data() {
    return {
      form: {},
      readonlyInputs: [],
      errorKeyPrefix: 'initial_params.pulse',
      keys: {
        'speed_pulse': '車速パルス係数',
        'tacho_pulse': 'エンジン回転係数',
        'speed_level': 'スピードレベル（0＝電圧、1＝電流）',
        'tacho_level': 'エンジン回転レベル（0＝電圧、1＝電流）',
        'speed_threshold': '速度パルスレベル',
        'tacho_threshold': '回転パルスレベル',
        'speed_limit_normal': '一般道速度超過（km）',
        'speed_limit_high': '高速道速度超過（km）',
        'tacho_limit_normal': '一般道エンジン回転超過（rpm）',
        'tacho_limit_high': '高速道エンジン回転超過（rpm）',
      }
    }
  },
  methods: {
  },
  watch: {
    modelValue: {
      handler() {
        this.form = this.modelValue
      }
    },
    formInputs: {
      handler() {
        if (!this.formInputs?.form?.pulse) return
        const key = Object.keys(this.formInputs.default_params)[0]
        this.readonlyInputs = Object.keys(this.formInputs.default_params[key]?.parameters?.pulse ?? [])
      }
    },
    form: {
      handler() {
        this.$emit('update:modelValue', this.form)
      },
      deep: true
    },
  },
  components: {
    Input,
  },
}
</script>

<style>
</style>
