import conn from "@/services/ApiConnection"
import Response from "@/types/responses/getDeliveryPlanDrivingStoppingPeriod"
import DeliveryPlanEvent from "@/types/DeliveryPlanEvent"
import { 
  formatDateTimeWithSeconds,
} from "@/services/functions";

class DeliveryPlanDrivingStoppingPeriod {
  public static async get(delivery_plan_id:string):Promise<Response[]> {
    const res = await conn.get(`/api/v1/delivery_plans/${delivery_plan_id}/driving_stopping_periods`)
    return new Promise((resolve) => {
      const data = res?.data || []
      resolve(data)
    })
  }

  public static async getStoppingPeriodsWithSpot(delivery_plan_id:string, operation_office_id: number):Promise<Response[]> {
    const res = await conn.get(`/api/v1/delivery_plans/${delivery_plan_id}/stopping_periods_with_spot?operation_office_id=${operation_office_id}`)
    return new Promise((resolve) => {
      const data = res?.data || []
      resolve(data)
    })
  }

  public static filterStoppingStatus(drivingStoppingPeriods:Response[]) {
    const stoppingPeriods = drivingStoppingPeriods.filter((drivingStoppingPeriod) => {
      return drivingStoppingPeriod.status == "stopping"
    })

    return stoppingPeriods
  }

  public static roundStoppingPeriods(stoppingPeriods:Response[]) {
    stoppingPeriods.map((stoppingPeriod) => {
      const start = Math.ceil(new Date(stoppingPeriod['start']).valueOf()/1000)
      const end = Math.floor(new Date(stoppingPeriod['end']).valueOf()/1000)
      stoppingPeriod['start'] = this.convertDateToString(new Date(start*1000))
      stoppingPeriod['end'] = this.convertDateToString(new Date(end*1000))
    })

    return stoppingPeriods
  }

  public static getOverlappingRange(minute: any, stoppingPeriods: any[], emptyPeriods: any[]):any[]{
    const periods = [] as any[]
    stoppingPeriods.map((stoppingPeriod) => {
      const stoppingPeriodStart = stoppingPeriod['start'];
      const stoppingPeriodEnd = stoppingPeriod['end'];

      emptyPeriods.map((emptyPeriod) => {
        const emptyPeriodStart = emptyPeriod.start
        const emptyPeriodEnd = emptyPeriod.end

        let start = null
        let end = null
        if(
          (emptyPeriodStart <= stoppingPeriodStart && stoppingPeriodStart <= emptyPeriodEnd) &&
          (emptyPeriodStart <= stoppingPeriodEnd && stoppingPeriodEnd <= emptyPeriodEnd)
        )
        {
          start = stoppingPeriodStart
          end = stoppingPeriodEnd
        }
        else if(
          (stoppingPeriodStart < emptyPeriodStart && emptyPeriodStart < stoppingPeriodEnd) &&
          (stoppingPeriodStart < emptyPeriodEnd && emptyPeriodEnd < stoppingPeriodEnd)
        ){
          start = emptyPeriodStart
          end = emptyPeriodEnd
        }
        else if(
          (stoppingPeriodStart < emptyPeriodStart && emptyPeriodStart < stoppingPeriodEnd) &&
          (stoppingPeriodEnd <= emptyPeriodEnd)
        ){
          start = emptyPeriodStart
          end = stoppingPeriodEnd
        }
        else if(
          (emptyPeriodStart <= stoppingPeriodStart) &&
          (stoppingPeriodStart < emptyPeriodEnd && emptyPeriodEnd < stoppingPeriodEnd) 
        ){
          start = stoppingPeriodStart
          end = emptyPeriodEnd
        }

        if(start && end){
          const startTime = new Date(start).valueOf();
          const endTime = new Date(end).valueOf();
          const diffTime = endTime - startTime;
          if(minute * 60 * 1000 <= diffTime){
            const stoppingPeriodCopyed = {
              id: stoppingPeriod['id'],
              delivery_plan_id: stoppingPeriod['delivery_plan_id'],
              status: stoppingPeriod['status'],
              start: stoppingPeriod['start'],
              end: stoppingPeriod['end'],
              spot_id: stoppingPeriod['spot_id'],
              spot_name: stoppingPeriod['spot_name'],
              start_latitude: stoppingPeriod['start_latitude'],
              end_latitude: stoppingPeriod['end_latitude'],
              start_longitude: stoppingPeriod['start_longitude'],
              end_longitude: stoppingPeriod['end_longitude'],
              created_at: stoppingPeriod['created_at'],
              updated_at: stoppingPeriod['updated_at'],
            };
            stoppingPeriodCopyed['start'] = this.convertDateToString(new Date(startTime))
            stoppingPeriodCopyed['end'] = this.convertDateToString(new Date(endTime))
            periods.push(stoppingPeriodCopyed)
          }
        }
      })
    })
    return periods
  }

  public static getEmptyPeriodsFromEvent(items:DeliveryPlanEvent[]){
    const emptyPeriods = [] as any[]
    items.map((item, index) => {
      if(index > 0){
        const start = new Date(items[index - 1]['end_time']??'').valueOf() + 1000
        const end = new Date(items[index]['start_time']??'').valueOf() - 1000
        const emptyPeriod = {
          start: this.convertDateToString(new Date(start)),
          end: this.convertDateToString(new Date(end)),
        }
        emptyPeriods.push(emptyPeriod);
      }
    })
    return emptyPeriods
  }

  public static convertStoppingPeriodsToDeliveryPlanWork(drivingStoppingPeriods:Response[]){
    const convertedDatas = [] as any[];
    drivingStoppingPeriods.forEach((data) => {
      let convertedData = {} as any;

      if(data.status == 'stopping'){
        convertedData = {
          delivery_plan_id: data.delivery_plan_id,
          work: "stopping",
          date_time: data.start,
          status: true,
          latitude: data.start_latitude,
          longitude: data.start_longitude,
          spot_id: data.spot_id,
          spot_name: data.spot_name,
          mileage: 0,
          get_spot_name_from_api: false,
          enqueue: false,
          created_at: data.created_at,
          updated_at: data.updated_at,
          edited_at: null,
          added_at: null,
        }
        convertedDatas.push(convertedData)
        convertedData = {
          delivery_plan_id: data.delivery_plan_id,
          work: "stopping",
          date_time: data.end,
          status: false,
          latitude: data.end_latitude,
          longitude: data.end_longitude,
          spot_id: data.spot_id,
          spot_name: data.spot_name,
          mileage: 0,
          get_spot_name_from_api: false,
          enqueue: false,
          created_at: data.created_at,
          updated_at: data.updated_at,
          edited_at: null,
          added_at: null,
        }
        convertedDatas.push(convertedData)
      }
    });
    return convertedDatas;
  }

  public static convertDateToString(date: any){
    const toString = Object.prototype.toString;
    const flg = toString.call(date).slice(8, -1);
    if(flg === 'Date'){
      return formatDateTimeWithSeconds(date)
    }else{
      return date
    }
  }
}

export default DeliveryPlanDrivingStoppingPeriod
