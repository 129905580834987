<template>
  <div :class="searchAreaClass">
    <h4 class="d-flex justify-content-between mt-0">
      {{ title }}
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fit=""
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        sandboxuid="0"
        @click="toggleSidebar"
      >
        <template v-if="hide">
          <path
            d="M7.615 11.795l4.59-4.59-1.41-1.41-6 6 6 6 1.41-1.41-4.59-4.59zm9.59 6v-12h-2v12h2z"
            fill-rule="evenodd"
            sandboxuid="0"
          ></path>
        </template>
        <template v-if="!hide">
          <path
            d="M6 7.41L10.438 12 6 16.59 7.363 18l5.802-6-5.802-6L6 7.41zM16.066 6H18v12h-1.934V6z"
            sandboxuid="0"
          />
        </template>
      </svg>
    </h4>
    <div :class="`strpied-tabled-with-hover ${hide ? 'd-none' : ''}`">
      <div class="d-grid gap-2">
        <v-text-field label="名称" v-model="form.name"></v-text-field>
      </div>
      <div class="d-flex flex-column">
        <div class="col-md-3">
          <svg
            version="1.2"
            style="overflow: visible; width: 299px; height: 1px; opacity: 1; mix-blend-mode: normal; rotate(0deg);"
          >
            <defs>
              <path
                id="path-1650848226989195"
                d="M1126 703 C1126 703 1425 703 1425 703"
              ></path>
            </defs>
            <g transform="translate(-1126, -703)">
              <path
                style="
                  stroke: rgb(194, 186, 186);
                  stroke-width: 1;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  fill: none;
                "
                d="M1126 703 C1126 703 1425 703 1425 703"
              ></path>
            </g>
          </svg>
        </div>
        <div class="d-flex search-buttons">
          <v-btn
            class="ma-2 exec-btn"
            @click="handleClickSearch"
            :style="{
              backgroundColor: loading ? '#B3B8BB' : '',
            }"
          >
            {{ searchBtnLabel }}
          </v-btn>
          <v-btn class="ma-2 reset-btn" @click="clearForm"> リセット </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {},
  },
  data() {
    return {
      form: {},
      searchBtnLabel: '実行',
      hide: false,
      errors: {},
    };
  },
  computed: {
    searchAreaClass() {
      return this.hide ? 'search-area-hide p-4' : 'search-area p-4';
    },
    title() {
      return this.hide ? '' : 'フィルター';
    },
  },
  methods: {
    changeSearchButton() {
      this.searchBtnLabel = this.loading ? '実行中...' : '実行';
    },
    handleClickSearch() {
      this.$emit('search', this.form);
    },
    clearForm() {
      this.form = {
        managementOffice: null,
        operationOffice: null,
        name: null,
        address: null,
      };
    },
    toggleSidebar() {
      this.hide = !this.hide;
    },
  },
  watch: {
    form: {
      handler() {
        this.$emit('formchange', this.form);
      },
      deep: true,
    },
    loading: {
      handler() {
        this.changeSearchButton();
      },
    },
  },
};
</script>

<style>
.clear-button {
  border: thin solid red !important;
  color: red !important;
}
.search-area-hide {
  border-left: solid 1px #b3b8bb;
  background-color: white;
}
.search-area {
  width: 20%;
  min-width: 280px;
  border-left: solid 1px #b3b8bb;
  background-color: white;
}
.conditions {
  margin-bottom: 2vw !important;
}
.search-area-toggle-icon {
  height: 24px;
  width: 24px;
}
.search-area label,
.search-area {
  font-size: 12px;
}
.radio-group-row .v-selection-control-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.search-buttons .v-btn--disabled.v-btn--variant-contained {
  background-color: rgb(71, 170, 196);
}
.error .dp__input {
  border: 1px solid rgb(var(--v-theme-error));
}
.error *,
.v-input--error .v-messages__message {
  color: rgb(var(--v-theme-error));
  opacity: 1;
}
</style>
