import {sortType} from "@/services/consts";

export enum sortField {
  ORDER_BY_FIRMWARE_ID = 'firmware_id',
  ORDER_BY_DEVICE_TYPE = 'device_type',
  ORDER_BY_ACTIVE = 'active',
  ORDER_BY_UPDATED_AT = 'updated_at',
  ORDER_BY_UPDATED_USER = 'updated_by',
}

export type getFirmwareListRequest = {
  version?: string,
  firmware_id?: number,
  device_type?: number[],
  page?: number,
  sort_type?: sortType,
  sort?: sortField,
}