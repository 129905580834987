<script setup>
import VideoModal from "@/components/CvrConvert/VideoModal";
import RemoveButton from "@/components/CvrConvert/RemoveButton";
import CancelButton from "@/components/CvrConvert/CancelButton";
import RequestTypeIcon from "@/components/CvrConvert/RequestTypeIcon";
import CvrUploadStatus from "@/components/CvrConvert/CvrUploadStatus";
import ChangeHoldSwitch from "@/components/CvrConvert/ChangeHoldSwitch";
import Api from "@/services/api/ApiServiceFabrick";
import { formatDateTime } from '@/services/functions';
import {
  CVR_UPLOAD_COMPLETE,
  CVR_UPLOAD_ACCEPTED,
  CVR_UPLOAD_FINALIZED,
  CVR_UPLOAD_CANCELABLE_STATUSES,
  CVR_REQUEST_TYPE,
  CVR_UPLOAD_REQUEST_CAMERA_TYPES
} from '@/services/consts';
import { ref, defineProps, computed } from 'vue'
import i18n from '@/lang/i18n';

const props = defineProps([
  'item',
  'expandRowId',
  'setExpandRowId',
  'showFlash',
  'reload',
  'accounts',
  'updateAuthority'
]);

const item = ref(props.item);
const downloading = ref([]);

const showChildDownloadButtons = function(index) {
  return props.item.children[index].status?.toLowerCase() == CVR_UPLOAD_COMPLETE;
}

function formatItemDateTime(_datetime) {
  if (!_datetime) return '';
  const datetime =  new Date(_datetime)
  return formatDateTime(datetime);
}

const isOpen = computed(() => {
  return props.item.id == props.expandRowId
})

const showDownloadButtons = computed(() => {
  return props.item.summary_status?.toLowerCase() == CVR_UPLOAD_COMPLETE
})

const showCancelButtons = computed(() => {
  const status = props.item.parent_status?.toLowerCase();
  return (
    status == CVR_UPLOAD_ACCEPTED ||
    status == CVR_UPLOAD_FINALIZED
  ) && !CVR_UPLOAD_CANCELABLE_STATUSES.includes(props.item.status.toLowerCase())
})

const cameraType = computed(() => {
  if (props.item.request_type != CVR_REQUEST_TYPE) return '';
  return CVR_UPLOAD_REQUEST_CAMERA_TYPES[props.item.type];
})

const isDisplayRemoveButton = computed(() => {
  return props.item.request_type == CVR_REQUEST_TYPE &&
    showDownloadButtons.value && props.updateAuthority;
})

const createdBy = computed(() => {
  if (!props.accounts.length) return ""
  return props.accounts.filter((account) => {
    return account.id == item.value.created_by
  })[0]?.name ?? "";
})

const createdAt = computed(() => {
  if (!item.value.created_at) return '';
  return formatDateTime(new Date(item.value.created_at));
})

function download(item) {
  downloading.value[item.id] = true;
  const res = Api().downloadCvrUpload(item).catch((e) => {
    props.showFlash(
      i18n.global.t('pages.cvr_uploads.errorMessages.failedDownloadMovies'),
      'error'
    );
  }).finally(() => {
    downloading.value[item.id] = false;
  });
}
</script>

<template>
  <tr>
    <td @click="props.setExpandRowId(item.id)">
      <div class="d-flex p-1 collapse-container">
        <span class="pt-2 pb-2">{{props.item.id}}</span>
        <span v-if="item.children[0]" class="material-symbols-outlined pb-2 pt-2">
          <template v-if="isOpen">expand_less</template>
          <template v-else>expand_more</template>
        </span>
      </div>
    </td>
    <td>
      <RequestTypeIcon :requestType="props.item.request_type" />
    </td>
    <td>{{ props.item?.driver_name ?? '' }}</td>
    <td>
      <div class="camera-type-column">
        {{cameraType}}
      </div>
    </td>
    <td>
      <CvrUploadStatus :item="props.item" />
    </td>
    <td>{{formatItemDateTime(props.item.start_datetime)}}</td>
    <td>{{formatItemDateTime(props.item.end_datetime)}}</td>
    <td>{{ createdBy }}</td>
    <td>
      <div class="created-at">
        {{ createdAt }}
      </div>
    </td>
    <td>
      <ChangeHoldSwitch
        v-if="props.item.request_type == 'request'"
        class="hold-toggle-switch"
        :item="props.item"
        :reload="reload"
        :showFlash="props.showFlash"
        :updateAuthority="props.updateAuthority"
      />
    </td>
    <td>
      <div class="d-flex button-column">
        <div class="mr-1">
          <VideoModal v-if="showDownloadButtons"
            :item="props.item"
            :showFlash="props.showFlash"
          />
        </div>
        <div class="mr-1">
          <div v-if="!!props.item.children[0]"  class="empty_download_column" />
          <div v-else>
            <v-btn v-if="showDownloadButtons"
              :loading="downloading[props.item.id]"
              :disabled="downloading[props.item.id]"
              @click="download(props.item)"
            >
              {{i18n.global.t('pages.cvr_uploads.downloadBtn')}}
            </v-btn>
            <CancelButton
              v-if="showCancelButtons"
              :item="props.item"
              :reload="reload"
              :showFlash="props.showFlash"
            />
          </div>
        </div>
        <div class="mr-1">
          <RemoveButton
            v-if="isDisplayRemoveButton"
            :item="props.item"
            :reload="reload"
            :showFlash="props.showFlash"
          />
        </div>
      </div>
    </td>
  </tr>
  <tr
    :class="isOpen ? 'show' : 'close'"
    :height="isOpen ? '0' : '100px'"
    class="collapse"
    v-for="(child, i) in props.item.children"
    v-bind:key="child.id"
  >
    <td></td>
    <td></td>
    <td></td>
    <td class="cols:2">{{CVR_UPLOAD_REQUEST_CAMERA_TYPES[child.type]}}</td>
    <td>
      <CvrUploadStatus :item="child" :isChild="true" />
    </td>
    <template v-if="props.item.request_type == 'request'">
      <td>{{formatItemDateTime(child.start_datetime)}}</td>
      <td>{{formatItemDateTime(child.end_datetime)}}</td>
    </template>
    <template v-else>
      <td></td>
      <td></td>
    </template>
    <td>{{ createdBy }}</td>
    <td><div class="created-at">{{ createdAt }}</div></td>
    <td></td>
    <td>
      <div class="d-flex button-column">
        <div class="mr-1">
          <VideoModal v-if="showChildDownloadButtons(i)"
            :item="child"
            :showFlash="props.showFlash"
          />
        </div>
        <div class="mr-1">
          <div>
            <v-btn v-if="showChildDownloadButtons(i)"
              :loading="downloading[child.id]"
              :disabled="downloading[child.id]"
              @click="download(child)"
            >
              {{i18n.global.t('pages.cvr_uploads.downloadBtn')}}
            </v-btn>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<style scoped>
.camera-type-column {
  min-width: max-content;
  padding: 0 10px;
}
.hold-toggle-switch {
  display: grid;
  justify-content: center;
}
.empty_download_column {
  min-width: 140px !important;
  display: block;
}

.collapse {
  background: #DDD;
  overflow: hidden;
  transition: height 200ms;
}

.created-at {
  width: max-content;
}

.close {
  height: 0;
}

.open {
  height: 100px;
}
</style>
