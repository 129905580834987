<script setup>
  import { defineProps, watch, ref, onMounted } from 'vue'

  const canvas = ref(null);
  const preCanvasWidth = ref(0);
  const props = defineProps({
    title: String,
    times: Array,
    startTime: String,
    endTime: String,
    width: Number
  })
  const emits = defineEmits(['resize']);

  const fillCanvas = (times) => {
    if (!canvas.value) return
    const startUnixTime = new Date(props.startTime).getTime();
    const endUnixTime = new Date(props.endTime).getTime();
    const diffSecondsFromTo = endUnixTime - startUnixTime;
    const context = canvas.value.getContext('2d');
    const canvasWidth = canvas.value.width;
    context.clearRect(0, 5, preCanvasWidth.value, 20);

    for (var i = 0; i < times.length; i++) {
      const time = times[i];
      const diffSecondsFromStart = time['start'] - startUnixTime;
      const startPx = diffSecondsFromStart * canvasWidth / diffSecondsFromTo;
      const diffSecondsFromEnd = time['end'] - startUnixTime;
      const endPx = diffSecondsFromEnd * canvasWidth / diffSecondsFromTo;

      context.fillRect(startPx, 5, endPx - startPx, 20);
    }

    preCanvasWidth.value = canvasWidth;
  }

  const fillCanvasAfterResize = () => {
    new Promise((resolve) => {
      emits('resize');
      resolve();
    }).then(() => {
      fillCanvas(props.times);
    })
  }

  watch(() => props.times, (times) => {
    fillCanvas(times);
  })

  onMounted(() => {
    window.addEventListener('resize', fillCanvasAfterResize);
  })
</script>

<template>
  <div class="d-flex justify-end pl-10 pr-3">
    <div class="pl-1 pt-2 pb-1 pr-1 d-flex align-items-center" style="border: black solid 1px; font-weight: bold; font-size: 14px;">
      {{ props.title }}
    </div>
    <canvas ref="canvas" :width="props.width" height="30" style="border: black solid 1px;"></canvas>
  </div>
</template>

<style>
</style>
