<script setup>
import { watch, ref } from 'vue';

const props = defineProps({
  trackingService: { type: String },
  isManagementOffice: { type: Boolean },
  windowInnerWidth: Number,
});
const emit = defineEmits(['inputValue']);
const trackingServiceOptions = ['docomap', 'octlink'];
const trackingServiceValue = ref('');

const unwatch = watch(
  () => props.trackingService,
  (propsTrackingService) => {
    trackingServiceValue.value = propsTrackingService;
    unwatch();
  }
);
watch(trackingServiceValue, () => {
  emit('inputValue', trackingServiceValue.value);
});
</script>
<template>
  <v-row class="text-form pl-4 pr-3 mb-3" v-if="isManagementOffice">
    <v-col :cols="windowInnerWidth > 800 ? 2 : 3">
      <h6 class="pl-4">トラッキングサービス</h6>
    </v-col>
    <v-col cols="4" class="pt-0">
      <v-select
        :items="trackingServiceOptions"
        v-model="trackingServiceValue"
      />
    </v-col>
  </v-row>
</template>
