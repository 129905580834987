
import { defineComponent, PropType } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import getFirmwareVersionsResponse from '@/types/responses/getFirmwareVersionsResponse';
import FirmwareVersionsType from '@/types/FirmwareVersionsType';
import FirmwareVersionAutoComplete from '@/components/AutoCompletes/FirmwareVersionAutoComplete.vue';
import ValidationErrors from '@/components/Common/ValidationErrors.vue';

export default defineComponent({
  setup() {
    return {};
  },
  data() {
    return {
      selectedFirmware: undefined as FirmwareVersionsType | undefined,
      form: {
        firmware_id: undefined as number | undefined,
        activate_date: new Date() as Date | undefined,
      },
      errors: {} as {
        firmware?: Array<string>;
        activate_date?: Array<string>;
      },
    };
  },
  emits: ['close-modal', 'send-form', 'select-firmware'],
  props: {
    isFirmwareDeviceAttaching: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    isAttachButtonDisabled: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    availableToSelectFirmwares: {
      type: Array as PropType<getFirmwareVersionsResponse>,
      required: true,
    },
    updateAuthority: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  name: 'AttachFirmwareForm',
  mounted() {
    //
  },
  computed: {
    isValide(): boolean {
      return Object.keys(this.errors).length == 0;
    },
    attachBtnText(): string {
      return this.isFirmwareDeviceAttaching ? '適用中...' : '適用';
    },
    isFirmwareIDValide(): string | boolean {
      if (this.selectedFirmware === undefined || this.selectedFirmware === null) {
        return 'ファームウェアを選択してください。';
      }
      return true;
    },
    isActivateDateValide(): string | boolean {
      if (
        this.form.activate_date == undefined ||
        this.form.activate_date === null
      ) {
        return '適用開始日時を選択してください。';
      }
      return true;
    },
    isNeedToResetSelectedFirmwareID: function (): boolean {
      if (!this.selectedFirmware) {
        return false;
      }

      const selectedFirmwareAvailable = this.availableToSelectFirmwares.find(
        (firmware) => {
          return firmware.id == this.form.firmware_id;
        }
      );

      return !selectedFirmwareAvailable;
    },
  },
  watch: {
    selectedFirmware: {
      handler() {
        this.form.firmware_id = this.selectedFirmware?.id;
      },
      deep: true,
    },
    form: {
      handler() {
        this.validateForm();
      },
      deep: true,
    },
    availableToSelectFirmwares: {
      handler() {
        if (this.isNeedToResetSelectedFirmwareID) {
          this.selectedFirmware = undefined;
        }
      },
      deep: true,
    },
  },
  methods: {
    validateAndSendForm(): void {
      if (this.isFirmwareDeviceAttaching) {
        return;
      }
      this.validateForm();
      if (this.isValide) {
        this.sendForm();
      }
    },
    validateForm(): void {
      this.errors = {};

      if (this.isActivateDateValide !== true) {
        if (!this.errors.activate_date) this.errors.activate_date = [];
        this.errors.activate_date.push(this.isActivateDateValide as string);
      }

      if (this.isFirmwareIDValide !== true) {
        if (!this.errors.firmware) this.errors.firmware = [];
        this.errors.firmware.push(this.isFirmwareIDValide as string);
      }
    },
    clearForm(): void {
      this.selectedFirmware = undefined;
      this.form.activate_date = undefined;
    },
    sendForm(): void {
      this.$emit('send-form', this.form);
    },
  },
  components: {
    Datepicker,
    FirmwareVersionAutoComplete,
    ValidationErrors,
  },
});
