class Sha256 {
    public static async generate(str: string) {
        const shaBuffer = await this.sha256(str);
        const hashArray = Array.from(new Uint8Array(shaBuffer));
        const hashHex = hashArray
            .map((b) => b.toString(16).padStart(2, "0"))
            .join(""); // convert bytes to hex string
        return hashHex;
    }
        
    public static sha256 (message: string) {
        const encoder = new TextEncoder();
        const data = encoder.encode(message);
        return window.crypto.subtle.digest('SHA-256', data);
    }
}

export default Sha256