<template>
  <div class="d-flex justify-content-end bd-highlight mb-3 output-button-area">
    <v-btn
      class="exec-btn"
      :disabled="!previewable"
      @click="handleClickPreview"
      :style="{
        backgroundColor : previewing ? '#B3B8BB' : ''
      }"
    >
      {{downloadBtnLabel}}
    </v-btn>
  </div>
</template>

<script>
  import Api from "@/services/api/ApiServiceFabrick";
  import { useAuth } from "@/services/user/Auth0UserProvider"

  export default {
    props: {
      operationOfficeId: null,
      deliveryPlanIds: [],
    },
    setup() {
      const { loginWithRedirect } = useAuth();
      return { loginWithRedirect }
    },
    data() {
      return {
        previewing: false,
        type: "App\\Services\\OutputReports\\Every24hours\\PreviewCreateService",
      }
    },
    computed: {
      previewable() {
        return this.deliveryPlanIds[0] && !this.previewing
      },
      downloadBtnLabel() {
        return this.previewing ? "表示中..." : "プレビュー"
      },
    },
    methods: {
      handleClickPreview() {
        this.previewing = true
        const params = this.makeShowPreviewRequest()
        Api().createJobStatus(params).then((data) => {
          this.pollingJobStatus(data.id)
        }).catch((error) => {
          const res = error.response
          switch (res.status) {
            case 401:
              this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
              break
            case 403:
              location.reload()
              break
            case 422:
          }
          this.previewing = false
        })
      },
      pollingJobStatus(id) {
        const interval = setInterval(() => {
          Api().getJobStatus(id).then((res) => {
            if(res.status == "completed") {
              this.$emit('set', res.output_contents)
              clearInterval(interval)
              this.previewing = false
            }
            if (res.status == "failed") {
              alert(res.output_contents.message)
              clearInterval(interval)
              this.previewing = false
            }
          })
        }, 3000)
      },
      makeShowPreviewRequest() {
        return {
          type: this.type,
          operation_office_id: this.operationOfficeId,
          delivery_plan_ids: this.deliveryPlanIds.join(',')
        }
      },
    },
  }

</script>

<style>
  .output-button-area .v-btn--disabled.v-btn--variant-contained {
    background-color: rgb(71, 170, 196) !important;
  }
</style>
