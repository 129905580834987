import AccessChecker from "@/router/accessChecker";
import { routingAuthorityNames } from "@/services/consts";
import SelectRoleChecker from "@/router/selectRoleChecker";

type SiteLink = {
  name: string;
  path: string;
  accessCheckHandler: (accessChecker: AccessChecker) => boolean;
};

const arSiteLinks: Array<SiteLink> = [
  {
    name: "select_role",
    path: "/select_role",
    accessCheckHandler: (accessChecker) => {
      return true;
    },
  },
  {
    name: "firmwares",
    path: "firmwares",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.Firmwares) &&
        accessChecker.isItManagementOffice();
    },
  },
  {
    name: "FirmwareDevices",
    path: "firmwares/devices",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.FirmwareDevices) &&
        accessChecker.isItManagementOffice();
    },
  },
  {
    name: "dashboard",
    path: "dashboard",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.Dashboard);
    },
  },
  {
    name: "DailyReport",
    path: "daily-report",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.DailyReport);
    },
  },
  {
    name: "DailyReportLayout",
    path: "daily-report-layouts",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(
        routingAuthorityNames.DailyReportLayout
      );
    },
  },
  {
    name: "UnexpectedEventAndAlertStatuseList",
    path: "unexpected-event-and-long-time-driving-extensions",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(
        routingAuthorityNames.DailyReport
      );
    },
  },
  {
    name: "Every24hoursReport",
    path: "every24hours-report",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(
        routingAuthorityNames.Every24hoursReport
      );
    },
  },
  {
    name: "MonthlyReport",
    path: "monthly-report",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.MonthlyReport);
    },
  },
  {
    name: "SafeDrivingReport",
    path: "safe-driving-reports",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(
        routingAuthorityNames.SafeDrivingReport
      );
    },
  },
  {
    name: "Accounts",
    path: "accounts",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.Accounts);
    },
  },
  {
    name: "Vehicle",
    path: "vehicles",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.Vehicle);
    },
  },
  {
    name: "SafeDrivingSetting",
    path: "safe-driving-setting",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(
        routingAuthorityNames.SafeDrivingSetting
      );
    },
  },
  {
    name: "DeviceList",
    path: "devices",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.Device);
    },
  },
  {
    name: "SubManagementOffices",
    path: "sub-management-offices",
    accessCheckHandler: (accessChecker) => {
      return (
        accessChecker.hasUserAccessTo(routingAuthorityNames.SubManagementOffice) &&
        (
          accessChecker.isItManagementOffice() ||
          accessChecker.isItSubManagementOffice()
        )
      );
    },
  },
  {
    name: "OperationOfficeEditForm",
    path: "operation-office-edit",
    accessCheckHandler: (accessChecker) => {
      return (
        accessChecker.hasUserAccessTo(routingAuthorityNames.OperationOffice) &&
        !accessChecker.isItManagementOffice() &&
        !accessChecker.isItSubManagementOffice()
      );
    },
  },
  {
    name: "OperationOfficeList",
    path: "operation-offices",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.OperationOffice) &&
        (accessChecker.isItManagementOffice() || accessChecker.isItSubManagementOffice());
    },
  },
  {
    name: "Spot",
    path: "spots",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.Spot);
    },
  },
  {
    name: "Groups",
    path: "groups",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.Groups);
    },
  },
  {
    name: "InstallationOffice",
    path: "installation-offices",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(
        routingAuthorityNames.InstallationOffice
      );
    },
  },
  {
    name: "AllowAccessOffice",
    path: "allow-access-offices",
    accessCheckHandler: (accessChecker) => {
      return (
        accessChecker.hasUserAccessTo(
          routingAuthorityNames.AllowAccessOffice
        ) &&
        (accessChecker.isItManagementOffice() ||
          accessChecker.isItSubManagementOffice())
      );
    },
  },
  {
    name: "DownloadData",
    path: "download-data",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.DownloadData);
    },
  },
  {
    name: "DownloadDataHistories",
    path: "download-data-histories",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(
        routingAuthorityNames.DownloadDataHistories
      );
    },
  },
  {
    name: "CvrConvert",
    path: "cvr_upload",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.CvrConvert);
    },
  },
  {
    name: "TemperatureSensorCharts",
    path: "temperature-sensor-charts",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.Temperature);
    },
  },
  {
    name: "DataLinkage",
    path: "data-linkage",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.DataLinkage);
    },
  },
  {
    name: "TemperaturInformationNumerical",
    path: "temperatur-information-numerical",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.Temperature) &&
      (!accessChecker.isItManagementOffice() &&
      !accessChecker.isItSubManagementOffice());
    },
  },
  {
    name: "RoleAssaignment",
    path: "role-assaignment",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.roleAssignment);
    },
  },
  {
    name: "Departments",
    path: "departments",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.Departments);
    },
  },
  {
    name: "UserRoles",
    path: "user-roles",
    accessCheckHandler: (accessChecker) => {
      return accessChecker.hasUserAccessTo(routingAuthorityNames.UserRoles);
    },
  },
];

// 使い方
//
// CvrConvert の場合
// accessCheckHandler へ下記のように追記
// ```
// if (!showMenu('CvrConvert')) return false
// ```
// 表示したい環境の環境変数へ設定
// ```
// VUE_APP_SHOW_MENU_CVRCONVERT=true
// ```
// 環境変数が 未設定 or true以外の値 の場合にサイドメニューへ表示されない
const showMenu = (name: string) => {
  const key = "VUE_APP_SHOW_MENU_" + name.toUpperCase();
  return !!process.env[key] && process.env[key] == "true";
};

export const getSiteLink = (name: string): SiteLink | undefined => {
  return arSiteLinks.find((link) => {
    return link.name === name;
  });
};
