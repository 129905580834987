<script setup>
  import OperationOfficeList from "@/components/OpearationOfficeListParts/OperationOfficeList"
  import OpeartionOfficeSearchForm from "@/components/OpearationOfficeListParts/OpeartionOfficeSearchForm"
  import FileDownloadBtn from "@/components/Files/DownloadButton"
  import FileUploadModal from "@/components/Files/UploadModal"
  import Api from "@/services/api/ApiServiceFabrick"
  import PagenateItems from "@/components/PagenateItems"
  import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
  import ManualLink from "@/components/ManualLink";
  import { reactive, ref, onMounted, watch } from 'vue'
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import { MANAGEMENT_OFFICE_TYPE, SUB_MANAGEMENT_OFFICE_TYPE } from "@/services/consts.ts"
  import { useRouter } from 'vue-router'
  import i18n from "@/lang/i18n"

  const props = defineProps({
    showFlash: {
      type: Function
    }
  })
  document.title = "運営会社一覧";
  const changeStatusAuthority = ref(false)
  const createAuthority = ref(false)
  const updateAuthority = ref(false)
  const csvImportOperationAuthority = ref(false)
  const csvExportOperationAuthority = ref(false)

  const { loginWithRedirect } = useAuth()
  const router = useRouter()
  const form = reactive({})
  const selfRoleId = ref(2)
  const currentPage = ref(1)
  const totalPage = ref(0)
  const widthRatio = ref('min-width: 75%; width: 80%')
  const operationOffices = ref([])
  const listLoading = ref(false)
  const searchParams = reactive({
    page: 1,
    withs: 'updatedBy',
    status: 'only-active'
  })
  const updatingStatus = ref(false)
  const response = reactive({errors: {}})
  const downloadType = "App\\Services\\Csv\\Exports\\OperationOfficeService"
  const uploadType = "App\\Services\\Csv\\Imports\\OperationOfficeService"
  const modalState = ref(false)
  const pageName = "manageOperationOffice"
  const isManagementOffice = ref(false)

  onMounted(() => {
    getAuthorityList()
    listLoading.value = true
    Api().getSelfAccount().then((data) => {
      isManagementOffice.value = data.login_organization_type == MANAGEMENT_OFFICE_TYPE
      selfRoleId.value = data.role_id;
      getOperationOffices();
    }).catch((error) => {
      const res = error.response
      if (res.status == 401) loginWithRedirect({ appState: { targetUrl: location.pathname } });
      listLoading.value = false;
    })
  })

  const getAuthorityList = async(isError = false) =>{
    if (isError) await AuthorityCacheApi.reflesh();
    const authorityList = await AuthorityCacheApi.getWithParse();
    changeStatusAuthority.value = authorityList["octlink.operation_office.delete"];
    createAuthority.value = authorityList["octlink.operation_office.create"];
    updateAuthority.value = authorityList["octlink.operation_office.update"];
    csvImportOperationAuthority.value = authorityList["octlink.operation_office.input"];
    csvExportOperationAuthority.value = authorityList["octlink.operation_office.output"];
  }

  function getOperationOffices () {
    listLoading.value = true
    Api().getPaginatedOperationOffices(searchParams).then((data) => {
      operationOffices.value = data.operationOffices
      currentPage.value = data.current_page
      totalPage.value = data.total_page
      listLoading.value = false
    }).catch((error) => {
      return errorHandle(error.response)
    })
  }

  function serchOperationOfficesList (form) {
    const workStatus = makeWorkStatusParam(form)
    searchParams.page = 1
    searchParams.management_officeable_id = form?.managementOffice?.id,
    searchParams.management_officeable_type = form?.managementOffice?.type,
    searchParams.operation_office_name = form?.operationOffice
    searchParams.contact_name = form?.contactName,
    searchParams.work_status = workStatus,
    searchParams.status = form?.status
    searchParams.sort
    searchParams.sort_type
    getOperationOffices()
  }

  function makeWorkStatusParam (form) {
    if (!form.workStatus) return ''
    switch (form.workStatus){
      case '商談を持ちます':
        return 1
      case 'オペレーティング':
        return 2
      case '契約の解散':
        return 3
      case '保留中':
        return 4
      default: ''
    }
  }

  function handleChangeSearchForm (serchForm) { form.value = serchForm}
  function changeSortOrder (sortItem, sortType) {
    searchParams.sort = sortItem
    searchParams.sort_type = sortType
    if (currentPage.value == 1) {
      getOperationOffices()
    } else {
      currentPage.value = 1;
    }
  }

  function updateStatus (params, key) {
    updatingStatus.value = true
    if (key == "allow_external_shared") return updateAllowExternalShared(params)
    Api().updateOperationOfficeStatus(params.id, params).then((_data) => {
      props.showFlash(i18n.global.t('pages.common.messages.changeStatusSuccess'), "success")
      updatingStatus.value = false
      getOperationOffices()
    }).catch((error) => {
      errorHandle(error.response)
      if (error.response.status == 403) getAuthorityList(true)
    })
  }

  function updateAllowExternalShared (params) {
    Api().updateAllowExternalShared(params.id, params).then((_data) => {
      props.showFlash(i18n.global.t('pages.operation_offices.list.allowExternalShared'), "success")
      updatingStatus.value = false
      getOperationOffices()
    }).catch((error) => {
      errorHandle(error.response)
      if (error.response.status == 403) getAuthorityList(true)
    })
  }

  function updateWidth (hide) {
    widthRatio.value = hide ? 'width: 95%' : 'min-width: 75%; width: 80%'
  }

  function moveToAccountForm () {
    router.push('/operation-offices/form/')
  }

  function inactivateModalState (isReacquireAccount) {
    modalState.value = false
    if (isReacquireAccount) getOperationOffices()
  }

  function activateModalState () {
    modalState.value = true
  }

  function errorHandle (res) {
    switch (res.status) {
      case 401:
        loginWithRedirect({ appState: { targetUrl: location.pathname } })
        listLoading.value = false
        break
      case 403:
        props.showFlash(i18n.global.t('pages.common.messages.forbidden'), "error", false)
        listLoading.value = false
        break
      case 422:
        response.errors = res.data.errors
    }
    return updatingStatus.value = false
  }

  watch(currentPage, () => {
    searchParams.page = currentPage.value
    getOperationOffices()
  })
</script>

<template>
  <div class="d-flex" style="min-height: 45vw;">
    <div class="content p-4" :style="widthRatio">
      <div class="d-flex justify-content-between mb-3">
        <h4 class="card-title mt-0">運営会社一覧</h4>
        <div class="ml-2 mb-0">
          <ManualLink :pageName="pageName" />
        </div>
      </div>
      <div class="mb-5 d-flex justify-end">
        <div class="btn-area">
          <v-btn v-if="createAuthority" class="exec-btn mt-0" @click="moveToAccountForm">
            新規作成
          </v-btn>
          <FileDownloadBtn
            :showable="csvExportOperationAuthority"
            btnClass="exec-btn mt-0 ml-1"
            btnName="CSV出力"
            :type="downloadType"
            :filterItems="searchParams"
          />
          <v-btn
            v-show="csvImportOperationAuthority && isManagementOffice"
            class="exec-btn mt-0 ml-1"
            @click="activateModalState"
            >
            CSV入力
          </v-btn>
        </div>
      </div>
      <div>
        <OperationOfficeList
          :selfRoleId="selfRoleId"
          :operationOffices="operationOffices"
          :listLoading="listLoading"
          :changeStatusAuthority="changeStatusAuthority"
          :changeSharedAuthority="updateAuthority"
          :updatingStatus="updatingStatus"
          @changeStatus="updateStatus"
          @changeSortOrder="changeSortOrder"
        />
        <PagenateItems
          :range="2"
          :totalPage="totalPage"
          v-model="currentPage"
        />
        <hr />
      </div>
    </div>

    <OpeartionOfficeSearchForm
      :loading="listLoading"
      :isManagementOffice="isManagementOffice"
      @formchange="handleChangeSearchForm"
      @search="serchOperationOfficesList"
      @hideOrShow="updateWidth"
    />

    <FileUploadModal
      :modalState="modalState"
      title='運営会社の入力'
      uploadBtnClass='exec-btn mt-0 ml-1'
      closeBtnClass='mt-0'
      :type="uploadType"
      templateCsvFileType="operation_offices"
      @closeModal="inactivateModalState"
    />
  </div>
</template>
