<template>
<GDialog
  v-model="dialogState"
  max-width="30%"
  transition="custom-from-bottom-transition"
>
  <div class="modal-header justify-content-center pt-3">
    <label>確認</label>
  </div>
  <div class="row mr-3 ml-3 pl-5 v-theme--light">
    車両を{{nextStatus()}}にします。よろしいですか？
  </div>
  <Buttons
    @cancel="closeModal"
    @submit="submit"
    :isUpdateAuthority="updatableStatusButton"
    :running="isStatusUpdating"
    label="更新"
  />
</GDialog>

<v-container
  class="px-0 py-2"
  fluid
  style="width: 3vw;"
>
  <v-switch v-if="updatableStatusButton"
    v-model="currentStatus"
    @change="dialogState = true"
    color="success"
    hide-details
  ></v-switch>
  <v-switch v-if="!updatableStatusButton"
    v-model="currentStatus"
    @change="dialogState = true"
    color="success"
    :class="currentStatus ? 'text-success' : ''"
    disabled
    hide-details
  ></v-switch>
</v-container>
</template>

<script>
import 'gitart-vue-dialog/dist/style.css'
import { GDialog } from 'gitart-vue-dialog'
import Buttons from '@/components/SpotParts/ModalButtons'

export default {
  props: {
    item: {
      default: null
    },
    updatableStatusButton: {
      type: Boolean,
    },
    isStatusUpdating: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    dialogState: false,
    currentStatus: false,
  }),
  mounted() {
    this.currentStatus = !!this.item?.status
  },
  methods: {
    closeModal() {
      this.dialogState = false
    },
    submit() {
      this.$emit('submit', this.item.id, !this.currentStatus)
    },
    viewStatus() {
      return this.item?.status ? '有効' : '無効'
    },
    nextStatus() {
      return this.item?.status ? '無効' : '有効'
    }
  },
  watch: {
    item: {
      handler() {
        this.currentStatus = !!this.item?.status
      },
      deep: true,
    },
    dialogState: {
      handler() {
        this.currentStatus = !!this.item?.status
      },
    },
    isStatusUpdating: {
      handler(status) {
        if (status) return;
        this.closeModal();
      }
    }
  },
  components: {
    GDialog,
    Buttons,
  },
}
</script>

<style>
.modal-header {
  font-weight: 600;
}
</style>
<style lang="scss">
.custom-rotate-transition {
  &-enter-from {
    transform: translate(0, 30px) rotate(20deg);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, 30px) rotate(10deg);
    opacity: 0;
  }
}

.custom-from-bottom-transition {
  &-enter-from {
    transform: translate(0, 100%);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, -30%);
    opacity: 0;
  }
}
.error *, .v-input--error .v-messages__message {
  color: rgb(var(--v-theme-error));
  opacity: 1;
}
</style>
