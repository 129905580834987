<template>
  <GDialog
    v-model="dialogState"
    max-width="90%"
    transition="custom-from-bottom-transition"
  >
    <ModalFlashMessage
      :message = "flashMessage.message"
      :show = "flashMessage.show"
      :type = "flashMessage.type"
      :cancel = "flashMessage.cancel"
      @setShow = "(show) => {flashMessage.show = show}"
    ></ModalFlashMessage>
    <div class="row mr-3 ml-3">
      <div class="col-6">
        <div class="row v-theme--light">
          <div class="col-6">
            <DriverAutoComplete
              v-if="operationOffice"
              v-model="selectedDriver"
              :initialItemId="driverId"
              :operationOffices="operationOffices"
              :error-messages="errors['vehicleInformation.driver_id']"
              @isDriverLoading="setIsDriverLoading"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row pt-0 pb-0 ">
              <label v-if="!refuelingAmountState">この運行の給油量を入力してください。</label>
              <div class="col-6">
                <v-text-field
                  label="自社給油"
                  v-model="form.refueling_amount_at_warehouse"
                  hide-details="auto"
                  :readonly="refuelingAmountState"
                  :clearable="false"
                  :error-messages="errors['vehicleInformation.refueling_amount_at_warehouse']"
                ></v-text-field>
              </div>
              <div class="col-6">
                <v-text-field
                  label="他社給油"
                  v-model="form.refueling_amount_at_general_gas_station"
                  hide-details="auto"
                  :readonly="refuelingAmountState"
                  :clearable="false"
                  :error-messages="errors['vehicleInformation.refueling_amount_at_general_gas_station']"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <label>この運行のコース名を入力してください</label>
          <div class="col-12">
            <v-text-field
              hide-details="auto"
              v-model="form.course_name"
              :error-messages="errors['vehicleInformation.course_name']"
            ></v-text-field>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row pt-0 pb-0 mb-2">
          <label class="mb-1">停車を検索</label>
          <v-text-field
            label="1分〜1440分の範囲で入力"
            suffix="分以上の停車"
            v-model="searchItems.minute"
            :hint="search_message"
            :error-messages="search_error_message"
            persistent-hint
          />
        </div>
        <div class="row pt-0 pb-0">
          <div class="d-flex justify-content-end">
            <v-btn
              class="reset-btn"
              @click="clear"
            >
              クリア
            </v-btn>
            <v-btn
              :loading="searching"
              class="exec-btn ml-3"
              @click="search"
            >
              検索
            </v-btn>
          </div>
        </div>
        <div class="d-flex justify-end mt-4">
          <SpecialCaseButton
            :deliveryPlan="deliveryPlan"
            :createAuthority="createAuthority"
            :showFlash="makeFlashMessage"
          />
        </div>
      </div>
    </div>
    <div class="row mr-3 ml-3">
      <div class="col-12">
        <Overview :deliveryPlan="form" />
      </div>
    </div>
    <div class="row mr-3 ml-3">
      <div class="col-12">
        <WorkList
          ref="workList"
          v-model="items"
          :deliveryPlan="deliveryPlan"
          :dialogState="dialogState"
          :operationOfficeId="this.operationOffice.id"
          :updateAuthority="updateAuthority"
          @changeSearchMessage="changeSearchMessage"
          @changeSearchErrorMessage="changeSearchErrorMessage"
          @clearModal="clearModal"
          @setRefuelingAmount="setRefuelingAmount"
        />
        <ValidationErrors
          :messages="errors['works']"
        />
      </div>
    </div>
    <div class="row mr-3 ml-3">
      <div class="col-8">
        <div class="modal-bottom-note">※作業が割当されていない「停止」は保存されません。</div>
      </div>
      <div class="col-4 m-0">
        <Buttons
          :items="items"
          @cancel="closeModal"
          @submit="submit"
          :running="running"
          :hasError="hasError"
          :isLoading="isDriverLoading"
          :updateAuthority="updateAuthority"
        />
      </div>
    </div>

  </GDialog>

  <button @click="openModal" class="d-flex material-symbols-outlined">
    edit_note
  </button>
</template>

<script>
import "gitart-vue-dialog/dist/style.css";
import { GDialog } from "gitart-vue-dialog";
import Api from "@/services/api/ApiServiceFabrick";
import ModalFlashMessage from "@/components/Common/ModalFlashMessage";
import DriverAutoComplete from "@/components/AutoCompletes/DriverAutoComplete";
import Overview from "@/components/DailyReportUpdateModalParts/DailyReportOverview";
import WorkList from "@/components/DailyReportUpdateModalParts/DailyReportWorkList";
import Buttons from "@/components/DailyReportUpdateModalParts/ModalButtons";
import DeliveryPlanEvent from "@/services/models/DeliveryPlanEvent";
import { useAuth } from "@/services/user/Auth0UserProvider";
import ValidationErrors from '@/components/Common/ValidationErrors';
import SpecialCaseButton from "@/components/DailyReportUpdateModalParts/SpecialCaseButton";
import { round, scrollTop } from "@/services/functions";
import {
  isIntegerValide,
  isNumberIncludeBetween
} from "@/services/validators";

export default {
  setup() {
    const { loginWithRedirect } = useAuth();

    return { round, loginWithRedirect };
  },
  props: {
    showFlash: {
      type: Function,
    },
    operationOffice: {
      type: Object,
    },
    driverId: {
      type: Number,
      default: 1,
    },
    deliveryPlan: {},
    createAuthority: {
      type: Boolean
    },
    updateAuthority: {
      type: Boolean,
    },
  },
  data: () => ({
    form: null,
    dialogState: false,
    refuelingAmountState: true,
    selectedDriver: null,
    items: [],
    confirm: false,
    errors: {},
    hasError: false,
    running: false,
    searchItems: {},
    searching: false,
    search_message: "",
    search_error_message: [],
    isDriverLoading: true,
    flashMessage: {
      message: "",
      show: false,
      type: "",
      cancel: false,
    },
  }),
  mounted() {
    this.setToFormFromProps();
    this.initSearchItems();
  },
  computed: {
    operationOffices: function() {
      if (this.operationOffice) return [this.operationOffice]
      return null
    }
  },
  methods: {
    setToFormFromProps() {
      this.form = JSON.parse(JSON.stringify(this.deliveryPlan));
    },
    initSearchItems(){
      this.searchItems = {minute: ""};
      this.initSearchMessage();
    },
    initSearchMessage(){
      this.search_message = "";
      this.search_error_message = [];
    },
    setRefuelingAmount(){
      this.$emit("change", this.form);
    },
    setRefuelingAmountState(){
      this.refuelingAmountState = this.items.some( item => {
        return item.work_id
      })
    },
    roundForView(num) {
      return round(num, 2).toFixed(2);
    },
    openModal(){
      this.dialogState = true
      this.refuelingAmountState = true
      this.makeFlashMessage("", false, "info", true)
    },
    closeModal() {
      this.form = JSON.parse(JSON.stringify(this.deliveryPlan));
      this.dialogState = false;
      this.clearModal()
    },
    clearModal(){
      this.$refs.workList.clearErrors();
      this.$refs.workList.clearSearchItems();
      this.initSearchItems();
    },
    submit() {
      this.running = true;
      // 未入力の停車レコードは保存しない
      // this.clear() // 実行タイミングの違い？によりうまくいかない
      let new_items = this.items.filter((item) => {
        return item.work != "stopping"
      });
      this.clearModal()
      this.items = new_items

      const works = DeliveryPlanEvent.convertEventToWork(this.items);
      this.form.driver_id = this.selectedDriver?.driver_id;
      Api()
        .updateDailyReport(this.form, works)
        .then(() => {
          this.errors = {};
          this.makeFlashMessage("更新に成功しました。", true, "info", false)
          this.running = false;
          this.clear()
          this.$refs.workList.getWorks();
          this.$refs.workList.editClear();
          this.$emit("change", this.form);
        })
        .catch((error) => {
          const res = error.response;
          switch (res.status) {
            case 401:
              this.loginWithRedirect({
                appState: { targetUrl: location.pathname },
              });
              break;
            case 403:
              location.reload();
              break;
            case 422:
              this.errors = res.data;
              this.setWorksErrorMessages(this.errors)
              this.makeFlashMessage("更新に失敗しました。", true, "error", false)
          }
          this.running = false;
        });
    },
    search(){
      this.initSearchMessage();
      let result = this.validateSearchItems()
      if(result.length > 0){
        this.search_error_message = result
      }else{
        this.searching = true;
        this.$refs.workList.search(this.operationOffice.id, this.searchItems.minute);
      }
    },
    changeSearchMessage(message){
      this.search_message = message;
      this.searching = false;
    },
    changeSearchErrorMessage(errorMessage){
      this.search_error_message = errorMessage;
      this.searching = false;
    },
    clear(){
      this.clearModal()
      this.$refs.workList.clear();
    },
    validate() {
      this.errors['vehicleInformation.driver_id'] = undefined;
      if (!this.selectedDriver && !this.isDriverLoading) {
        this.errors['vehicleInformation.driver_id'] = ['選択してください'];
      }
      this.hasError = !!this.errors['vehicleInformation.driver_id']?.[0];
    },
    validateSearchItems(){
      let result = []
      if(isIntegerValide(this.searchItems.minute) !== true){
        result.push(isIntegerValide(this.searchItems.minute))
      }
      if(isNumberIncludeBetween(this.searchItems.minute, 1, 1440) !== true){
        result.push(isNumberIncludeBetween(this.searchItems.minute, 1, 1440))
      }
      return result
    },
    setIsDriverLoading(isLoading){
      this.isDriverLoading = isLoading
    },
    setWorksErrorMessages(messages){
      for (const key of Object.keys(messages)) {
        if(key.indexOf('works') != -1){
          messages['works'] = messages[key]
          break
        }
      }
    },
    makeFlashMessage(message, show, type, cancel) {
      this.flashMessage.message = message
      this.flashMessage.show = show
      this.flashMessage.type = type
      this.flashMessage.cancel = cancel
      scrollTop('modal-flash-message')
    }
  },
  watch: {
    items: {
      handler() {
        const time = this.items[this.items.length - 1]?.start_time;
        this.form.returning_warehouse_date_time = new Date(
          time
        ).toLocaleString();
        this.setRefuelingAmountState();
      },
      deep: true,
    },
    selectedDriver: {
      handler() {
        this.validate();
      },
    },
    dialogState: {
      handler() {
        if (this.dialogState) this.validate();
      },
    },
    deliveryPlan: {
      handler() {
        this.setToFormFromProps();
      },
    },
  },
  components: {
    GDialog,
    DriverAutoComplete,
    Overview,
    WorkList,
    Buttons,
    ModalFlashMessage,
    ValidationErrors,
    SpecialCaseButton,
  },
};
</script>

<style scoped>
.modal-header {
  font-weight: 600;
}
</style>
<style lang="scss">
.custom-rotate-transition {
  &-enter-from {
    transform: translate(0, 30px) rotate(20deg);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, 30px) rotate(10deg);
    opacity: 0;
  }
}

.custom-from-bottom-transition {
  &-enter-from {
    transform: translate(0, 100%);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, -30%);
    opacity: 0;
  }
}
.error *,
.v-input--error .v-messages__message {
  color: rgb(var(--v-theme-error));
  opacity: 1;
}

.modal-bottom-note {
  color: red;
}

</style>
