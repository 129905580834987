<script setup>
  import Api from "@/services/api/ApiServiceFabrick"
  import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
  import ManagementOfficeAutoComplete from "@/components/AutoCompletes/ManagementOfficeAutoComplete"
  import CompanyNameForm from "@/components/InstallationOfficeParts/CompanyNameForm"
  import ContactNameForm from "@/components/InstallationOfficeParts/ContactNameForm"
  import EmailForm from "@/components/InstallationOfficeParts/EmailForm"
  import OfficeInCharge from "@/components/InstallationOfficeParts/OfficeInCharge"
  import CommentForm from "@/components/InstallationOfficeParts/CommentForm"
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import { useRoute, useRouter } from 'vue-router'
  import { reactive, ref, onMounted, onBeforeUnmount, watch } from 'vue'
  import {scrollToErrorComponent} from "@/services/validators";

  const props = defineProps({
    showFlash: { type: Function },
    switchContentLoading: { type: Function }
  })

  const loginWithRedirect = ref(null)
  const router = useRouter()
  const route = useRoute()
  const title = ref('')
  const showableUpdateButton = ref(false)
  const data = reactive({ management_office: undefined, installation_office: {}, errors: {} })
  const isUpdating = ref(false)
  const updatable = ref(false)
  const installationOfficeId = ref(null)
  const operationOffices = reactive({ value: {} })
  const officeInCharges = ref([])
  const windowInnerWidth = ref(window.innerWidth);
  const initialManagementId = ref(null);
  const initialManagementType = ref(null);

  (async () => {
    props.switchContentLoading(true)
    installationOfficeId.value = route.params.id
    title.value = installationOfficeId.value ? '設置会社一覧/編集' : '設置会社一覧/作成'
    document.title = title.value

    AuthorityCacheApi.getWithParse().then((authorityList) => {
      if (installationOfficeId.value) {
        showableUpdateButton.value = !!authorityList["octlink.installation_office.update"]
      } else {
        showableUpdateButton.value = !!authorityList["octlink.installation_office.create"]
      }
      if (!authorityList["octlink.installation_office.read"]) {
        router.push('/')
      }
    })
    const { loginWithRedirect } = useAuth()
    loginWithRedirect.value = loginWithRedirect

    if (installationOfficeId.value != "") {
      const response = await Api().getInstallationOffice(installationOfficeId.value)
        .catch((error) => {
          if (error.request.status == 403) {
            props.showFlash("編集権限がありません。", "error", true)
            router.push('/installation-offices/')
          }
          return errorHandle(error.request)
        })
      if(!response) return
      updatable.value = true
      initialManagementId.value = response.management_officeable_id
      initialManagementType.value = response.management_officeable_type

      data.installation_office = response
      officeInCharges.value = data.installation_office.office_in_charge
    }

    props.switchContentLoading(false)
  })()

  onMounted(() => {
    if (installationOfficeId.value == "") {
      data.installation_office = {}
      data.installation_office.office_in_charge = []
      updatable.value = true
    }
  })

  function operationOfficeByManagementoffice () {
    if (!data?.management_office?.id) {
      operationOffices.value = []
      data.installation_office.office_in_charge = []
      return
    }

    Api().autoCompleteOperationOffices().then((response) => {
      operationOffices.value = []
      response.forEach((operationOffice) => {
        if (data?.management_office?.id == operationOffice.managementOfficeId && data?.management_office?.type == operationOffice.managementOfficeType) {
          operationOffices.value.push(operationOffice)
        }
      })
    }).catch((error) => {
      return errorHandle(error.response)
    })
    data.installation_office.office_in_charge = []
    if (data?.management_office?.id == data.installation_office.management_officeable_id && data?.management_office?.type == data.installation_office.management_officeable_type ) {
      data.installation_office.office_in_charge = officeInCharges.value
    }
  }

  function updateInstallationOffice (instalationOffice) {
    Object.keys(instalationOffice).forEach((key) => {
      data.installation_office[key] = instalationOffice[key]
    })
  }

  function updateContactName (contactName) {
    const key = Object.keys(contactName)[0]
    data.installation_office[key] = contactName[key]
  }

  watch(() => data.management_office, () => {
    operationOfficeByManagementoffice()
  })

  function submit () {
    isUpdating.value = true
    const requestParams = makeRequestParams()
    const id = requestParams.id
    if (id) {
      Api().updateInstallationOffice(id, requestParams).then(() => {
        success()
      }).catch((error) => {
        errorHandle(error.response)
      })
    } else {
      Api().createInstallationOffice(requestParams).then(() => {
        success()
      }).catch((error) => {
        errorHandle(error.response)
      })
    }
  }

  function makeRequestParams () {
    const requestParams = {
      management_officeable_id: data.management_office?.id,
      management_officeable_type: data.management_office?.type,
      comment: data.installation_office.comment,
      company_name: data.installation_office.company_name,
      company_name_japan: data.installation_office.company_name_japan,
      contact_name: data.installation_office.contact_name,
      email: data.installation_office.email,
      id: data.installation_office.id,
      password_expire_days: data.installation_office.password_expire_days ?? 0,
      password_expire_days_checkbox: !!data.installation_office.password_expire_days,
      status: data.installation_office.status
    }

    const officeIncharge = data.installation_office.office_in_charge
    requestParams.office_in_charge = []
    officeIncharge.forEach((v) => {
      requestParams.office_in_charge.push(v.id)
    })

    return requestParams
  }

  function success () {
    isUpdating.value = false
    router.push('/installation-offices')
    props.showFlash("保存に成功しました。", "success", true)
  }

  function errorHandle (res) {
    switch (res.status) {
      case 401:
        loginWithRedirect({ appState: { targetUrl: location.pathname } })
        updatable.value = false
        break
      case 403:
        props.showFlash("編集権限がありません。", "error", true)
        updatable.value = false
        break
      case 422:
        data.errors = res.data.errors
        scrollToErrorComponent()
    }
    return isUpdating.value = false
  }

  function handleResize () {
    windowInnerWidth.value = window.innerWidth
  }

  window.addEventListener('resize', handleResize)

  onBeforeUnmount(() => {
    props.switchContentLoading(false)
  })
</script>

<template>
  <div style="min-height: 45vw;">
    <h4 class="content pl-3 w-100">{{title}}</h4>
    <div class="row mr-3 ml-3 v-theme--light">
      <div class="mb-4">
        <v-row class="text-form pl-4 pr-3 mb-3">
          <v-col :cols="windowInnerWidth > 800 ? 2 : 3" class="my-auto">
            <h6 class="pl-4 mb-0">管理会社<span style="color: red">✴︎</span></h6>
          </v-col>
          <v-col cols="10" class="pt-0">
            <ManagementOfficeAutoComplete
              v-model="data.management_office"
              selectFirstIfOnlyOne
              :error-messages="data?.errors['management_officeable_id']"
              :initialManagementId="initialManagementId"
              :initialManagementType="initialManagementType"
            />
          </v-col>
        </v-row>
        <CompanyNameForm
          :companyName="data.installation_office?.company_name"
          :companyNameKana="data.installation_office?.company_name_japan"
          :errors="data?.errors"
          @inputValue="updateInstallationOffice"
          :windowInnerWidth="windowInnerWidth"
        />
        <ContactNameForm
          :contactName="data.installation_office?.contact_name"
          @inputValue="updateContactName"
          :errors="data.errors?.contact_name"
          :windowInnerWidth="windowInnerWidth"
        />
        <EmailForm
          :email="data.installation_office?.email"
          @inputValue="updateInstallationOffice"
          :errors="data?.errors"
          :windowInnerWidth="windowInnerWidth"
        />
        <OfficeInCharge
          :operationOfficeInfo="data.installation_office.office_in_charge"
          :operationOfficeLists="operationOffices.value"
          @selected="updateInstallationOffice"
          :windowInnerWidth="windowInnerWidth"
        />
        <CommentForm
          :comment="data.installation_office?.comment"
          @inputComment="updateInstallationOffice"
          :errors="data.errors?.comment"
          :windowInnerWidth="windowInnerWidth"
        />
        <div v-if="updatable && showableUpdateButton" class="mt-4 mb-4 mr-4" style="text-align: right;">
          <v-btn
            class="exec-btn"
            @click="submit"
            :loading="isUpdating"
          >
            保存
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
