<template>
  <tr class="table-header">
    <td rowspan="2">日付</td>
    <td rowspan="2">曜日</td>
    <td rowspan="2">始業</td>
    <td rowspan="2">終業</td>
    <td rowspan="2">運行総時間</td>
    <td rowspan="2">終業 ~ 始業時間</td>
    <td rowspan="2">走行距離</td>
    <td colspan="8">拘束時間</td>
    <td rowspan="2">労働時間</td>
    <td rowspan="2">重複時間</td>
    <td colspan="3">休息期間</td>
    <td rowspan="2">時間外労働時間</td>
    <td rowspan="2">深夜労働時間</td>
  </tr>
  <tr class="table-header">
    <td>運転</td>
    <td>休憩</td>
    <td>荷積</td>
    <td>荷卸</td>
    <td>荷待</td>
    <td>待機</td>
    <td>その他</td>
    <td>計</td>
    <td>開始</td>
    <td>終了</td>
    <td>合計</td>
  </tr>
</template>

<style scoped>
#trip-detail-table td {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 2px;
  padding-right: 2px;
  text-align: center;
  font-weight: bold !important;
  border: 1 !important;
  border-width: 2px !important;
}
#trip-detail-table .table-header {
  background-color: silver !important;
}
#trip-detail-table tr,
p {
  line-height: 15px;
  font-size: 13px;
  font-weight: bold;
}
</style>
