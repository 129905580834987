<script setup>
import Input from "@/components/VehicleFormParts/SettingFormInput.vue";
import { ref, defineProps, defineEmits, watch } from 'vue'

const props = defineProps(['modelValue', 'formInputs', 'errors', 'channelKey', 'channelLabel']);
const emit = defineEmits(['update:modelValue']);

const form = ref(props.modelValue)

const modelValue = ref(props.modelValue);
const channelStatusList = ['ON', 'OFF'];

const upper_key = props.channelKey + '_upper';
const lower_key = props.channelKey + '_lower';
const errorKeyPrefix = 'initial_params.tdsensor';
const keys = {}
keys[upper_key] = 'チャンネル上限'
keys[lower_key] = 'チャンネル下限'

watch(props, () => {
  form.value = props.modelValue
  if (props.formInputs?.form?.tdsensor[props.channelKey] != undefined &&
    props.modelValue[props.channelKey] == undefined) {
    form.value[props.channelKey] = 'OFF';
  }
  if (props.formInputs?.form?.tdsensor[upper_key] != undefined &&
    props.modelValue[upper_key] === undefined) {
    form.value[upper_key] = '+155';
  }
  if (props.formInputs?.form?.tdsensor[lower_key] != undefined &&
    props.modelValue[lower_key] === undefined) {
    form.value[lower_key] = '-30';
  }
}, { deep: true })

watch(form, () => {
  emit('update:modelValue', form.value)
}, { deep: true })

</script>

<template>
      <div class="col-4">
        <v-select
          class="small mt-2"
          :label="props.channelLabel+'（ON/OFF）'"
          v-model="form[channelKey]"
          :items="channelStatusList"
          :error-messages="errors[errorKeyPrefix+'.'+channelKey]"
        >
        </v-select>
      </div>
      <template v-for="title, key in keys" v-bind:key="key">
        <Input
          :label="title"
          v-model="form[key]"
          :formKey="key"
          parentKey="tdsensor"
          :formInputs="formInputs"
          :errors="errors[errorKeyPrefix+'.'+key]"
          colClass="col-4"
        />
      </template>
</template>
