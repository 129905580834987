<template>
  <template v-if="!!formInputs?.form?.record">
    <p class="vehicle-ini-setting-title">RECORD</p>
    <div class="row mr-3 v-theme--light">
      <template v-for="(title, key) in keys" v-bind:key="key">
        <Input
          :label="title"
          v-model="form[key]"
          :formKey="key"
          parentKey="record"
          :formInputs="formInputs"
          :readonly="selectDefault && readonlyInputs.includes(key)"
          :errors="errors[errorKeyPrefix + '.' + key]"
        ></Input>
      </template>
    </div>
  </template>
</template>

<script>
import Api from "@/services/api/ApiServiceFabrick";
import Input from "@/components/VehicleFormParts/SettingFormInput.vue";

export default {
  props: {
    modelValue: {},
    formInputs: {},
    selectDefault: {
      type: Boolean,
      default: false,
    },
    errors: {},
  },
  data() {
    return {
      form: {
        camr_on: '',
        camf_bitrate: '',
        camr_bitrate: '',
        camf_fps: '',
        camr_fps: '',
        sound_on: '',
        cami_on: '',
        cami_bitrate: '',
        cami_fps: '',
      },
      readonlyInputs: [],
      errorKeyPrefix: "initial_params.record",
      keys: {
        camr_on: "CAMR_ON",
        camf_bitrate: "CAMF_BITRATE",
        camr_bitrate: "CAMR_BITRATE",
        camf_fps: "CAMF_FPS",
        camr_fps: "CAMR_FPS",
        sound_on: "SOUND_ON",
        cami_on: "CAMI_ON",
        cami_bitrate: "CAMI_BITRATE",
        cami_fps: "CAMI_FPS",
      },
    };
  },
  methods: {},
  watch: {
    modelValue: {
      handler() {
        this.form = Object.assign(this.form, this.modelValue)
      },
    },
    formInputs: {
      handler() {
        if (!this.formInputs?.form?.record) return;
        const key = Object.keys(this.formInputs.default_params)[0];
        this.readonlyInputs = Object.keys(
          this.formInputs.default_params[key]?.parameters?.record ?? []
        );
      },
    },
    form: {
      handler() {
        this.$emit("update:modelValue", this.form);
      },
      deep: true,
    },
  },
  components: {
    Input,
  },
};
</script>

<style></style>
