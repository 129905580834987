<template>
  <tr class="table-header">
    <td nowrap>単日当たり拘束時間</td>
    <td nowrap>拘束日数</td>
  </tr>
  <tr class="table-header">
    <td nowrap>平均/目標</td>
    <td nowrap>実績/想定</td>
  </tr>
</template>

<style scoped>
.summary-times-container td {
  padding: 7px;
  text-align: center;
  font-weight: bold !important;
  border: 1 !important;
  border-width: 2px !important;
}
.summary-times-container .table-header {
  background-color: silver !important;
}
.summary-times-container tr {
  line-height: 15px;
  font-size: 13px;
}
</style>
