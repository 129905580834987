<template>
  <template v-if="!!formInputs?.form?.daily_early">
    <p class="vehicle-ini-setting-title">DAILYYEARLY</p>
    <div class="row mr-3 v-theme--light">
      <Input
        label="STARTUP"
        v-model="form.startup"
        formKey="startup"
        parentKey="daily_early"
        :formInputs="formInputs"
        :readonly="selectDefault && readonlyInputs.includes('startup')"
        :errors="errors[errorKeyPrefix+'.startup']"
      />
    </div>
  </template>
</template>

<script>
import Api from "@/services/api/ApiServiceFabrick";
import Input from "@/components/VehicleFormParts/SettingFormInput.vue";

export default {
  props: {
    modelValue: {},
    formInputs: {},
    selectDefault: {
      type: Boolean,
      default: false
    },
    errors: {},
  },
  data() {
    return {
      form: {
        startup: '',
      },
      readonlyInputs: [],
      errorKeyPrefix: 'initial_params.daily_early',
    }
  },
  methods: {
  },
  watch: {
    modelValue: {
      handler() {
        this.form = Object.assign(this.form, this.modelValue)
      }
    },
    formInputs: {
      handler() {
        if (!this.formInputs?.form?.daily_early) return
        const key = Object.keys(this.formInputs.default_params)[0]
        this.readonlyInputs = Object.keys(this.formInputs.default_params[key]?.parameters?.daily_early ?? [])
      }
    },
    form: {
      handler() {
        this.$emit('update:modelValue', this.form)
      },
      deep: true
    },
  },
  components: {
    Input,
  },
}
</script>

<style>
</style>
