<template>
  <template v-if="dialogState">
  <GDialog
    v-model="dialogState"
    max-width="90%"
    transition="custom-from-bottom-transition"
  >
  <Form
    v-model="dialogState"
    :item="form"
    :showFlash="showFlash"
    @updateItem="updateItem"
  />
  </GDialog>
  </template>
<slot name="button" :dialogState="dialogState">
  <v-btn class="exec-btn" @click="dialogState = true" >
    新規作成
  </v-btn>
</slot>

</template>

<script setup>
  import 'gitart-vue-dialog/dist/style.css'
  import { GDialog } from 'gitart-vue-dialog'
  import Buttons from '@/components/SpotParts/ModalButtons'
  import Form from '@/components/DailyReportLayoutParts/CreateOrUpdateForm'
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import { ref, defineProps, defineEmits, watch } from 'vue'

  const props = defineProps([
    'showFlash',
    'item',
  ]);
  const emit = defineEmits(['updateItem', 'change']);

  const form = ref({});
  const dialogState = ref(false);

  const updateItem = () => {
    emit('updateItem');
  };

  watch(dialogState, () => {
    if (dialogState.value) {
      form.value = JSON.parse(JSON.stringify(props.item));
      emit('change', form.value)
    }
  });
</script>

<style>
</style>
