<template>
  <template v-if="dialogState">
  <GDialog
    v-model="dialogState"
    max-width="90%"
    transition="custom-from-bottom-transition"
  >
  <Form
    v-model="dialogState"
    :item="form"
    :showFlash="showFlash"
    @updateItem="updateItem"
    :label="label"
    :isUpdateAuthority="isCreateAuthority"
    :isOperationOffice="isOperationOffice"
    :isSubManagementOffice="isSubManagementOffice"
  />
  </GDialog>
  </template>
<slot name="button" :dialogState="dialogState">
  <v-btn class="exec-btn" @click="dialogState = true" >
    新規作成
  </v-btn>
</slot>

</template>

<script>
import 'gitart-vue-dialog/dist/style.css'
import { GDialog } from 'gitart-vue-dialog'
import Buttons from '@/components/SpotParts/ModalButtons'
import Form from '@/components/SpotParts/SpotUpdateModalForm'
import { useAuth } from "@/services/user/Auth0UserProvider"

export default {
  setup() {
    const { loginWithRedirect } = useAuth();
    return {loginWithRedirect }
  },
  props: {
    showFlash: {
      type: Function,
    },
    item: {},
    icon: {
      type:String,
      default: 'edit_note',
    },
    label: {
      type: String,
      default: '登録',
    },
    isCreateAuthority: {
      type: Boolean,
    },
    isOperationOffice: {
      type: Boolean,
      default: false,
    },
    isSubManagementOffice: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialogState: false,
    form: {},
  }),
  methods: {
    updateItem() {
      this.$emit('updateItem')
    }
  },
  watch: {
    dialogState: {
      handler() {
        if (this.dialogState) {
          this.form = JSON.parse(JSON.stringify(this.item));
          this.$emit('change', this.form)
        }
      }
    }
  },
  components: {
    GDialog,
    Form,
  },
}
</script>

<style>
</style>
