import UserType from "@/types/account";
import SelfUserType from "@/types/SelfAccount";

class UserHelper {
  public static getOrganizationType(user: UserType|SelfUserType): string {
    let type = user.login_organization_type;
    if (!type) type = user.officeable_type;
    return type;
  }
  public static getOrganizationId(user: UserType|SelfUserType): number {
    let id = user.login_organization_id;
    if (!id) id = user.officeable_id;
    return id;
  }
}

export default UserHelper
