import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-710ea10c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sort-field" }
const _hoisted_2 = {
  style: {"display":"inline-block"},
  class: "pr-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass(["nc-icon nc-stre-up sort-icon d-block", _ctx.getClassForSort(_ctx.sortType.ASK)]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('changeSort', _ctx.sortField, _ctx.sortType.ASK)))
      }, null, 2),
      _createElementVNode("span", {
        class: _normalizeClass(["nc-icon nc-stre-down sort-icon d-block", _ctx.getClassForSort(_ctx.sortType.DESC)]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('changeSort', _ctx.sortField, _ctx.sortType.DESC)))
      }, null, 2)
    ]),
    _createElementVNode("span", {
      class: "pt-1 sort-icon",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('toggleSort', _ctx.sortField)))
    }, _toDisplayString(_ctx.columnTitle), 1)
  ]))
}