<script setup>
  import { reactive, ref, watch } from 'vue'

  const props = defineProps({
    operationOfficeInfo: Object,
    operationOfficeLists: Array,
    windowInnerWidth: Number
  })
  const emits = defineEmits(['selected'])
  const title = "運営会社"
  const officeInCharges = reactive({ value: [] })
  const manageOfficeOfCompanies = reactive({ value: [] })
  const selectedList = reactive({ value: [] })
  const candidateList = reactive({ value: [] })

  watch(() => props.operationOfficeLists, (officeList) => {
    manageOfficeOfCompanies.value = officeList
  })

  watch(() => props.operationOfficeInfo, (info) => {
    const targetIds = pluckIdListString(info)
    const selectedIds = pluckIdListString(selectedList.value)
    if (selectedIds == targetIds) return

    officeInCharges.value = info
    selectedList.value = officeInCharges.value.map((office) => {
      return { id: office.id, operation_office_name: office.operation_office_name }
    })
  })

  watch(() => manageOfficeOfCompanies.value, () => {
    makeCandidateAndSelectedLists(selectedList.value)
  })

  watch(() => selectedList.value, (selected) => {
    makeCandidateAndSelectedLists(selected)

    const emitValus = selected.filter((office) => { return !manageOfficeOfCompanies.value.includes(office.id) })
    emits('selected', { office_in_charge: emitValus })
  })

  function makeCandidateAndSelectedLists (selected) {
    const officeIds = selected.map((office) => { return office.id })
    if (manageOfficeOfCompanies.value == {}) return
    const candidateAccounts = manageOfficeOfCompanies.value.filter((office) => { return !officeIds.includes(office.id) })
    candidateList.value = candidateAccounts.map((office) => {
      return { id: office.id, operation_office_name: office.name }
    })
  }

  const selectInSelectedList = ref([])
  const selectInCandidateList = ref([])

  const addOfficeInCharge = () => {
    const targetAccounts = candidateList.value.filter((officeInfo) => {
      return Object.values(selectInCandidateList.value).includes(officeInfo.id)
    })

    selectedList.value = selectedList.value.concat(targetAccounts)
  }

  const clearOfficeInCharge = () => {
    selectedList.value = selectedList.value.filter((officeInfo) => {
      return !Object.values(selectInSelectedList.value).includes(officeInfo.id)
    })
  }

  const pluckIdListString = (arr) => {
    const ids = []
    arr.forEach((obj) => {
      ids.push(obj.id)
    })
    return JSON.stringify(ids.sort())
  }
</script>

<template>
  <div v-show="windowInnerWidth > 800" class="mb-4">
    <v-row class="checkbox-with-number-text-form pl-4 pr-3 mb-2">
      <v-col cols="2">
        <h6 class="pt-2 pl-4" style="font-weight: bold;">{{title}}</h6>
      </v-col>
      <v-col>
        <div>
          <div style="font-weight: bold; font-size: 14px;" class="pt-2">一覧</div>
          <select class="form-control" style="height: 200px;" multiple="multiple" v-model="selectInCandidateList">
            <option v-for="(officeInfo, index) in candidateList.value" :key="index" :value="officeInfo.id">
              {{officeInfo.operation_office_name}}
            </option>
          </select>
        </div>
      </v-col>
      <v-col cols="1">
        <div class="text-center" style="margin-top: 40px;">
          <v-btn
            style="color: white; font-weight: bold; background-color: #3388DC;"
            class="mb-2"
            @click="addOfficeInCharge"
          >
            ▶︎
          </v-btn>
          <v-btn
            style="color: white; font-weight: bold; background-color: #3388DC;"
            @click="clearOfficeInCharge"
          >
            ◀︎
          </v-btn>
        </div>
      </v-col>
      <v-col>
        <div>
          <div style="font-weight: bold; font-size: 14px;" class="pt-2">選択済</div>
          <select class="form-control" style="height: 200px;" multiple="multiple" v-model="selectInSelectedList">
            <option v-for="(officeInfo, index) in selectedList.value" :key="index" :value="officeInfo.id">
              {{officeInfo.operation_office_name}}
            </option>
          </select>
        </div>
      </v-col>
    </v-row>
  </div>

  <div v-show="windowInnerWidth <= 800" class="mb-4">
    <v-row class="checkbox-with-number-text-form pl-4 pr-3">
      <v-col cols="3">
        <h6 class="pt-2 pl-4" style="font-weight: bold;">{{title}}</h6>
      </v-col>
      <v-col>
        <div>
          <div style="font-weight: bold; font-size: 14px;" class="pt-2">一覧</div>
          <select class="form-control" style="height: 200px;" multiple="multiple" v-model="selectInCandidateList">
            <option v-for="(officeInfo, index) in candidateList.value" :key="index" :value="officeInfo.id">
              {{officeInfo.operation_office_name}}
            </option>
          </select>
        </div>
      </v-col>
    </v-row>
    <v-row class="checkbox-with-number-text-form pl-4 pr-3">
      <v-col cols="3">
      </v-col>
      <v-col class="text-center">
        <div class="d-flex justify-content-center">
          <div style="margin-top: 10px; margin-right: 10px;">
            <v-btn
              style="color: white; font-weight: bold; background-color: #3388DC;"
              @click="clearOfficeInCharge"
            >
              ◀︎
            </v-btn>
          </div>
          <div style="margin-top: 10px;">
            <v-btn
              style="color: white; font-weight: bold; background-color: #3388DC;"
              class="mb-2"
              @click="addOfficeInCharge"
            >
              ▶︎
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="checkbox-with-number-text-form pl-4 pr-3 mb-2">
      <v-col cols="3">
      </v-col>
      <v-col>
        <div>
          <div style="font-weight: bold; font-size: 14px;" class="pt-2">選択済</div>
          <select class="form-control" style="height: 200px;" multiple="multiple" v-model="selectInSelectedList">
            <option v-for="(officeInfo, index) in selectedList.value" :key="index" :value="officeInfo.id">
              {{officeInfo.operation_office_name}}
            </option>
          </select>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
