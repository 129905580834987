<script setup>
import { cvrUploadsStatuses } from '@/services/consts';
import { ref, defineProps, computed, watch } from 'vue'

const props = defineProps(['item', 'isChild']);

const getProgress = function() {
  let progress = 0
  if (props.isChild) {
    progress = props.item.progress;
  } else {
    progress = props.item.summary_progress;
  }
  if (progress <= 0) progress = 0
  return progress
}
const progress = ref(getProgress());

watch(
  () => props.item,
  () => { progress.value = getProgress() },
  { deep: true }
)

const itemStatus = computed(() => {
  if (props.isChild) return props.item.status?.toLowerCase() ?? '';
  return props.item.summary_status?.toLowerCase() ?? '';
})

const viewStatus = computed(() => {
  return cvrUploadsStatuses[itemStatus.value]?.label;
})

const viewStatusClass = computed(() => {
  return cvrUploadsStatuses[itemStatus.value]?.class;
})

</script>

<template>
  <div class="mx-4 cvr-upload-status-div">
    <template v-if="itemStatus == 'progressing'">
      <v-progress-linear
        v-model="progress"
        class="cvr-upload-status"
        color="light-blue"
        height="25"
        striped
      >
        <template v-slot:default>
          <div class="px-2">
          <span class="progress-label pr-1">{{Math.ceil(progress)}}%</span>
          <strong class="progress-label">{{viewStatus}}</strong>
          </div>
        </template>
      </v-progress-linear>
    </template>
    <template v-else>
      <div class="text-center px-2 cvr-upload-status" :class="viewStatusClass">
        {{viewStatus}}
      </div>
    </template>
  </div>
</template>

<style scoped>
.progress-label {
  color: #333333;
}
.cvr-upload-status-div {
  min-width: 8em;
}
.cvr-upload-status {
  width: max-content;
  min-width: 100%;
}
</style>
