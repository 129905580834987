<template>
  <div class="row m-3 mt-1 mb-1" v-if="!isSingleOperationOffice">
    <div class="d-flex justify-content-end ml-2 mb-2">
      <ManualLink :pageName="pageName" />
    </div>
    <SingleOperationOfficeAutoComplete
      v-model="operationOffice"
      selectFirstIfOnlyOne
    />
  </div>
  <div class="content" style="background-color: #DDDDDD;">
    <div class="d-flex justify-content-end p-3 pb-1" v-if="isSingleOperationOffice">
      <ManualLink :pageName="pageName" />
    </div>
    <div class="container-fluid">
      <div class="row">
        <DriversWithPotentiallyExcessiveHours :operationOfficeId="operationOfficeId"  v-if="viewCards"/>
        <OverTimeCard :operationOfficeId="operationOfficeId" v-if="viewCards" />
      </div>
    </div>

    <div class="container-fluid" v-if="viewCards">
      <div class="row">
        <ActualCarCard :operationOfficeId="operationOfficeId" v-if="viewCards"/>
        <SafeDrivingCard :operationOfficeId="operationOfficeId" v-if="viewCards"/>
      </div>
    </div>
  </div>
</template>

<script>
  import UserHelper from "@/services/helpers/UserHelper";
  import SingleOperationOfficeAutoComplete from "@/components/AutoCompletes/SingleOperationOfficeAutoComplete"
  import ActualCarCard from '@/components/DashboardCards/ActualCarCard.vue'
  import SafeDrivingCard from '@/components/DashboardCards/SafeDrivingCard.vue'
  import DriversWithPotentiallyExcessiveHours from '@/components/DashboardCards/DriversWithPotentiallyExcessiveHours'
  import OverTimeCard from '@/components/DashboardCards/OverTimeCard'
  import Api from "@/services/api/ApiServiceFabrick";
  import ManualLink from "@/components/ManualLink";

  export default {
    components: {
      SingleOperationOfficeAutoComplete,
      ActualCarCard,
      SafeDrivingCard,
      DriversWithPotentiallyExcessiveHours,
      OverTimeCard,
      ManualLink
    },
    data () {
      return {
        isOperationOffice: false,
        isDepartment: false,
        operationOffice: null,
        pageName: "dashboard"
      }
    },
    computed: {
      isSingleOperationOffice() {
        return this.isOperationOffice || this.isDepartment;
      },
      operationOfficeId() {
        return this.operationOffice?.id
      },
      viewCards() {
        if (this.isSingleOperationOffice) return true;
        if (this.operationOfficeId) return true;
        return false
      }
    },
    setup() {
      document.title = "ダッシュボード"
      const iconCards = [
        {
          id: 1,
          header: {
            content: '１運行の平均運転時間',
          },
          body: {
            style: 'color:#FF9900',
            icon: 'timer',
            class: '',
            content: '07:24',
          },
          footer: {
            content: '目標運転時間 08:30',
          }
        },
        {
          id: 2,
          header: {
            content: '平均実車率',
          },
          body: {
            style: 'color:#3399CC',
            icon: 'local_shipping',
            class: 'inversion',
            content: '75.2 %',
          },
          footer: {
            content: '目標実車率 80.2%',
          }
        },
        {
          id: 3,
          header: {
            content: '平均安全運転スコア',
          },
          body: {
            style: 'color:#CC66FF',
            icon: 'sentiment_satisfied',
            class: '',
            content: '95.6 点',
          },
          footer: {
            content: '目標運転スコア 95点',
          }
        },
        {
          id: 4,
          header: {
            content: '平均燃費'
          },
          body: {
            style: 'color:#228B22',
            class: '',
            icon: 'eco',
            content: '4.5 km/L',
          },
          footer: {
            content: '目標燃費 4.2km/L',
          }
        }
      ]
      return { iconCards }
    },
    mounted() {
      Api().getSelfAccount().then((data) => {
        const type = UserHelper.getOrganizationType(data);
        this.isOperationOffice = type == 'App\\Models\\OperationOffice'
        this.isDepartment = type == 'App\\Models\\Department'
      })
    }
  }
</script>
<style>
  .big-toys {
    font-size: 75px;
  }
  .card-content {
    font-size: 50px;
  }
  .inversion {
    transform: scale(-1, 1);
  }
  .table-list td:first-child {
    padding-left: 6px !important;
  }
  .table-list td:last-child {
    display: inline-block !important;
    padding-right: 6px !important;
  }
  .chart-card-title {
    font-size: larger !important;
    font-weight: bold !important;
    color: black !important;
  }
  .card-body {
    padding-top: 0 !important;
  }
  .card {
    height: 100%;
  }
  .row {
    padding: 15px 0px;
  }
  .card label {
    text-transform: inherit !important;
  }
</style>
