<script setup>
import Api from '@/services/api/ApiServiceFabrick';
import AuthorityCacheApi from '@/services/models/AuthorityCacheApi';
import ManagementOfficeAutoComplete from '@/components/AutoCompletes/ManagementOfficeAutoComplete';
import SingleOperationOfficeAutoComplete from '@/components/AutoCompletes/SingleOperationOfficeAutoComplete';
import SingleDeviceTypeAutoComplete from '@/components/AutoCompletes/SingleDeviceTypeAutoComplete';
import { useAuth } from '@/services/user/Auth0UserProvider';
import { useRoute, useRouter } from 'vue-router';
import { reactive, ref, onMounted, nextTick, onBeforeUnmount } from 'vue';

const props = defineProps({
  showFlash: { type: Function },
  switchContentLoading: { type: Function },
});

const loginWithRedirect = ref(null);
const router = useRouter();
const route = useRoute();
const title = ref('');
const showableUpdateButton = ref(false);
const data = reactive({
  management_office: undefined,
  operation_office: undefined,
  device: {},
  errors: {},
});
const initialManagementId = ref();
const initialManagementType = ref();
const initialOperationId = ref();
const initialDeviceId = ref();
const isUpdating = ref(false);
const updatable = ref(false);
const deviceId = ref(null);
const makers = ref([]);
const makerNames = ref([]);
const c500Id = 5;
const windowInnerWidth = ref(window.innerWidth);

(async () => {
  props.switchContentLoading(true);
  deviceId.value = route.params.id;
  title.value = deviceId.value ? 'デバイス一覧/編集' : 'デバイス一覧/作成';
  document.title = title.value;

  AuthorityCacheApi.getWithParse().then((authorityList) => {
    if (deviceId.value) {
      showableUpdateButton.value = !!authorityList['octlink.device.update'];
    } else {
      showableUpdateButton.value = !!authorityList['octlink.device.create'];
    }
    if (!authorityList['octlink.device.read']) {
      router.push('/');
    }
  });
  const { loginWithRedirect } = useAuth();
  loginWithRedirect.value = loginWithRedirect;

  if (deviceId.value != '') {
    const response = await Api()
      .getDevice(deviceId.value)
      .catch((error) => {
        if (error.request.status == 403) {
          router.push('/devices');
          return props.showFlash('権限がありません。', 'error', true);
        }
        return errorHandle(error.request);
      });
    if (!response) return;
    updatable.value = true;

    makeInitialParams(response);
  }

  props.switchContentLoading(false);
})();

onMounted(() => {
  if (deviceId.value == '') {
    data.device = {};
    updatable.value = true;
  }
  getAllMakers();
});

function makeInitialParams(response) {
  initialManagementId.value = response.management_officeable_id
  initialManagementType.value = response.management_officeable_type
  initialOperationId.value = response.operation_office_id;
  initialDeviceId.value = response.model_type;
  data.device = response;
  data.device.maker = response.maker.name;
}

function getAllMakers() {
  Api()
    .getMakers()
    .then((res) => {
      makers.value = res;
      makerNames.value = makeMakers(res);
    })
    .catch((error) => {
      return errorHandle(error.response);
    });
}

function makeMakers(res) {
  let array = [];
  res.forEach((value) => {
    array.push(value.name);
  });
  return array;
}

function selectedMaker(maker) {
  let id = null;
  makers.value.forEach((value) => {
    if (maker == value.name) id = value.id;
  });
  return id;
}

function submit() {
  isUpdating.value = true;
  const requestParams = makeRequestParams();
  Api()
    .createOrUpdateDevice(deviceId.value, requestParams)
    .then(() => {
      success();
    })
    .catch((error) => {
      errorHandle(error.response);
    });
}

function makeRequestParams() {
  const requestParams = {
    id: deviceId.value,
    maker_id: selectedMaker(data.device?.maker),
    model_number: data.device?.model_number,
    device_id: data.device?.device_id,
    sim_id: data.device?.sim_id,
    operation_office_id: data.operation_office?.id,
    management_officeable_id: data.management_office?.id,
    management_officeable_type: data.management_office?.type,
    status: 1,
    imei: data.device.imei,
    model_type: data.device.model_type?.id,
  };

  return requestParams;
}

function success() {
  isUpdating.value = false;
  router.push('/devices');
  props.showFlash('保存に成功しました。', 'success', true);
}

function errorHandle(res) {
  switch (res.status) {
    case 401:
      loginWithRedirect({ appState: { targetUrl: location.pathname } });
      updatable.value = false;
      break;
    case 403:
      props.showFlash('編集権限がありません。', 'error', true);
      showableUpdateButton.value = false;
      updatable.value = false;
      break;
    case 422:
      data.errors = res.data.errors;
      scrollToErrorComponent(data.errors);
  }
  return (isUpdating.value = false);
}

function scrollToErrorComponent(errors) {
  if (!Object.keys(errors).length) return;
  nextTick(() => {
    const firstErrorParentDom =
      document.getElementsByClassName('validation-error')[0].parentElement;
    firstErrorParentDom.scrollIntoView(true);
  });
}

function handleResize() {
  windowInnerWidth.value = window.innerWidth;
}

window.addEventListener('resize', handleResize);

onBeforeUnmount(() => {
  props.switchContentLoading(false);
});
</script>
<template>
  <div style="min-height: 45vw">
    <h4 class="content pl-3 w-100">{{ title }}</h4>
    <div class="row mr-3 ml-3 v-theme--light">
      <div class="mb-4">
        <v-row class="text-form pl-4 pr-3 mb-3">
          <v-col :cols="windowInnerWidth > 800 ? 2 : 3" class="my-auto">
            <h6 class="pl-4 mb-0">
              メーカー<span style="color: red">✴︎</span>
            </h6>
          </v-col>
          <v-col cols="10" class="pt-0">
            <v-select
              class="pt-2"
              v-model="data.device.maker"
              :items="makerNames"
              outlined
              :error-messages="data?.errors['maker_id']"
            />
          </v-col>
        </v-row>
        <v-row class="text-form pl-4 pr-3 mb-3">
          <v-col :cols="windowInnerWidth > 800 ? 2 : 3" class="my-auto">
            <h6 class="pl-4 mb-0">
              製造番号<span style="color: red">✴︎</span>
            </h6>
          </v-col>
          <v-col cols="10" class="pt-0">
            <v-text-field
              class="pt-2"
              v-model="data.device.model_number"
              outlined
              :error-messages="data?.errors['model_number']"
            />
          </v-col>
        </v-row>
        <v-row class="text-form pl-4 pr-3 mb-3">
          <v-col :cols="windowInnerWidth > 800 ? 2 : 3" class="my-auto">
            <h6 class="pl-4 mb-0">
              デバイスID<span style="color: red">✴︎</span>
            </h6>
          </v-col>
          <v-col cols="10" class="pt-0">
            <v-text-field
              class="pt-2"
              v-model="data.device.device_id"
              outlined
              :error-messages="data?.errors['device_id']"
            />
          </v-col>
        </v-row>
        <v-row class="text-form pl-4 pr-3 mb-3">
          <v-col :cols="windowInnerWidth > 800 ? 2 : 3" class="my-auto">
            <h6 class="pl-4 mb-0">SIM ID<span style="color: red">✴︎</span></h6>
          </v-col>
          <v-col cols="10" class="pt-0">
            <v-text-field
              class="pt-2"
              v-model="data.device.sim_id"
              outlined
              :error-messages="data?.errors['sim_id']"
            />
          </v-col>
        </v-row>
        <v-row class="text-form pl-4 pr-3 mb-3">
          <v-col :cols="windowInnerWidth > 800 ? 2 : 3" class="my-auto">
            <h6 class="pl-4 mb-0">
              IMEI<span
                v-if="data.device.model_type?.id == c500Id"
                style="color: red"
                >✴︎</span
              >
            </h6>
          </v-col>
          <v-col cols="10" class="pt-0">
            <v-text-field
              class="pt-2"
              v-model="data.device.imei"
              outlined
              :error-messages="data?.errors['imei']"
            />
          </v-col>
        </v-row>
        <v-row class="text-form pl-4 pr-3 mb-3">
          <v-col :cols="windowInnerWidth > 800 ? 2 : 3" class="my-auto">
            <h6 class="pl-4 mb-0">型番</h6>
          </v-col>
          <v-col cols="10" class="pt-0">
            <SingleDeviceTypeAutoComplete
              v-model="data.device.model_type"
              :initialItemId="initialDeviceId"
            />
          </v-col>
        </v-row>
        <v-row class="text-form pl-4 pr-3 mb-3">
          <v-col :cols="windowInnerWidth > 800 ? 2 : 3" class="my-auto">
            <h6 class="pl-4 mb-0">
              管理会社<span style="color: red">✴︎</span>
            </h6>
          </v-col>
          <v-col cols="10" class="pt-0">
            <ManagementOfficeAutoComplete
              v-model="data.management_office"
              :errorMessages="data?.errors['management_officeable_id']"
              :initialManagementId="initialManagementId"
              :initialManagementType="initialManagementType"
              selectFirstIfOnlyOne
            />
          </v-col>
        </v-row>
        <v-row class="text-form pl-4 pr-3 mb-3">
          <v-col :cols="windowInnerWidth > 800 ? 2 : 3" class="my-auto">
            <h6 class="pl-4 mb-0">
              運営会社<span style="color: red">✴︎</span>
            </h6>
          </v-col>
          <v-col cols="10" class="pt-0">
            <SingleOperationOfficeAutoComplete
              v-model="data.operation_office"
              :initialOperationId="initialOperationId"
              :managementOffice="data.management_office"
              :errorMessages="data?.errors['operation_office_id']"
              selectFirstIfOnlyOne
            />
          </v-col>
        </v-row>
        <div
          v-if="updatable && showableUpdateButton"
          class="mt-4 mb-4 mr-4"
          style="text-align: right"
        >
          <v-btn class="exec-btn" @click="submit" :loading="isUpdating">
            保存
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
