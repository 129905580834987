<template>
  <tr class="table-header">
    <td colspan="14">合計時間</td>
  </tr>
  <tr class="table-header">
    <td nowrap>拘束</td>
    <td nowrap>労働時間</td>
    <td nowrap>走行距離</td>
    <td nowrap>運転</td>
    <td nowrap>休憩</td>
    <td nowrap>荷積</td>
    <td nowrap>荷卸</td>
    <td nowrap>荷待</td>
    <td nowrap>待機</td>
    <td nowrap>その他</td>
    <td nowrap>休息</td>
    <td nowrap>重複</td>
    <td nowrap>時間外労働時間</td>
    <td nowrap>深夜労働時間</td>
  </tr>
</template>

<style scoped>
.summary-times-container td {
  padding: 7px;
  text-align: center;
  font-weight: bold !important;
  border: 1 !important;
  border-width: 2px !important;
}
.summary-times-container .table-header {
  background-color: silver !important;
}
.summary-times-container tr {
  line-height: 15px;
  font-size: 13px;
}
</style>
