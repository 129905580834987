<template>
  <div id="modal-body" style="position: relative" :style="setOpacity()">
    <div v-for="(deliveryPlan, index) in deliveryPlanRecords" :key="index" class="w-100 text-center mt-5 every-24hours-report-container">
      <div class="w-75 d-flex mx-auto">
          <div class="w-50 text-white bg-dark text-center pt-3"><h3 class="font-weight-bold">{{items.title}}</h3></div>
          <div class="w-50 border border-3">
              <h3>{{deliveryPlan.driver_name}}</h3>
              <p>{{deliveryPlan.car_number}}</p>
          </div>
      </div>
      <div class="w-75 mt-3 mx-auto">
        <table class="table driving-record-table">
          <tr>
            <th>{{items.leavingWarehouse}}</th>
            <th>{{items.returningWarehouse}}</th>
            <th>{{items.totalOperationTime}}</th>
            <th>{{items.totalMileage}}</th>
            <th>{{items.drivingTime}}</th>
            <th>{{items.maxContinuousDrivingTime}}</th>
            <th>{{items.maximumSpeed}}</th>
          </tr>
          <tr>
            <td :id="`start-time-` + index" :data-start-time="deliveryPlan.leaving_warehouse">{{deliveryPlan.leaving_warehouse}}</td>
            <td :id="`end-time-` + index" :data-end-time="deliveryPlan.returning_warehouse">{{deliveryPlan.returning_warehouse}}</td>
            <td>{{deliveryPlan.total_operation_time}}</td>
            <td>{{deliveryPlan.total_mileage}}</td>
            <td>{{deliveryPlan.total_driving_time}}</td>
            <td>{{deliveryPlan.max_continuous_driving_time}}</td>
            <td>{{deliveryPlan.maximum_speed}}</td>
          </tr>
        </table>
      </div>
      <div :id="`output-time-` + index" class="w-75 mt-3 d-flex mx-auto" style="margin-top: -320px;">
        <div class="w-50 data-for-each-section">
          <h5 class="pt-3 text-white font-weight-bold">
            <span :class="`every-output-time-` + index"/>{{items.dataForEach}}
          </h5>
          <div class="pr-4 pl-4">
            <table class="table driving-record-table">
              <tr>
                <th>{{items.deliveryDate}}</th>
                <th>{{items.totalMileage}}</th>
                <th>{{items.drivingTime}}</th>
                <th>{{items.maximumSpeed}}</th>
              </tr>
              <tr>
                <td :id="`date-at-output-time-` + index"></td>
                <td :id="`distance-at-output-time-` + index"></td>
                <td :id="`driving-time-at-output-time-` + index" :data-driving-times="JSON.stringify(deliveryPlan.devided_driving_section)"></td>
                <td :id="`maximum-speed-at-output-time-` + index"></td>
              </tr>
            </table>
          </div>
        </div>
        <div class="w-50 d-flex bg-dark pl-5 pt-5">
          <div class="ml-3 mt-4 mr-3 text-white padding-top-2px">
            <h5 class="font-weight-bold text-white">{{items.outputTime}}</h5>
          </div>
          <div class="ml-2 mt-4 font-weight-bold">
            <input type="radio" :class="`output-time-` + index" :id="`four-minute-` + index" :name="`output-time-` + index" value="4min" :disabled="downloadingOrLoading()">
            <label class="ml-1 text-white" :for="`four-minute-` + index">{{outputTimeList['4min']}}</label>
            <input type="radio" class="ml-3" :class="`output-time-` + index" :id="`twelve-minute-` + index" :name="`output-time-` + index" value="12min" :disabled="downloadingOrLoading()">
            <label class="ml-1 text-white" :for="`twelve-minute-` + index">{{outputTimeList['12min']}}</label>
            <input type="radio" class="ml-3" :class="`output-time-` + index" :id="`eight-hour-` + index" :name="`output-time-` + index" value="8h" :disabled="downloadingOrLoading()">
            <label class="ml-1 text-white" :for="`eight-hour-` + index">{{outputTimeList['8h']}}</label>
            <input type="radio" class="ml-3" :class="`output-time-` + index" :id="`twenty-four-hours-` + index" :name="`output-time-` + index" value="24h" :checked="true" :disabled="downloadingOrLoading()">
            <label class="ml-1 text-white" :for="`twenty-four-hours-` + index">{{outputTimeList['24h']}}</label>
          </div>
        </div>
      </div>
      <div class="chart-area w-100 mx-auto mt-3">
        <!-- speed chart area -->
        <div :id="`speed-chart-region-` + index" class="w-75 mx-auto speed-chart d-flex mt-3 px-3 pt-3">
          <div class="d-flex align-items-center bg-dark text-white p-3 rounded">{{items.speed}}<br>{{items.speedUnit}}</div>
          <div class="w-100 bg-light rounded d-flex flex-column" style="position: relative">
            <canvas :id="`speed-chart-` + index" :data-speed-value-date-times="JSON.stringify(deliveryPlan.speeds)"></canvas>
            <div>
              <div :class="`x-label-` + index" class="d-flex justify-content-between">
                <p style="font-size: 12px;" class="x-label"></p>
                <p style="font-size: 12px;" class="x-label"></p>
                <p style="font-size: 12px;" class="x-label"></p>
                <p style="font-size: 12px;" class="x-label"></p>
                <p style="font-size: 12px;" class="x-label"></p>
              </div>
              <div class="pl-4" style="position: relative">
                <input :id="`speed-chart-slider-` + index" :name="`speed-chart-slider-` + index" class="w-100 input-range" type="range"/>
              </div>
            </div>
          </div>
        </div>
        <!-- distance chart area -->
        <div :id="`distance-chart-region-` + index" class="w-75 mx-auto distance-chart d-flex mt-3 p-3">
          <div class="d-flex align-items-center bg-dark text-white p-3 rounded">{{items.distance}}<br>{{items.distanceUnit}}</div>
          <div class="w-100 bg-light rounded" style="position: relative">
            <canvas :id="`distance-chart-` + index" :data-distance-value-date-times="JSON.stringify(deliveryPlan.distances)"></canvas>
            <div>
              <div :class="`x-label-` + index" class="d-flex justify-content-between">
                <p style="font-size: 12px;" class="x-label"></p>
                <p style="font-size: 12px;" class="x-label"></p>
                <p style="font-size: 12px;" class="x-label"></p>
                <p style="font-size: 12px;" class="x-label"></p>
                <p style="font-size: 12px;" class="x-label"></p>
              </div>
            </div>
            <div class="pl-4">
              <input :id="`distance-chart-slider-` + index" :name="`distance-chart-slider-` + index" class="w-100 input-range" type="range"/>
            </div>
          </div>
        </div>
        <!-- rotation speed chart area -->
        <div :id="`rotation-speed-chart-region-` + index" class="w-75 mx-auto rotation-speed-chart d-flex mt-3 p-3">
          <div class="d-flex align-items-center bg-dark text-white p-3 rounded">{{items.rotationSpeed}}<br>{{items.rotationSpeedUnit}}</div>
          <div class="w-100 bg-light rounded" style="position: relative">
            <canvas :id="`rotation-speed-chart-` + index" :data-rotation-speed-value-date-times="JSON.stringify(deliveryPlan.rotation_speeds)"></canvas>
            <div>
              <div :class="`x-label-` + index"  class="d-flex justify-content-between">
                <p style="font-size: 12px;" class="x-label"></p>
                <p style="font-size: 12px;" class="x-label"></p>
                <p style="font-size: 12px;" class="x-label"></p>
                <p style="font-size: 12px;" class="x-label"></p>
                <p style="font-size: 12px;" class="x-label"></p>
              </div>
            </div>
            <div class="pl-4">
              <input :id="`rotation-speed-chart-slider-` + index" :name="`rotation-speed-chart-slider-` + index" class="w-100 input-range" type="range"/>
            </div>
          </div>
        </div>

        <input type="hidden" class="delivery-plan-id" :name="`delivery-plan-id-` + index" :value="deliveryPlan.delivery_plan_id">
        <input type="hidden" class="from-date-time" :name="`from-date-time-` + index">
        <input type="hidden" class="to-date-time" :name="`to-date-time-` + index">
        <input type="hidden" class="speed-data" :name="`speed-data-` + index">
        <input type="hidden" class="distance-data" :name="`distance-data-` + index">
        <input type="hidden" class="rotation-speed-data" :name="`rotation-speed-data-` + index">
        <input type="hidden" class="x-label-str" :name="`x-label-str-` + index">
      </div>
    </div>
    <div class="d-flex justify-content-end m-3">
      <v-btn class="m-1 reset-btn" :class="changeTextColor(`text-white`)" @click="cancel">
        {{items.close}}
      </v-btn>
      <v-btn v-show="showableButtonArea" class="m-1 exec-btn" @click="submit" :style="{backgroundColor : downloading ? '#B3B8BB' : ''}" :disabled="downloadingOrLoading()">
        {{btnText()}}
      </v-btn>
    </div>
  </div>
  <div v-show="loading.status" class="loading" style="position: absolute; left: 46%; top: 10%;">
    <div class="d-flex min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div class="max-w-md w-full space-y-8">
        <v-loader-animation
          :text="'text-black'"
          :loading-text-style="'text-black text-lg'"
          loading-text="loading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {initCharts, createParameter} from "@/services/every_24hours_charts";
import Api from "@/services/api/ApiServiceFabrick";
import vLoaderAnimation from "@/components/template/v-loader-animation.vue";
import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
import {ref, reactive} from "vue"
import { useAuth } from "@/services/user/Auth0UserProvider"

export default {
  props: {
    deliveryPlanRecords: [],
    downloading: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, context) {
    const loading = reactive({ status: false })

    const showableButtonArea = ref(true)
    const setShowableButtonArea = async () => {
      const authorityList = await AuthorityCacheApi.getWithParse()
      showableButtonArea.value = authorityList["octlink.every_24hours_delivery_report.output"]
    }
    setShowableButtonArea()

    const { loginWithRedirect } = useAuth();

    const items = {
      title: "運行記録表",
      leavingWarehouse: "記録開始",
      returningWarehouse: "記録終了",
      totalOperationTime: "記録時間",
      totalMileage: "運行距離",
      drivingTime: "走行時間",
      maxContinuousDrivingTime: "最大連続走行時間",
      maximumSpeed: "最高速度",
      deliveryDate: "運行日付",
      outputTime: "出力時間",
      speed: "速度",
      speedUnit: "[km/h]",
      distance: "距離",
      distanceUnit: "[km]",
      rotationSpeed: "回転数",
      rotationSpeedUnit: "[rpm]",
      noData: "データがありません。",
      dataForEach: "毎のデータ",
      close: "閉じる",
    }

    const outputTimeList = {
      "4min": "4分",
      "12min": "12分",
      "8h": "8時間",
      "24h": "24時間"
    }

    const downloadingOrLoading = () => {
      return loading.status || props.downloading
    }

    const changeTextColor = (textColor) => {
      if (downloadingOrLoading()) return textColor
    }

    const setOpacity = () => {
      if (loading.status) return "opacity: 0.3"
    }

    const submit = () => {
      context.emit('submit', createParameter())
    }

    const cancel = () => {
      context.emit('close')
    }

    const btnText = () => {
      return props.downloading ? "ダウンロード中..." : "ダウンロード"
    }

    return {
      showableButtonArea,
      items,
      outputTimeList,
      loading,
      setOpacity,
      changeTextColor,
      submit,
      cancel,
      downloadingOrLoading,
      btnText,
      loginWithRedirect
    }
  },
  mounted() {
    for (let i = 0; i < this.deliveryPlanRecords.length; i++) {
      initCharts(i, Api().getDrivingRecords, this.loading, this.loginWithRedirect)
    }
  },
  components: {
    vLoaderAnimation
  }
}
</script>

<style>
.driving-record-table * {
  background-color: rgb(241, 236, 236);
  padding: 20px 0px 20px 0px;
}
.data-for-each-section {
  background-color: gray;
}

.output-time-input-area {
  background-color: rgb(241, 236, 236);
}

.v-btn--disabled.v-btn--variant-contained {
  color:inherit;
  box-shadow: inherit;
  background: rgb(21, 130, 156) !important;
}

.padding-top-2px {
  padding-top: 2px;
}

.input-range[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  height: 14px;
  width: 100%;
  background: #C0C0C0;
  border-radius: 10px;
  border: solid 3px #EEEEEE;
}

.input-range[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #53aeff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
}
</style>
