import conn from "@/services/ApiConnection"
import Response from '@/types/responses/getRestraintAndWorkingTime'
import RestraintData from '@/types/responses/getRestraintTimeData'
import RestraintAndWorkingTime from '@/types/RestraintAndWorkingTime'
import OperationOfficeSettingsMonthlyReport from '@/types/OperationOfficeSettingsMonthlyReport'
import Api from "@/services/api/ApiServiceFabrick";

class RestraintAndWorkingTimes {
  public static url = '/api/v1/summaries/restraint_and_working_times';

  public static async divideByThresholds(operationOfficeId: number|null = null):Promise<Response> {
    const currentMonth:number = (new Date()).getMonth() + 1
    let month:number = currentMonth
    const miliSeccondsInDay = 24 * 60 * 60 * 1000;

    const params: {
      cache: boolean,
      params?: { operation_office_id:number }
    } = {cache: true};
    if (operationOfficeId) {
      params['params'] = { operation_office_id: operationOfficeId };
    }
    const [res, operationOffices] = await Promise.all([
      conn.get(this.url, params),
      Api().autoCompleteOperationOffices()
    ])

    const response:Response = {
      restraint_data: [],
      working_data: {
        threshold: '0',
        threshold_miliseconds: 0,
        count: 0,
        list: [],
      },
      start_date: '',
      end_date: '',
      details: res.data,
    }
    let loaded = false;
    res.data.forEach((v:RestraintAndWorkingTime, i:number) => {
      if (v.officeable_type != "App\\Models\\OperationOffice") return true
      response.start_date = v.start_date;
      response.end_date = v.end_date;

      let setting = {} as OperationOfficeSettingsMonthlyReport
      operationOffices.forEach((operationOffice) => {
        if (operationOffice.id != v.officeable_id) return true
        setting = JSON.parse(operationOffice.settings_monthly_report) as OperationOfficeSettingsMonthlyReport
      })
      if (setting?.report_deadline_day != 0 && (new Date()).getDate() <= setting?.report_deadline_day) month = currentMonth - 1
      if (!loaded) {
        const _thresholds = setting.color_setting[month]
        for (let j = 1; j <= 3; j++) {
          const key = `alert_lv${j}`
          const threshold = this.getDefaultData(setting, month, key)
          response.restraint_data.push(threshold as RestraintData)
        }
        loaded = true;
      }

      let restraintPrediction = v.restraint
      let workingPrediction = v.working

      if (v.trip_count == 0) {
        v.prediction_restraint_time = 0;
        v.prediction_working_time = 0;
      } else {
        if (v.trip_count <= (v.bound_time_by_days ?? 0)) {
          const bound_time_by_days = v.bound_time_by_days ?? 1
          const trip_count = v.trip_count ?? 1
          let elapsedDaysPercentage = v.trip_count / bound_time_by_days
          elapsedDaysPercentage = elapsedDaysPercentage != 0 ? elapsedDaysPercentage : 1

          const restraintTimePerDay = v.restraint / trip_count
          restraintPrediction = restraintTimePerDay * bound_time_by_days

          const workingTimePerDay = v.working / trip_count
          workingPrediction = workingTimePerDay * bound_time_by_days
        }

        v.prediction_restraint_time = restraintPrediction
        v.prediction_working_time = workingPrediction
      }

      if (setting.over_time_thresholds_type == 1) {
        let working_threshold = '0:00';
        if (setting?.over_time_thresholds && setting.over_time_thresholds[month]) {
          working_threshold = setting.over_time_thresholds[month]
        }
        const threshold_hours_string = working_threshold?.split(':')[0] ?? '0'
        const threshold_minutes_string = working_threshold?.split(':')[1] ?? '00'
        const threshold_hours =   Number(threshold_hours_string)
        const threshold_minutes = Number(threshold_minutes_string)
        response.working_data.threshold = threshold_hours_string+' 時間 '+threshold_minutes_string+' 分'

        const threshold_miliseconds = ((threshold_hours * 60) + threshold_minutes) * 60
        response.working_data.threshold_miliseconds = threshold_miliseconds
        if (threshold_miliseconds < workingPrediction) {
          response.working_data.count++
          response.working_data.list.push(v.last_name + ' ' + v.first_name)
        }
      } else {
        response.working_data.threshold = '- 時間 - 分'
      }

      for (let j = 2; j >= 0; j--) {
        const miliseconds = response.restraint_data[j].threshold_miliseconds
        const workingThresholdMileSeconds = response.working_data.threshold_miliseconds!
        if (miliseconds && miliseconds < v.restraint) {
          v.restraint_over_threshold_background_color = '#fce4ec'
        }
        if (setting.over_time_thresholds_type == 1 && workingThresholdMileSeconds < v.working) {
          v.working_over_threshold_background_color = '#fce4ec'
        }
        if (setting.over_time_thresholds_type == 1 && workingThresholdMileSeconds < workingPrediction) {
          v.prediction_working_color = '#ff0000'
        }
        if (miliseconds && miliseconds < restraintPrediction) {
          response.restraint_data[j].count++
          response.restraint_data[j].list.push(v.last_name + ' ' + v.first_name)
          v.prediction_restraint_color = response.restraint_data[j].color
          return false
        }
      }
    })

    return new Promise((resolve,reject) => {
      resolve(response)
    })
  }

  public static getDefaultData(setting: OperationOfficeSettingsMonthlyReport, month:number, key: string)
  {
    const _thresholds = setting.color_setting[month]
    const data:RestraintData = {
      color: String(_thresholds[`${key}_color`] ?? ''),
      threshold: null,
      threshold_miliseconds: null,
      count: 0,
      list: [],
    }
    if (_thresholds[`${key}_time`]) {
      data.threshold = Number(_thresholds[`${key}_time`])
      data.threshold_miliseconds = Number(_thresholds[`${key}_time`]) * 60 * 60
      data.count = 0
    }
    return data
  }
}
export default RestraintAndWorkingTimes
