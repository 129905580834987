<template>
  <div class="daily-report-output-layout pt-3 pb-3">
    <v-row class="mb-1">
      <v-col>
        <v-combobox
          clearable
          class="small w-auto"
          v-model="selectedLayout"
          :items="layouts"
          label="レイアウト"
        >
        </v-combobox>
      </v-col>
      <v-col>
        <v-select
          class="small"
          v-model="selectedPageComposition"
          :items="displayPageCompositions"
          label="ページ単位"
          :clearable="false"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-select
          class="small"
          v-model="selectedOutputFormat"
          :items="displayOutputFormats"
          label="フォーマット"
          :clearable="false"
        >
        </v-select>
      </v-col>
      <v-col>
        <div
          v-if="isItTenkoKeeper"
          class="chart-display mt-b ml-2 d-flex mb-2"
        >
          <div
            class="my-auto"
          >
            {{ itTenkoReacquisition }}
          </div>
          <ToggleSwitch
            class="ml-4"
            v-model="isItTenkoReacquisition"
          />
        </div>
        <div class="d-flex justify-content-between chart-display">
          <v-checkbox
            dense
            class="mb-0 all-select"
            v-model="selectAllDisplay"
            label="すべての項目を表示する"
            @change="handleChangeSelectAllDisplay"
          />
          <mat-icon role="img" class="pl-2" @click="toggleChartDisplay">
            <svg
              width="18px"
              height="18px"
              viewBox="0 0 18 18"
              focusable="false"
            >
              <template v-if="!hideChartDisplay">
                <path d="M3.5 4.5L2 6l7 7 7-7-1.5-1.5L9 10 3.5 4.5z"></path>
              </template>
              <template v-if="hideChartDisplay">
                <path d="M3.5 13.5L2 12l7-7 7 7-1.5 1.5L9 8l-5.5 5.5z"></path>
              </template>
            </svg>
          </mat-icon>
        </div>
        <div :class="hideChartDisplay ? 'd-none' : ''" class="chart-display">
          <template v-for="item in chartDisplay" :key="item.key">
            <v-checkbox
              dense
              class="mb-0 small"
              v-model="form[`${item.key}`]"
              :label="item.label"
            />
          </template>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Api from "@/services/api/ApiServiceFabrick";
  import ToggleSwitch from "@/components/ToggleSwitch.vue";
  import { ref } from 'vue'
  import {
    dailyReportChartDisplay,
    dailyReportPageCompositions,
  } from "@/services/consts";

  export default {
    props: {
      deliveryPlans: [],
      modelValue: {},
      isItTenkoKeeper: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        form: {},
        layouts: [],
        dailyReportLayouts: [],
        displayPageCompositions: [],
        displayOutputFormats: [],
        selectedLayout: null,
        selectedPageComposition: "日別",
        selectedOutputFormat: "PDF",
        viewAllChartDisplay: true,
        selectAllDisplay: false,
        hideChartDisplay: true,
        outputFormats: [
          { key: "pdf", label: "PDF", data: "" },
          { key: "csv", label: "CSV", data: "" },
        ],
        chartDisplay: dailyReportChartDisplay,
        itTenkoReacquisition: "点呼データを再取得する",
        isItTenkoReacquisition: false,
      }
    },
    mounted() {
      this.form = this.modelValue ?? {}
      dailyReportChartDisplay.forEach((v) => {
        this.form[v.key] = false
      })
      Api().autoComplete.dailyReportLayouts().then((d) => {
        this.dailyReportLayouts = d
        this.layouts = this.dailyReportLayouts.map((v) => {
          return v.name;
        });
        this.displayOutputFormats = this.outputFormats.map((v) => {
          return v.label;
        });
        this.displayPageCompositions = dailyReportPageCompositions.map((v) => {
          return v.label;
        });
        this.selectedLayout = this.layouts[this.layouts.length - 1];
      });
  },
  methods: {
    handleChangeSelectAllDisplay() {
      dailyReportChartDisplay.forEach((v) => {
        this.form[v.key] = this.selectAllDisplay;
      });
    },
    checkSelectAllDisplay() {
      let selectAllDisplay = true;
      dailyReportChartDisplay.forEach((v) => {
        if (!this.form[v.key]) {
          selectAllDisplay = false;
          return false;
        }
      });
      this.selectAllDisplay = selectAllDisplay;
    },
    toggleChartDisplay() {
      this.hideChartDisplay = !this.hideChartDisplay;
    },
  },
  watch: {
    selectedLayout: {
      handler() {
        const form = {};
        dailyReportChartDisplay.forEach((v) => {
          form[v.key] = false;
        });
        form['outputFormat'] = this.form['outputFormat'];
        let layout = null;
        this.dailyReportLayouts.forEach((v) => {
          if (v.name === this.selectedLayout) {
            layout = v;
            return false;
          }
        });
        if (!layout) return;

          layout.selected.chartDisplay.forEach((v) => {
            form[v] = true
          })
          dailyReportPageCompositions.forEach((v) => {
            if (v.key == layout.selected.page_structure ) {
              this.selectedPageComposition = v.label
              return false
            }
          });
          this.checkSelectAllDisplay()
          this.form = form
        },
      },
      selectedPageComposition: {
        handler() {
          dailyReportPageCompositions.forEach((v) => {
            if (v.label == this.selectedPageComposition) {
              this.form.pageComposition = v
              return false
            }
          });
        },
      },
      selectedOutputFormat: {
        handler() {
          this.outputFormats.forEach((v) => {
            if (v.label == this.selectedOutputFormat) {
              this.form.outputFormat = v.label
              return false
            }
          });
        },
      },
      form: {
        handler() {
          this.$emit('change', this.form)
          this.$emit('update:modelValue', this.form)
          this.checkSelectAllDisplay()
        },
        deep: true
      },
      isItTenkoReacquisition: {
        handler() {
          this.$emit('changeIsItTenkoReacquisition', this.isItTenkoReacquisition)
        }
      }
    },
    components: {
      ToggleSwitch
    }
  }

</script>

<style>
.daily-report-output-layout .mdi-menu-down {
  font-size: 1em;
}
.daily-report-output-layout .v-checkbox * {
  height: 1.5rem;
}
.daily-report-output-layout .v-checkbox label {
  white-space: pre-wrap;
  height: auto;
}
.daily-report-output-layout .all-select .v-checkbox-btn {
  width: 100%;
}
.daily-report-output-layout .v-col {
  padding-top: 0;
  padding-bottom: 0.5em;
}
.daily-report-output-layout .v-selection-control__wrapper {
  transform: scale(1.2);
}

.chart-display label {
  font-size: 14px;
}
</style>
