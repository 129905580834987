<template>
  <div :class="searchAreaClass">
    <h4 class="d-flex justify-content-between mt-0">
      {{title}}
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fit=""
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        sandboxuid="0"
        @click="toggleSidebar"
      >
        <template v-if="hide">
          <path d="M7.615 11.795l4.59-4.59-1.41-1.41-6 6 6 6 1.41-1.41-4.59-4.59zm9.59 6v-12h-2v12h2z" fill-rule="evenodd" sandboxuid="0"></path>
        </template>
        <template v-if="!hide">
          <path d="M6 7.41L10.438 12 6 16.59 7.363 18l5.802-6-5.802-6L6 7.41zM16.066 6H18v12h-1.934V6z" sandboxuid="0" />
        </template>
      </svg>
    </h4>
    <div :class="`strpied-tabled-with-hover ${hide ? 'd-none' : '' }`">
      <div class="d-grid gap-2">
        <ManagementOfficeAutoComplete
          v-model="form.managementOffice"
          :error-messages="errors.managementOffice"
          selectFirstIfOnlyOne
          :clearable="managementOfficeClearable"
        />
        <SingleOperationOfficeAutoComplete
          v-model="form.operationOffice"
          :managementOffice="form.managementOffice"
          :error-messages="errors.operationOffice"
          selectFirstIfOnlyOne
          :clearable="operationOfficeClearable"
        />
        <v-text-field
          label="名称"
          v-model="form.name"
        />
        <v-text-field
          label="住所"
          v-model="form.address"
        />
      </div>
      <div class="d-flex flex-column">
        <div class="col-md-3">
          <svg
            version="1.2"
            style="overflow: visible; width: 299px; height: 1px; opacity: 1; mix-blend-mode: normal; rotate(0deg);"
          >
            <defs>
              <path id="path-1650848226989195" d="M1126 703 C1126 703 1425 703 1425 703"></path>
            </defs>
            <g transform="translate(-1126, -703)">
              <path style="stroke: rgb(194, 186, 186); stroke-width: 1; stroke-linecap: butt; stroke-linejoin: miter; fill: none;" d="M1126 703 C1126 703 1425 703 1425 703"></path>
            </g>
          </svg>
        </div>
        <div class="d-flex search-buttons" >
          <v-btn
            class="ma-2 exec-btn"
            @click="handleClickSearch"
            :style="{
              backgroundColor : loading ? '#B3B8BB' : ''
            }"
          >
            {{searchBtnLabel}}
          </v-btn>
          <v-btn
            class="ma-2 reset-btn"
            @click="clearForm"
          >
            リセット
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ManagementOfficeAutoComplete from "@/components/AutoCompletes/ManagementOfficeAutoComplete"
  import SingleOperationOfficeAutoComplete from "@/components/AutoCompletes/SingleOperationOfficeAutoComplete"

  export default {
    props: {
      loading: {},
      isOperationOffice: {
        type: Boolean,
        default: false,
      },
      isSubManagementOffice: {
        type: Boolean,
        default: false,
      },
      isManagementOffice: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        form: {},
        searchBtnLabel: '実行',
        hide: false,
        errors: {},
      }
    },
    computed: {
      searchAreaClass() {
        return this.hide ? "search-area-hide p-4" : "search-area p-4"
      },
      title() { return this.hide ? "" : "フィルター" },
      managementOfficeClearable() {
        return this.isManagementOffice;
      },
      operationOfficeClearable() {
        return this.isManagementOffice || this.isSubManagementOffice;
      },
    },
    methods: {
      changeSearchButton() {
        this.searchBtnLabel = this.loading ? '実行中...' : '実行'
      },
      handleClickSearch() {
        this.$emit('search', this.form)
      },
      clearForm() {
        this.form = {
          managementOffice: this.managementOfficeClearable ? undefined : this.form.managementOffice,
          operationOffice: this.operationOfficeClearable ? undefined : this.form.operationOffice,
          name: null,
          address: null,
        }
      },
      toggleSidebar() {
        this.hide = !this.hide
      },
    },
    watch: {
      form: {
        handler() {
          this.$emit('formchange', this.form)
        },
        deep: true
      },
      loading: {
        handler() {
          this.changeSearchButton()
        }
      },
    },
    components: {
      ManagementOfficeAutoComplete,
      SingleOperationOfficeAutoComplete,
    },
  }
</script>