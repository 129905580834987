type Position = {
  lat: number,
  lng: number,
  address: string
}

type latlng = {
  lat: number,
  lng: number
}

class Geocoder {
  geocode(address: string, location: latlng): Promise<Position> {
    return new Promise<Position>((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({address: address, location: location})
        .then((response) => {
          if ( response.results[0] ){
            const result = response.results[0]
            let responseAddress = address
            if (location) responseAddress = result.formatted_address.split('、')[1] ?? result.formatted_address ?? ""
            resolve({
              lat: result.geometry?.location?.lat(),
              lng: result.geometry?.location?.lng(),
              address: responseAddress,
            })
          } else {
            reject("No address found")
          }

        })
        .catch((e) => {
          reject("Geocoder failed due to: " + e)
        });
    });
  }
}

export default Geocoder
