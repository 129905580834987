<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  comment: String,
  errors: Array,
  windowInnerWidth: Number,
});
const emits = defineEmits(['inputComment']);
const title = 'コメント';
const commentRef = ref('');

const unwatch = watch(
  () => props.comment,
  (value) => {
    commentRef.value = value;

    unwatch();
  }
);

watch(
  () => commentRef.value,
  (value) => {
    emits('inputComment', { comment: value });
  }
);
</script>

<template>
  <v-row class="select-form pl-4 pr-3 mb-2">
    <v-col :cols="windowInnerWidth > 800 ? 2 : 3">
      <h6 class="pt-2 pl-4" style="font-weight: bold">{{ title }}</h6>
    </v-col>
    <v-col class="pt-0 pr-4">
      <v-textarea
        v-model="commentRef"
        class="mt-0"
        rows="5"
        :error-messages="errors"
      />
    </v-col>
  </v-row>
</template>

<style scoped>
.select-form .v-label {
  display: none;
}
.select-form .v-field__field {
  padding-top: 7px;
}
</style>
