class TimelineHelper {
  public static convertPercentToNumber(percent: number, total: number): number {
    if (percent < 0 || percent > 100 || total < 1) {
      return 0
    }

    return Number((Number(total) * (Number(percent) * 0.01)).toFixed(2))
    // return Math.round((number(total) * (number(percent) * 0.01)));
  }

  public static convertNumberToPercent(
    pixelValue: number,
    total: number
  ): number {
    if (pixelValue < 0 || pixelValue > total || total < 1) {
      return 0
    }

    return Number((Number(pixelValue) / (Number(total) * 0.01)).toFixed(2))
    // return Math.round((number(pixelValue) / (number(total) * 0.01)));
  }
}

export default TimelineHelper
