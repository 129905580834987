import deviceType from '@/types/DeviceType';
import { nextTick } from "vue"

export const isMemoValide = (memo: string | undefined | null): string | null => {
  if (memo && memo.length > 256) {
    return "メモは 256 文字を超えることはできません。"
  }
  return null
}

export const isDeviceTypesValide = (device_types: deviceType[] | undefined): string | null => {
  if (device_types === undefined || device_types === null || device_types.length < 1) {
    return "対応型番を選択してください。"
  }
  return null
}

// 整数か否か
export const isIntegerValide = ( target: string|number|undefined ): string|boolean => {
  if (typeof Number(target) === 'number' && Number.isInteger(Number(target))) {
    return true
  }
  return "整数を入力してください。"
}

// 指定した範囲に含まれるか否か
export const isNumberIncludeBetween = ( target: string|number|undefined, min: number, max: number): string|boolean => {
  if (typeof Number(target) === 'number' && min <= Number(target) && Number(target) <= max) {
    return true
  }
  return min + "から" + max + "の範囲で入力してください。"
}
export const scrollToErrorComponent = () => {
  nextTick(() => {
    const scrollPoint = document.getElementsByClassName('v-input--error')[0]
    scrollPoint.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
  })
}