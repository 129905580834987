import conn from "@/services/ApiConnection"
import Response from "@/types/responses/getWorkload"
import WorkloadType from "@/types/Workload"
import DeliveryPlanWork from "@/types/DeliveryPlanWork"
import Sha256 from "@/services/models/Sha256"
import { formatDateTimeWithSeconds } from "@/services/functions"

class Workload {
  public static async get(operationOfficeId:number, work_id:string, work:string):Promise<Response> {
    const res = await conn.get(`/api/v1/operation_offices/${operationOfficeId}/workloads/${work_id}?work=${work}`)
    return new Promise((resolve) => {
      const data = res?.data || []
      resolve(data)
    })
  }

  public static async upsert(operationOfficeId:number, work_id: string, workItem: DeliveryPlanWork, items: WorkloadType[], deleteItems: number[]):Promise<Response> {
    const res = await conn.put(`/api/v1/operation_offices/${operationOfficeId}/workloads/${work_id}`,{
      delivery_plan_work: workItem,
      workloads: items,
      delete_workload_ids: deleteItems,
    })
    return new Promise((resolve) => {
      const data = res?.data || []
      resolve(data)
    })
  }

  public static generateWorkId(delivery_plan_id:string, work:string, status:boolean, date_time:string){
    const trimd_date_time = formatDateTimeWithSeconds(new Date(date_time))
    const str_status = status? "true":"false"
    const __concated = delivery_plan_id + work + str_status + trimd_date_time;
    return Sha256.generate(__concated)
  }

  public static validate(work:string, items: WorkloadType[]):any[]{
    const error_messages:any = []

    items.map((item, index) => {
      const isValidateName = this.validateName(item['name'])
      const isValidateQuantity = this.validateQuantity(item['quantity'], work)
      const isValidateUnit = this.validateUnit(item['unit'])
      if(isValidateName){
        error_messages['workloads.' + index + '.name'] =  isValidateName
      }
      if(isValidateQuantity){
        error_messages['workloads.' + index + '.quantity'] = isValidateQuantity
      }
      if(isValidateUnit){
        error_messages['workloads.' + index + '.unit'] = isValidateUnit
      }
    })
    return error_messages
  }

  public static validateName(item: string | null):string{
    if(!item) return "入力してください。"
    if(item && item.length > 10) return "10文字以内で入力してください。"
    return ""
  }
  public static validateQuantity(item: number|null, work: string):string{
    if(item !== 0 && !item) return "入力してください。"
    
    if(work == "refueling"){
      if(this.validateQuantityRefueling(item)) return this.validateQuantityRefueling(item)
    }else{
      if(this.validateQuantityLoadingAndUnloading(item)) return this.validateQuantityLoadingAndUnloading(item)
    }
    
    return ""
  }
  public static validateUnit(item: string | null):string{
    if(!item) return "入力してください。"
    if(item && item.length > 5) return "5文字以内で入力してください"
    return ""
  }

  public static validateQuantityRefueling(item: number):string{
    if(!this.isNumber(item)) return "0.00〜999.99の間の数値で入力してください。"
    if(item < 0 || 1000 <= item ) return "0.00〜999.99の間の数値で入力してください。"
    if(String(item).includes('.') && String(item).split(".")[1].length > 2) return "小数点第二位までで入力してください。"

    return ""
  }

  public static validateQuantityLoadingAndUnloading(item: number):string{
    if(!this.isNumber(item)) return "0.00〜9999.99の間の数値で入力してください。"
    if(item < 0 || 10000 <= item) return "0.00〜9999.99の間の数値で入力してください。"
    if(String(item).includes('.') && String(item).split(".")[1].length > 2) return "小数点第二位までで入力してください。"

    return ""
  }

  public static isNumber(item: any):boolean{
    return /^([1-9]\d*|0)(\.\d+)?$/.test(item.toString())
  }
}

export default Workload
