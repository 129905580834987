import { userRoleSearchFormType } from "@/types/requests/userRoleSearchForm"
import getPermissionListResponseType from "@/types/responses/getPermissionList";
import permissionType from "@/types/permission"
import SelectRoleChecker from '@/router/selectRoleChecker'
import { ORGANIZATION_TYPE } from "@/services/consts";
import i18n from '@/lang/i18n';

const PERMISSION_SERVICE_NAME = 'octlink'
const PERMISSION_ADMINISTRATOR = 'administrator'

class UserRole {
  public static formatPermissionsToForm(permissions: permissionType[]){
    let formated = {} as getPermissionListResponseType
    Object.values(permissions).forEach( (permission) => {
      // admisintrator
      if(permission.name === PERMISSION_ADMINISTRATOR){
        formated[PERMISSION_ADMINISTRATOR] = []
        return
      }

      // other
      const splited = permission.name.split('.')
      const resourse = splited[1]
      const action = splited[2]
      if(!formated[resourse]) {
        const merged = Object.assign(formated, {[resourse]: []})
        formated = merged
      }
      if(!formated[resourse].includes(action)) {
        formated[resourse].push(action)
      }
    })
    return formated
  }

  public static formatPermissionsToRequest(permissions: getPermissionListResponseType){
    const formated = [] as permissionType[]
    Object.keys(permissions).forEach((resourse_name) => {
      // admisintrator
      if(resourse_name === PERMISSION_ADMINISTRATOR){
        formated.push(
          {
            name: PERMISSION_ADMINISTRATOR
          }
        )
        return
      }

      // other
      const resourse = resourse_name
      const actions = permissions[resourse_name]
      actions.forEach((action) => {
        const permission_name = PERMISSION_SERVICE_NAME + '.' + resourse_name + '.' + action
        formated.push(
          {
            name: permission_name
          }
        )
      })
    })
    return formated
  }

  public static converetOfficeType(officeType: string) {
    const organization = ORGANIZATION_TYPE.find((org) => {
      return org.type.includes(officeType)
    })
    if(organization) {
      return organization.type
    }
    return officeType
  }

  public static validateSearchForm(form: userRoleSearchFormType){
    const errors = {
      "organization": [] as string[],
      "department": [] as string[],
      "display_name": [] as string[]
    }

    // is empty?
    const current_role = SelectRoleChecker.getCurrentRole()
    if(
      current_role &&
      ( !form.organization || !form.organization.id ) &&
      ( current_role.organization_type == ORGANIZATION_TYPE[3].type ||
      current_role.organization_type == ORGANIZATION_TYPE[4].type )
    ){
      errors.organization.push(i18n.global.t('pages.user_roles.validate.officeFormEmpty'))
    }

    return errors
  }
}

export default UserRole
