<script setup>
import DeviceList from "@/components/DeviceListParts/DeviceList"
import DeviceSearchForm from "@/components/DeviceListParts/DeviceSearchForm"
import Api from "@/services/api/ApiServiceFabrick"
import PagenateItems from "@/components/PagenateItems"
import FileDownloadBtn from "@/components/Files/DownloadButton"
import FileUploadModal from "@/components/Files/UploadModal"
import { reactive, ref, onMounted, watch } from 'vue'
import { useAuth } from "@/services/user/Auth0UserProvider"
import { useRouter } from 'vue-router'
import { getAuthorities } from "@/services/functions"
import UpsertCsvButtons from "@/components/Common/UpsertCsvButtons"
import ManualLink from "@/components/ManualLink.vue"

const props = defineProps({
  showFlash: {
    type: Function
  }
})
document.title = "デバイス一覧";
const PAGE_NAME = "device"
const deleteAuthority = ref(false)
const createAuthority = ref(false)
const viewAuthority = ref(false)
const modalState = ref(false)
const { loginWithRedirect } = useAuth()
const router = useRouter()
const currentPage = ref(1)
const selfRoleId = ref(2)
const totalPage = ref(0)
const widthRatio = ref('min-width: 75%; width: 80%')
const devices = ref([])
const makers = ref([])
const makerNames = ref([])
const listLoading = ref(false)
const searchParams = reactive({
  page: 1,
  withs: 'updatedBy',
  status: 'only-active'
})
const updatingStatus = ref(false)
const response = reactive({errors: {}})
const downloadType = "App\\Services\\Csv\\Exports\\DeviceService"
const uploadType = "App\\Services\\Csv\\Imports\\DeviceService"
const isManagementOffice = ref(false)
const csvtAuthorities = reactive({
  csvImport: false,
  csvExport: false
})

onMounted(() => {
  getAuthorityList()
  listLoading.value = true
  Api().getSelfAccount().then((data) => {
    selfRoleId.value = data.role_id
    isManagementOffice.value = data.login_organization_type == 'App\\Models\\ManagementOffice'
    getDevices()
    getAllMakers()
  }).catch((error) => {
    const res = error.response
    if (res.status == 401) loginWithRedirect({ appState: { targetUrl: location.pathname } });
    listLoading.value = false;
  })
})

const getAuthorityList = async(isError = false) =>{
  const authorities = await getAuthorities(isError)
  createAuthority.value = !!authorities["octlink.device.create"]
  deleteAuthority.value = !!authorities["octlink.device.delete"]
  viewAuthority.value = !!authorities["octlink.device.read"]
  csvtAuthorities.csvImport = !!authorities["octlink.device.input"]
  csvtAuthorities.csvExport = !!authorities["octlink.device.output"]
}

function getDevices () {
  listLoading.value = true
  Api().getPaginatedDevices(searchParams).then((data) => {
    devices.value = data.devices
    currentPage.value = data.current_page
    totalPage.value = data.total_page
    listLoading.value = false
  }).catch((error) => {
    return errorHandle(error.response)
  })
}

function getAllMakers () {
  listLoading.value = true
  Api().getMakers().then((res) => {
    makers.value = res
    makerNames.value = makeMakers(res)
    listLoading.value = false
  }).catch((error) => {
    return errorHandle(error.response)
  })
}

function makeMakers (res) {
  let array = []
  res.forEach((value) => {
    array.push(value.name)
  })
  return array
}

function searchDeviceList (form) {
  searchParams.page = 1
  searchParams.management_officeable_id = form?.managementOffice?.id,
  searchParams.management_officeable_type = form?.managementOffice?.type,
  searchParams.operation_office_id = form?.operationOffice?.id
  searchParams.maker_id = selectedMaker(form),
  searchParams.model_number = form?.model_number,
  searchParams.fw_version = form?.fw_version,
  searchParams.sim_id = form?.sim_id,
  searchParams.imei = form?.imei,
  searchParams.model_type = form?.model_type?.id,
  searchParams.status = form?.status
  searchParams.sort
  searchParams.sort_type
  getDevices()
}

function selectedMaker (form) {
  let id = null
  makers.value.forEach((value) => {
    if (form.maker == value.name) id = value.id
  })
  return id
}

function changeSortOrder (sortItem, sortType) {
  searchParams.sort = sortItem
  searchParams.sort_type = sortType
  if (currentPage.value == 1) {
    getDevices()
  } else {
    currentPage.value = 1;
  }
}

function updateStatus (params, key) {
  updatingStatus.value = true
  Api().updateDeviceStatus(params.id, params).then((_data) => {
    props.showFlash("ステータスの保存に成功しました。", "success")
    updatingStatus.value = false
    getDevices()
  }).catch((error) => {
      getAuthorityList(true)
    return errorHandle(error.response)
  })
}

function updateWidth (hide) {
  widthRatio.value = hide ? 'width: 95%' : 'min-width: 75%; width: 80%'
}

function moveToAccountForm () {
  router.push('/devices/form/')
}

function errorHandle (res) {
  switch (res.status) {
    case 401:
      loginWithRedirect({ appState: { targetUrl: location.pathname } })
      listLoading.value = false
      break
    case 403:
      props.showFlash("権限がありません。", "error", false)
      getAuthorityList(true)
      listLoading.value = false
      break
    case 422:
      response.errors = res.data.errors
  }
  return updatingStatus.value = false
}

function inactivateModalState (isReacquireAccount) {
  modalState.value = false
  if (isReacquireAccount) getDevices()
}

function activateModalState () {
  modalState.value = true
}

watch(currentPage, () => {
  searchParams.page = currentPage.value
  getDevices()
})
</script>
<template>
  <div class="d-flex" style="min-height: 45vw;">
    <div class="content p-4" :style="widthRatio">
      <div class="d-flex">
        <h4 class="card-title mt-0">デバイス一覧</h4>
        <div class="ml-auto ml-2 mb-1 align-self-end">
          <ManualLink :pageName="PAGE_NAME" />
        </div>
      </div>
      <div class="mb-5 d-flex justify-end">
        <div class="btn-area">
          <v-btn v-if="viewAuthority && createAuthority && selfRoleId == 1" class="exec-btn mt-0" @click="moveToAccountForm">
            新規作成
          </v-btn>
          <FileDownloadBtn
            :showable="csvtAuthorities.csvExport"
            btnClass="exec-btn mt-0 ml-1"
            btnName="CSV出力"
            :type="downloadType"
            :filterItems="searchParams"
          />
          <v-btn
            v-show="csvtAuthorities.csvImport"
            class="exec-btn mt-0 ml-1"
            @click="activateModalState"
            >
            CSV入力
          </v-btn>
        </div>
        <UpsertCsvButtons
          v-if="csvtAuthorities.csvImport || csvtAuthorities.csvExport"
          :title="$t('pages.common.button.csvUpsert')"
          :importTitle="$t('pages.devices.csv.importTitle')"
          importType="UpsertImportDevice"
          exportType="UpsertExportDevice"
          :filterItems="searchParams"
          :getList="getDevices"
          :authorities="csvtAuthorities"
          elementId="devices-upsert"
        />
      </div>
      <div>
        <DeviceList
          :devices="devices"
          :selfRoleId="selfRoleId"
          :listLoading="listLoading"
          :changeStatusAuthority="deleteAuthority"
          :updatingStatus="updatingStatus"
          @changeStatus="updateStatus"
          @changeSortOrder="changeSortOrder"
        />
        <PagenateItems
          :range="2"
          :totalPage="totalPage"
          v-model="currentPage"
        />
        <hr />
      </div>
    </div>

    <DeviceSearchForm
      :makers="makerNames"
      :loading="listLoading"
      :isManagementOffice="isManagementOffice"
      @search="searchDeviceList"
      @hideOrShow="updateWidth"
    />

    <FileUploadModal
      :modalState="modalState"
      title='デバイスの入力'
      uploadBtnClass='exec-btn mt-0 ml-1'
      closeBtnClass='mt-0'
      :type="uploadType"
      templateCsvFileType="devices"
      @closeModal="inactivateModalState"
    />
  </div>
</template>
