<script setup>
import { reactive, watch, ref } from 'vue';

const props = defineProps({
  contactName: { type: String },
  errors: { type: String },
  windowInnerWidth: Number,
});

const emits = defineEmits(['inputValue']);
const contactNameValue = ref('');
const data = reactive({ contact_name: contactNameValue });

const unwatch = watch(
  () => props.contactName,
  (propsContactName) => {
    contactNameValue.value = propsContactName;
    unwatch();
  }
);

watch(contactNameValue, () => {
  data.contact_name = contactNameValue;
  emits('inputValue', data);
});
</script>
<template>
  <v-row class="text-form pl-4 pr-3 mb-3">
    <v-col :cols="windowInnerWidth > 800 ? 2 : 3" class="my-auto">
      <h6 class="pl-4">担当者名</h6>
    </v-col>
    <v-col :cols="windowInnerWidth > 800 ? 10 : 9" class="pt-0">
      <v-text-field
        v-model="contactNameValue"
        maxlength="255"
        :error="errors?.length ?? 0 > 0"
        :error-messages="errors"
      />
    </v-col>
  </v-row>
</template>
