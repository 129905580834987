<template>
  <div :class="colClass" v-if="isShow">
    <v-text-field
      class="pt-2"
      v-model="value"
      :disabled="readonly"
      :error-messages="errors"
    >
      <template v-slot:label>
        {{ label }}
        <span v-if="isRequired" style="color: red">
          <strong>*</strong>
        </span>
      </template>
    </v-text-field>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: String,
    },
    parentKey: {
      type: String,
    },
    formKey: {
      type: String,
    },
    formInputs: {},
    errors: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    colClass: {
      type: String,
      default: 'col-6',
    },
  },
  data() {
    return {
      value: '',
      isShow: false,
      isRequired: false,
    };
  },
  mounted() {
    this.value = this.modelValue;
    if (!this.formInputs?.form[this.parentKey]) return;
    const keys = Object.keys(this.formInputs.form[this.parentKey]);

    this.isShow = keys.includes(this.formKey);
    this.isRequired =
      !!this.formInputs.form[this.parentKey][this.formKey]?.required;
  },
  watch: {
    value: {
      handler() {
        this.$emit('update:modelValue', this.value);
      },
    },
    modelValue: {
      handler() {
        this.value = this.modelValue;
      },
    },
  },
};
</script>
