<template>
  <div class="multi-video">
    <div data-test="info-window-content" v-if="videos">
      <div class="mb-2">
        <div v-if="oneOfVideosHasDownloadProblems">
          <div class="v-theme--light message-position">
            <div class="w-100 opacity-75 justify-content-center d-inline-flex bg-danger text-light p-3">
              {{$t('pages.cvr_uploads.errorMessages.failedLoadMovies')}}
            </div>
          </div>
        </div>
        <div
          v-else
          v-show="isAllVideosReadyToAutoPlay"
          class="bg-black mb-2"
        >
          <div class="container flex-item">
            <div class="row">
              <div :class="mainVideoClass" style="width: fit-content;" v-if="mainVideo">
                <MultiVideoPart
                  class="main-video"
                  :style="videoStyle"
                  :globalTimeLinePosition="timeLinePosition"
                  :seekedTimeLinePosition="seekedTimeLinePosition"
                  :key="0"
                  :videoID="mainVideo.job_id"
                  :src="mainVideo.src"
                  :isMainVideo="true"
                  :allVideosArePlaying="allVideosArePlaying"
                  :isAllVideosReadyToAutoPlay="isAllVideosReadyToAutoPlay"
                  :oneOfVideosIsWaiting="oneOfVideosIsWaiting"
                  @isPlayingStateChange="(state) => (allVideosArePlaying = state)"
                  @videoTimeupdate="changeTimelinePositionEvent"
                  @videoSeeked="videoSeekedEvent"
                  @isReadyToPlay="videoReadyToPlayEvent"
                  @videoIsWaiting="addVideoInWaitingList"
                  @videoIsNotWaiting="removeVideoFromWaitingList"
                  @videoCanNotBePlayed="addVideoToErrorList"
                />
              </div>
            </div>
            <div class="row">
              <div class="chartClass">
                <MeasuringChart
                  :id="item.id"
                  :globalTimeLinePosition="timeLinePosition"
                  @isReadyToPlayChart="readyToPlayChartEvent"
                  :style="chartStyle"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="!videos || !isAllVideosReadyToAutoPlay"
      class="video-loading"
    >
      <div class="loading">
        <div class="max-w-md w-full space-y-8">
          <v-loader-animation
            text="text-black"
            loading-text-style="text-black text-lg"
            :loading-text="loadingText"
            :addCharacterImg="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/lang/i18n';
import { defineComponent } from 'vue'
import vLoaderAnimation from '@/components/template/v-loader-animation.vue';
import MultiVideoPart from '@/components/CvrConvert/MultiVideoPart.vue'
import MeasuringChart from "@/components/CvrConvert/MeasuringChart.vue";
import { timeLinePosition, timeLineProducer } from '@/types/timeLinePosition'
import Api from "@/services/api/ApiServiceFabrick";

export default defineComponent({
  name: 'MultiVideo',
  props: {
    item: {},
    addError: Function,
  },
  data() {
    return {
      loadingText: "loading...",
      timeLinePosition: {
        positionInPercentage: 0,
        producer: timeLineProducer.CONTAINER,
        producerID: 0,
      },
      seekedTimeLinePosition: {
        positionInPercentage: 0,
        producer: timeLineProducer.CONTAINER,
        producerID: 0,
      },
      allVideosArePlaying: false,
      arReadyToPlayVideoIDs: [],
      readyToPlayChart: false,
      arWaitingVideoIDs: [],
      arErrorVideoIDs: [],
      videos: null,
      dialogState: false,
      videoStyle: "height: 400px; width: 100%;",
      chartStyle: "height: fit-content; width: 100%; max-height: 250px;"
    }
  },
  computed: {
    mainVideo() {
      let videos = this.videos ?? []
      let video = null;
      this.videos.forEach((v) => {
        if (v.type === 'F') {
          video = JSON.parse(JSON.stringify(v))
        }
      })
      return video
    },
    mainVideoClass() {
      if (this.arSubVideos.length >= 2) return 'col-md-8'
      if (this.arSubVideos.length == 1) return 'col-md-6'
      return 'col-md-12'
    },
    arSubVideos() {
      let videos = [];
      this.videos.forEach((v) => {
        if (v.type !== 'F') {
          const video = JSON.parse(JSON.stringify(v))
          videos.push(video)
        }
      })
      return videos
    },
    subVideoClass() {
      if (!this.mainVideo) return 'd-flex'
      if (this.arSubVideos.length >= 2) return 'col-md-4'
      return 'col-md-6'
    },
    isAllVideosReadyToAutoPlay() {
      return (
        this.arReadyToPlayVideoIDs.length === this.arVideoWithOutError.length
      )
    },
    oneOfVideosIsWaiting() {
      return this.arWaitingVideoIDs.length > 0
    },
    arVideoWithOutError() {
      if (!this.videos?.length) {
        return []
      }
      return this.videos.filter((video) => {
        const index = this.arErrorVideoIDs.indexOf(video.job_id)
        return index === -1
      })
    },
    oneOfVideosHasDownloadProblems() {
      return this.arErrorVideoIDs.length > 0
    },
    isContentPreparingToBeShown() {
      return (
        !this.oneOfVideosHasDownloadProblems && !this.isAllVideosReadyToAutoPlay
      )
    },
  },
  mounted() {
    this.getVideos();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmout() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    item: {
      handler() {
        this.getVideos();
      },
    },
    timeLinePosition: {
      handler() {
        this.handleResize();
      },
      deep: true,
    },
    allVideosArePlaying: {
      handler() {
        this.handleResize();
      },
    }

  },
  methods: {
    handleResize: function() {
      const modalMerginRatio = 0.7
      const buttonAreaHeight = 36
      const merginHeigh = 14
      const modalHeight = (window.innerHeight * modalMerginRatio - buttonAreaHeight - merginHeigh * 2);
      if (!this.readyToPlayChart) {
        const videoHeight = modalHeight;
        this.videoStyle = `height: ${videoHeight}px; width: 100%;`
      } else {
        const videoHeight = modalHeight * 0.6;
        this.videoStyle = `height: ${videoHeight}px; width: 100%;`
        const chartHeight = modalHeight * 0.4;
        const videoWidth = this.getVideoWidth()
        this.chartStyle = `max-height: ${chartHeight}px; width: ${videoWidth}px;`
      }
    },
    getVideoWidth() {
      return document.getElementsByClassName('main-video')[0].offsetWidth
    },
    changeTimelinePositionEvent(timelinePosition) {
      if (
        this.timeLinePosition.positionInPercentage !=
        timelinePosition.positionInPercentage
      ) {
        this.timeLinePosition = timelinePosition
      }
    },
    videoSeekedEvent(timelinePosition) {
      if (
        this.seekedTimeLinePosition.positionInPercentage !=
        timelinePosition.positionInPercentage
      ) {
        this.seekedTimeLinePosition = timelinePosition
      }
    },
    videoReadyToPlayEvent(videoID) {
      const index = this.arReadyToPlayVideoIDs.indexOf(videoID)
      if (index == -1) {
        this.arReadyToPlayVideoIDs.push(videoID)
      }
    },
    readyToPlayChartEvent(bool) {
      this.readyToPlayChart = bool
      this.handleResize()
    },
    addVideoToErrorList(videoID) {
      const index = this.arErrorVideoIDs.indexOf(videoID)
      if (index !== -1) {
        return
      }

      this.arErrorVideoIDs.push(videoID)
    },
    addVideoInWaitingList(videoID) {
      const index = this.arWaitingVideoIDs.indexOf(videoID)
      if (index == -1) {
        this.arWaitingVideoIDs.push(videoID)
      }
    },
    removeVideoFromWaitingList(videoID) {
      const index = this.arWaitingVideoIDs.indexOf(videoID)
      if (index == -1) return
      this.arWaitingVideoIDs.splice(index, 1)
    },
    async getVideos() {
      let children = this.item.children ?? []
      let _videos = []
      if (!children[0]) {
        const video = JSON.parse(JSON.stringify(this.item))
        video.type = 'F'
        _videos.push(video)
      } else {
        _videos = children
      }
      const videos = []
      _videos.forEach((video) => {
        if (video.status.toLowerCase() == 'complete') videos.push(video)
      })

      const urls = await Promise.all(
        (videos ?? []).map((video) => {
          return this.getSrc(video.job_id);
        })
      );
      videos.forEach((video, i) => {
        video.src = urls[i];
      })
      this.videos = videos
    },
    async getSrc(jobId) {
      return Api().getCvrUploadPresignedUrl(jobId)
    },
  },
  components: {
    MultiVideoPart,
    vLoaderAnimation,
    MeasuringChart,
  },
})
</script>

<style scoped>
.video-loading {
  margin: 1% 3%;
  height: 100%;
  background-color: rgba(80,80,80,0.5);
  height: 40vh;
}
.video-loading .loading{
  height: 100%;
  width: 100%;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.multi-video {
  min-height: 40vh;
}

.row, .chartClass {
  display: flex;
  justify-content: center;
}
</style>
