<template>
  <div class="daily-report-list">
    <template v-if="listLoading">
      <div class="not-searched">
        <label>読み込み中</label>
      </div>
    </template>
    <div class="list list-scrollbar">
      <v-table>
        <thead>
          <tr>
            <th class="col-md-1" >ID</th>
            <th class="col-md-4" >名称</th>
            <th class="col-md-1" >備考</th>
            <th class="col-md-2" >最終更新日</th>
            <th class="col-md-3" >最終更新者</th>
            <th class="col-md-1" >編集</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="items?.length != 0">
          <tr
            v-for="item in items"
            :key="'item_id_' + item?.id"
          >
            <td> {{item.id}}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.is_note }}</td>
            <td>{{ item.updated_at?.split(" ")[0] }}</td>
            <td>{{ item.updated_by.last_name + item.updated_by.first_name }}</td>
            <td>
              <UpdateModal v-if="props.authorityList['octlink.daily_report_layout.update']"
                :item="item"
                @updateItem="updateItem"
                :showFlash="showFlash"
                icon="edit_note"
                label="更新"
              />
              <DeleteModal v-if="props.authorityList['octlink.daily_report_layout.delete']"
                :item="item"
                @change="updateItem"
                :showFlash="showFlash"
              />
            </td>
          </tr>
          </template>
          <template v-else-if="!listLoading">
            <tr>
              <td colspan="9">
                表にデータがありません。
              </td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </div>
  </div>
</template>

<script setup>
  import UpdateModal from "@/components/DailyReportLayoutParts/UpdateModal"
  import DeleteModal from "@/components/DailyReportLayoutParts/DeleteModal"
  import { ref, defineProps, defineEmits, toRefs, watch } from 'vue'

  const props = defineProps({
    items: [],
    listLoading: {
      type: Boolean,
      default: false
    },
    showFlash: {
      type: Function
    },
    authorityList: [],
  });

  const {items, listLoading, showFlash} = toRefs(props);
  const form = ref({});

  const emit = defineEmits(['changeItem'])
  const updateItem = (item) => {
    emit('changeItem');
  };
</script>

<style>
.daily-report-list {
  position: relative;
}
.daily-report-list .list{
  max-height: 34em;
  min-height: 20vw;
  overflow: auto;
}
.checkbox-in-grid {
  padding: 1vm;
  height: inherit !important;
  display: inline-grid !important;
}
.not-searched {
  height: 100%;
  position: absolute;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.not-searched>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
</style>
