<template>
  <div class="mb-4 alert-setting-form">
    <v-row class="pl-4 pr-3">
      <v-col :cols="windowInnerWidth > 800 ? 2 : 3">
        <h6 class="pt-2 pl-4" style="font-weight: bold">{{ title }}</h6>
      </v-col>
      <v-col
        :cols="
          windowInnerWidth <= 800 ||
          (windowInnerWidth >= 992 && windowInnerWidth <= 1197)
            ? 6
            : 3
        "
        class="pr-0"
      >
        <v-radio-group v-model="alertSetting.type" class="radio-group-row">
          <v-radio value="0" label="年間設定" color="blue" />
          <v-radio value="1" label="月ごとに設定" color="blue" />
        </v-radio-group>
      </v-col>
    </v-row>
    <div v-if="alertSetting.type == 0">
      <v-row
        class="radio-form pl-4"
        :class="windowInnerWidth > 800 ? 'pr-3' : ''"
      >
        <v-col v-show="windowInnerWidth > 800" :cols="2" />
        <v-col :class="windowInnerWidth > 800 ? 10 : 12" class="d-flex">
          <v-col
            v-for="num in [1, 2, 3]"
            :key="num"
            :class="windowInnerWidth > 800 ? 'd-flex' : ''"
          >
            <div style="width: 100%">
              <v-text-field
                v-model="alertSetting.current[`alert_lv${num}_time`]"
                suffix="時間以上"
                class="mt-0 mr-1"
                density="compact"
                maxlength="3"
                :error-messages="
                  alertSetting?.errors?.year[
                    `settings_monthly_report.color_setting.1.alert_lv${num}_time`
                  ]
                "
              />
            </div>
            <div
              class="color-field d-inline"
              :class="windowInnerWidth > 800 ? 'ml-2' : 'd-flex mt-2'"
            >
              <input
                type="color"
                v-model="alertSetting.current[`alert_lv${num}_color`]"
              />
              <div :class="windowInnerWidth > 800 ? '' : 'ml-2'">
                <label style="font-size: 14px">色選択</label>
              </div>
            </div>
          </v-col>
        </v-col>
      </v-row>
    </div>
    <div v-if="alertSetting.type == 1" class="mt-5">
      <v-row
        v-for="(setting, month) in alertSetting.current"
        :key="month"
        class="pl-4 pr-3 mb-4"
      >
        <v-col v-show="windowInnerWidth > 800" cols="2" class="pt-0 pb-0" />
        <v-col
          :class="windowInnerWidth > 800 ? 10 : 12"
          class="d-flex pt-0 pb-0"
        >
          <v-col cols="1" class="pt-0 pb-0">
            <div style="font-size: 14px">{{ month }}月</div>
          </v-col>
          <v-col
            v-for="num in [1, 2, 3]"
            :key="num"
            :class="windowInnerWidth > 800 ? 'd-flex' : ''"
            class="pt-0 pb-0"
          >
            <div style="width: 100%">
              <v-text-field
                v-model="
                  alertSetting.current[month][`alert_lv${num}_${month}_time`]
                "
                suffix="時間以上"
                class="mt-0 mr-1"
                density="compact"
                maxlength="3"
                :error-messages="
                  alertSetting?.errors?.monthly[
                    `settings_monthly_report.color_setting.${month}.alert_lv${num}_time`
                  ]
                "
              />
            </div>
            <div
              class="color-field d-inline"
              :class="windowInnerWidth > 800 ? 'ml-2' : 'd-flex mt-2'"
            >
              <input
                type="color"
                v-model="
                  alertSetting.current[month][`alert_lv${num}_${month}_color`]
                "
              />
              <div :class="windowInnerWidth > 800 ? '' : 'ml-2'">
                <label style="font-size: 14px">色選択</label>
              </div>
            </div>
          </v-col>
        </v-col>
      </v-row>
    </div>
    <v-row v-for="(boundTime, key) in boundTimes" :key="key" class="pl-4 pr-3">
      <v-col v-show="windowInnerWidth > 800" cols="2" />
      <v-col :cols="windowInnerWidth > 800 ? 2 : 4">
        <div style="font-weight: bold; font-size: 14px" class="pl-3">
          {{ boundTime.title }}
        </div>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="alertSetting[`${boundTime.key}`]"
          :suffix="boundTime.suffix"
          class="mt-0"
          density="compact"
          maxlength="9"
          :error-messages="errors[`settings_monthly_report.${boundTime.key}`]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { reactive, watch } from 'vue';

export default {
  props: {
    settingsMonthlyReport: Object,
    errors: Object,
    windowInnerWidth: Number,
  },
  setup(props, context) {
    const title = '拘束時間上限設定';
    const alertSetting = reactive({
      type: '0',
      year: {},
      monthly: {},
      current: {},
      bound_time_by_hours: '',
      bound_time_by_day: '',
      errors: { year: {}, monthly: {} },
    });

    alertSetting.year = {
      alert_lv1_time: 5,
      alert_lv1_color: '#47a9e6',
      alert_lv2_time: 10,
      alert_lv2_color: '#e1230e',
      alert_lv3_time: 15,
      alert_lv3_color: '#f9d006',
    };
    for (let i = 1; i < 13; i++) {
      const timekey1 = `alert_lv1_${i}_time`;
      const timekey2 = `alert_lv2_${i}_time`;
      const timekey3 = `alert_lv3_${i}_time`;
      const colorkey1 = `alert_lv1_${i}_color`;
      const colorkey2 = `alert_lv2_${i}_color`;
      const colorkey3 = `alert_lv3_${i}_color`;

      alertSetting.monthly[i.toString()] = {
        [timekey1]: 5,
        [colorkey1]: '#47a9e6',
        [timekey2]: 10,
        [colorkey2]: '#e1230e',
        [timekey3]: 15,
        [colorkey3]: '#f9d006',
      };
    }

    const unwatch = watch(
      () => props.settingsMonthlyReport,
      (settings) => {
        alertSetting.type = settings['color_setting_monthly'];

        alertSetting.year = settings['color_setting']['1'];
        for (let i = 1; i < 13; i++) {
          const timekey1 = `alert_lv1_${i}_time`;
          const timekey2 = `alert_lv2_${i}_time`;
          const timekey3 = `alert_lv3_${i}_time`;
          const colorkey1 = `alert_lv1_${i}_color`;
          const colorkey2 = `alert_lv2_${i}_color`;
          const colorkey3 = `alert_lv3_${i}_color`;

          alertSetting.monthly[i.toString()] = {
            [timekey1]: settings['color_setting'][i]['alert_lv1_time'],
            [colorkey1]: settings['color_setting'][i]['alert_lv1_color'],
            [timekey2]: settings['color_setting'][i]['alert_lv2_time'],
            [colorkey2]: settings['color_setting'][i]['alert_lv2_color'],
            [timekey3]: settings['color_setting'][i]['alert_lv3_time'],
            [colorkey3]: settings['color_setting'][i]['alert_lv3_color'],
          };
        }

        if (alertSetting.type == 0) {
          alertSetting.current = alertSetting.year;
        }

        if (alertSetting.type == 1) {
          alertSetting.current = alertSetting.monthly;
        }

        alertSetting.bound_time_by_hours = settings['bound_time_by_hours'];
        alertSetting.bound_time_by_days = settings['bound_time_by_days'];

        unwatch();
      }
    );

    const emitInputOrChange = (value) => {
      context.emit('inputOrChange', 'settings_monthly_report', value);
    };

    watch(
      () => alertSetting.type,
      (typeNum) => {
        if (typeNum == 0) alertSetting.current = alertSetting.year;
        if (typeNum == 1) alertSetting.current = alertSetting.monthly;

        emitInputOrChange({ color_setting_monthly: typeNum });
      }
    );

    watch(
      () => alertSetting.current,
      (setting) => {
        let emitValue = {};
        if (alertSetting.type == 0) {
          alertSetting.year = setting;
          emitValue['1'] = setting;
        }

        if (alertSetting.type == 1) {
          alertSetting.monthly = setting;
          for (let i = 1; i < 13; i++) {
            emitValue[i] = {
              alert_lv1_time: setting[i][`alert_lv1_${i}_time`],
              alert_lv1_color: setting[i][`alert_lv1_${i}_color`],
              alert_lv2_time: setting[i][`alert_lv2_${i}_time`],
              alert_lv2_color: setting[i][`alert_lv2_${i}_color`],
              alert_lv3_time: setting[i][`alert_lv3_${i}_time`],
              alert_lv3_color: setting[i][`alert_lv3_${i}_color`],
            };
          }
        }

        emitInputOrChange({ color_setting: emitValue });
      },
      { deep: true }
    );

    watch(
      () => alertSetting.bound_time_by_hours,
      (hours) => {
        emitInputOrChange({ bound_time_by_hours: hours });
      }
    );

    watch(
      () => alertSetting.bound_time_by_days,
      (day) => {
        emitInputOrChange({ bound_time_by_days: day });
      }
    );

    watch(
      () => props.errors,
      (e) => {
        if (alertSetting.type == 0) alertSetting.errors.year = e;
        if (alertSetting.type == 1) alertSetting.errors.monthly = e;
      }
    );

    const boundTimes = [
      { title: '単日拘束時間目標', key: 'bound_time_by_hours', suffix: '時間' },
      { title: '単日拘束日数想定', key: 'bound_time_by_days', suffix: '日' },
    ];

    return { title, alertSetting, boundTimes };
  },
};
</script>

<style scoped>
.alert-setting-form .v-label {
  font-size: 14px;
}
.validation-error {
  color: red;
  font-size: 80%;
}
</style>
