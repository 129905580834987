<template>
  <div class="mb-4 operation-office-radio-form">
    <v-row class="radio-form pl-4 pr-3">
      <v-col :cols="windowInnerWidth > 800 ? 2 : 3">
        <h6 class="pt-2 pl-4" style="font-weight: bold">{{ title }}</h6>
      </v-col>
      <v-col :cols="cols" class="pr-0 pt-0">
        <v-radio-group v-model="selectedLabel" class="radio-group-row pt-2">
          <template v-for="item in items" :key="item.value">
            <v-radio :value="item.value" :label="item.label" color="blue" />
          </template>
        </v-radio-group>
        <ValidationErrors
          :messages="errors"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ValidationErrors from '@/components/Common/ValidationErrors.vue';
import { ref, watch } from 'vue';

export default {
  props: {
    title: String,
    items: Array,
    selectedItem: [String, Number, Boolean],
    emitKey: String,
    cols: {
      type: Number,
    },
    windowInnerWidth: Number,
    errors: Array,
  },
  setup(props, context) {
    const selectedLabel = ref(props.items[0].label);

    const unwatch = watch(
      () => props.selectedItem,
      (value) => {
        if (typeof value == 'string') {
          selectedLabel.value = value;
        }
        if (typeof value == 'object') {
          selectedLabel.value = 'null';
        }
        if (typeof value == 'boolean') {
          selectedLabel.value = value ? '1' : '0';
        }
        unwatch();
      }
    );

    watch(
      () => selectedLabel.value,
      (value) => {
        context.emit('selected', { [props.emitKey]: value });
      }
    );

    return { selectedLabel };
  },
  components: {
    ValidationErrors
  },
};
</script>

<style>
.radio-form .radio-group-row .v-selection-control-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.operation-office-radio-form .v-label {
  font-size: 14px;
}
</style>
