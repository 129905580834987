<template>
  <card>
    <template v-slot:header>
      <slot name="header" />
    </template>
    <LineChart
      :chartData="chartData"
      :options="options"
      :height="height"
    />
  </card>
</template>

<script>
  import { Chart, registerables } from "chart.js";
  import { LineChart } from 'vue-chart-3';
  import Card from '@/components/Cards/StsCard.vue'
  Chart.register(...registerables);

  export default {
    name: 'line-chart-card',
    components: {
      LineChart,
      Card,
    },
    props: {
      chartData: null,
      options: null,
      height: {
        type: String,
        default: '265'
      }
    }
  }
</script>

<style>
  .card #line-chart {
    height: 265px !important;
  }
</style>
