<script setup>
  import InstallationOfficeList from "@/components/InstallationOfficeParts/InstallationOfficeList"
  import InstallationOfficeSerchForm from "@/components/InstallationOfficeParts/InstallationOfficeSerchForm"
  import Api from "@/services/api/ApiServiceFabrick"
  import PagenateItems from "@/components/PagenateItems"
  import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
  import FileDownloadBtn from "@/components/Files/DownloadButton"
  import FileUploadModal from "@/components/Files/UploadModal"
  import { reactive, ref, onMounted, watch } from 'vue'
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import { useRouter } from 'vue-router'
  import ManualLink from "@/components/ManualLink"
  import i18n from "@/lang/i18n"

  const props = defineProps({
    showFlash: {
      type: Function
    }
  })

  document.title = "設置会社一覧"
  const changeStatusAuthority = ref(false)
  const createAuthority = ref(false)
  const viewAuthority = ref(false)
  const { loginWithRedirect } = useAuth()
  const router = useRouter()
  const form = reactive({})
  const currentPage = ref(1)
  const totalPage = ref(0)
  const widthRatio = ref('min-width: 75%; width: 80%')
  const installationOffices = ref([])
  const listLoading = ref(false)
  const searchParams = reactive({
    page: 1,
    withs: 'updatedBy',
    status: 'only-active'
  })
  const updatingStatus = ref(false)
  const response = reactive({errors: {}})
  const modalState = ref(false)
  const pageName = "installation"
  const csvImportOperationAuthority = ref(false)
  const csvExportOperationAuthority = ref(false)
  const downloadType = "App\\Services\\Csv\\Exports\\InstallationOfficeService"
  const uploadType = "App\\Services\\Csv\\Imports\\InstallationOfficeService"
  const isSubManagementOffice = ref(false)

  onMounted(() => {
    getAuthorityList()
    listLoading.value = true
    Api().getSelfAccount().then((data) => {
      if (data.login_organization_type == 'App\\Models\\SubManagementOffice') isSubManagementOffice.value = true
      getInstallationOffices()
    }).catch((error) => {
      const res = error.response
      if (res.status == 401) loginWithRedirect({ appState: { targetUrl: location.pathname } })
      listLoading.value = false
    })
  })

  const getAuthorityList = async(isError = false) =>{
    if (isError) await AuthorityCacheApi.reflesh()
    const authorityList = await AuthorityCacheApi.getWithParse()
    changeStatusAuthority.value = authorityList["octlink.installation_office.delete"]
    createAuthority.value = authorityList["octlink.installation_office.create"]
    viewAuthority.value = authorityList["octlink.installation_office.read"]
    csvImportOperationAuthority.value = authorityList["octlink.installation_office.input"];
    csvExportOperationAuthority.value = authorityList["octlink.installation_office.output"];
  }

  function getInstallationOffices () {
    listLoading.value = true
    Api().getPaginatedInstallationOffices(searchParams).then((data) => {
      installationOffices.value = data.installationOffices
      currentPage.value = data.current_page
      totalPage.value = data.total_page
      listLoading.value = false
    }).catch((error) => {
      return errorHandle(error.response)
    })
  }

  function searchInstallationOfficesList (form) {
    searchParams.page = 1
    searchParams.management_officeable_id = form?.managementOffice?.id,
    searchParams.management_officeable_type = form?.managementOffice?.type,
    searchParams.company_name = form?.companyName
    searchParams.status = form?.status
    searchParams.sort
    searchParams.sort_type
    getInstallationOffices()
  }

  function handleChangeSearchForm (serchForm) { form.value = serchForm}

  function changeSortOrder (sortItem, sortType) {
    searchParams.sort = sortItem
    searchParams.sort_type = sortType
    if (currentPage.value == 1) {
      getInstallationOffices()
    } else {
      currentPage.value = 1;
    }
  }

  function updateStatus (params, key) {
    updatingStatus.value = true
    Api().updateInstallationOfficeStatus(params.id, params).then((_data) => {
      props.showFlash(i18n.global.t('pages.common.messages.changeStatusSuccess'), "success")
      updatingStatus.value = false
      getInstallationOffices()
    }).catch((error) => {
      errorHandle(error.response)
      if (error.response.status == 403) getAuthorityList(true)
    })
  }

  function updateWidth (hide) {
    widthRatio.value = hide ? 'width: 95%' : 'min-width: 75%; width: 80%'
  }

  function moveToForm () {
    router.push('/installation-offices/form/')
  }

  function inactivateModalState (isReacquireAccount) {
    modalState.value = false
    if (isReacquireAccount) getInstallationOffices()
  }

  function activateModalState () {
    modalState.value = true
  }

  function errorHandle (res) {
    switch (res.status) {
      case 401:
        loginWithRedirect({ appState: { targetUrl: location.pathname } })
        listLoading.value = false
        break
      case 403:
        props.showFlash(i18n.global.t('pages.common.messages.forbidden'), "error", false)
        listLoading.value = false
        break
      case 422:
        response.errors = res.data.errors
    }
    return updatingStatus.value = false
  }

  watch(currentPage, () => {
    searchParams.page = currentPage.value
    getInstallationOffices()
  })
</script>

<template>
  <div class="d-flex" style="min-height: 45vw;">
    <div class="content p-4" :style="widthRatio">
      <div class="d-flex">
        <h4 class="card-title mt-0">設置会社一覧</h4>
        <div class="ml-auto ml-2 mb-1 align-self-end">
          <ManualLink :pageName="pageName" />
        </div>
      </div>
      <div class="d-flex justify-end mb-5">
        <div class="btn-area">
          <v-btn v-if="viewAuthority && createAuthority" class="exec-btn mt-0" @click="moveToForm">
            新規作成
          </v-btn>
          <FileDownloadBtn
            :showable="csvExportOperationAuthority"
            btnClass="exec-btn mt-0 ml-1"
            btnName="CSV出力"
            :type="downloadType"
            :filterItems="searchParams"
          />
          <v-btn v-show="csvImportOperationAuthority" class="exec-btn mt-0 ml-1" @click="activateModalState">
            CSV入力
          </v-btn>
        </div>
      </div>
      <div>
        <InstallationOfficeList
          :installationOffices="installationOffices"
          :listLoading="listLoading"
          :changeStatusAuthority="changeStatusAuthority"
          :updatingStatus="updatingStatus"
          @changeStatus="updateStatus"
          @changeSortOrder="changeSortOrder"
        />
        <PagenateItems
          :range="2"
          :totalPage="totalPage"
          v-model="currentPage"
        />
        <FileUploadModal
          :modalState="modalState"
          title='設置会社の入力'
          uploadBtnClass='exec-btn mt-0 ml-1'
          closeBtnClass='mt-0'
          :type="uploadType"
          templateCsvFileType="installation_offices"
          @closeModal="inactivateModalState"
        />
        <hr />
      </div>
    </div>

    <InstallationOfficeSerchForm
      :loading="listLoading"
      :isSubManagementOffice="isSubManagementOffice"
      @formchange="handleChangeSearchForm"
      @search="searchInstallationOfficesList"
      @hideOrShow="updateWidth"
    />
  </div>
</template>
