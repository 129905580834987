

import {defineComponent, PropType} from "vue";
import firmware from "@/types/Firmware";
import {formatDateTimeWithSeconds} from "@/services/functions";
import {sortField} from "@/types/requests/getFirmwareListRequest";
import {sortType} from "@/services/consts";
import TableSort from "@/components/template/TableSort.vue";

export default defineComponent({
  setup() {
    return {
      formatDateTimeWithSeconds,
      sortField,
    }
  },
  data() {
    return {
      firmwareListSort: {
        sort_type: undefined as string | undefined,
        sort: undefined as string | undefined,
      },
    }
  },
  props: {
    isFirmwareListLoading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    firmwares: {
      type: Array as PropType<firmware[]>,
      required: true,
    },
  },
  emits: ['sort-change', 'update-firmware-click'],
  name: "FirmwareList",
  computed: {},
  watch: {
    firmwareListSort: {
      handler() {
        this.$emit('sort-change', this.firmwareListSort)
      },
      deep: true
    },
  },
  methods: {
    toggleSort( sortFiledValue:string ){

      let updatedSortField = false;
      if ( sortFiledValue !== this.firmwareListSort.sort ){
        updatedSortField = true
      }

      this.firmwareListSort.sort = sortFiledValue;
      if ( this.firmwareListSort.sort_type === undefined || updatedSortField ){
        this.firmwareListSort.sort_type = sortType.ASK
      } else {
        this.firmwareListSort.sort_type = this.firmwareListSort.sort_type === sortType.DESC ? sortType.ASK : sortType.DESC
      }
    },
    changeSort(sortFiledValue: string, needSortType: sortType): void {
      if ( this.firmwareListSort.sort == sortFiledValue && this.firmwareListSort.sort_type == needSortType ){
        return
      }
      this.firmwareListSort.sort = sortFiledValue;
      this.firmwareListSort.sort_type = needSortType
    },
  },
  components: {
    TableSort
  }
})
