import conn from "@/services/ApiConnection"
import DeliveryPlan from "@/types/DeliveryPlan"
import Response from "@/types/responses/getDeliveryPlanWork"
import DeliveryPlanEvent from "@/types/DeliveryPlanEvent"
import DeliveryPlanWorkType from "@/types/DeliveryPlanWork"
import {
  numberToHourAndMinite,
  addSecondsToDateString,
} from '@/services/functions'

class DeliveryPlanWork {
  public static async get(delivery_plan_id:string):Promise<Response[]> {
    const res = await conn.get(`/api/v1/delivery_plans/${delivery_plan_id}/works`)
    return new Promise((resolve) => {
      const data = res?.data || []
      resolve(data)
    })
  }
  public static async upsert(vehicleInformation:DeliveryPlan ,works:DeliveryPlanWorkType[]):Promise<boolean> {
    if (works[0]) {
      await conn.post(`/api/v1/daily_reports/${works[0]?.delivery_plan_id}`,{
        works: works,
        vehicleInformation: vehicleInformation,
      })
    }
    return Promise.resolve(true)
  }

  public static convertWotkToEvent(works:Response[]): DeliveryPlanEvent[] {
    const events = [] as DeliveryPlanEvent[]
    let event:DeliveryPlanEvent|null = null
    works.forEach((work) => {
      const _work = JSON.parse(JSON.stringify(work));
      const before = {
        delivery_plan_id: _work.delivery_plan_id,
        work: _work.work,
        date_time: _work.date_time,
        work_id: _work.work_id,
      };
      work['before'] = before;
      if (event) {
        event.end_time = work.date_time
        event.end_id = work.id
        if (!work.status && work.work != 'returning_warehouse') event.works.push(work)
        events.push(event)
        event = null

      }
      if (work.status || work.work == 'returning_warehouse') {
        event = {
          delivery_plan_id: work.delivery_plan_id,
          work: work.work,
          start_time: work.date_time,
          end_time: work.date_time,
          working_time: null,
          driving_time: null,
          latitude: work.latitude,
          longitude: work.longitude,
          spot_id: work.spot_id,
          spot_name: work.spot_name,
          mileage: work.mileage,
          get_spot_name_from_api: work.get_spot_name_from_api,
          start_id: work.id,
          end_id: null,
          edited_at: work.edited_at,
          added_at: work.added_at,
          editing: false,
          adding: !!work.added_at,
          works: [work],
          allocated: work.allocated,
          work_id: work.work_id,
        }
      }
      if (event && work.work == 'leaving_warehouse') {
        events.push(event)
        event = null
      }
      if (event && work.work == 'returning_warehouse') {
        event.end_time = null
        events.push(event)
        event = null
      }
    });
    this.setWorkingAndDrivingTime(events);
    return events
  }
  public static setWorkingAndDrivingTime(events:DeliveryPlanEvent[]):void {
    let beforeEndTime:number|null = null

    events.forEach((event:DeliveryPlanEvent) => {
      if (!event.start_time) return true

      const startTime = new Date(event.start_time).valueOf()

      if (beforeEndTime) {
        const drivingTime = startTime - beforeEndTime
        event.driving_time =  numberToHourAndMinite(drivingTime / 1000)
      }
      const endTime = new Date(event.end_time ?? '').valueOf()
      if (event.start_time && event.end_time) {
        const workingTime = endTime - startTime
        event.working_time =  numberToHourAndMinite(workingTime / 1000)
      }
      beforeEndTime = endTime
    })
  }
  public static setBeforeAndAfterTimes(deliveryPlan:DeliveryPlan, events:DeliveryPlanEvent[]):void {
    events.forEach((d:DeliveryPlanEvent, i:number) => {
      const before_event = this.avoidStoppingEvent(events, i, false)
      const after_event = this.avoidStoppingEvent(events, i, true)
      if (before_event) {
        d.before_end = addSecondsToDateString(before_event.end_time??'', 1)
      }
      if (after_event) {
        d.after_start = addSecondsToDateString(after_event.start_time??'', -1)
      }
      if (d.work == 'returning_warehouse') {
        const latestStopTime = new Date(deliveryPlan.latest_stop_time ?? 0)
        const returningTime = new Date(deliveryPlan.returning_warehouse_date_time_before_update ?? deliveryPlan.returning_warehouse_date_time ?? 0)
        const afterStart = latestStopTime > returningTime ? latestStopTime : returningTime
        d.after_start = afterStart.toLocaleString()
      }
    })
  }
  public static avoidStoppingEvent(events:DeliveryPlanEvent[], index:number, increment:boolean):DeliveryPlanEvent|void{
    let i
    (increment)? i = 1:i = -1
    const condition = (k:number) => {
      if(increment){
        return k < events.length
      }else{
        return k > -1
      }
    }
    for (let k = index + i; condition(k); k = k + i) {
      if(events[k] && events[k].work != 'stopping'){
        return events[k]
      }
    }
  }
}

export default DeliveryPlanWork
