
// eslint-disable-next-line no-unused-vars
let playPromise: any

import { defineComponent, PropType } from 'vue'
import TimelineHelper from '@/components/CvrConvert/Helpers/TimelineHelper'
import { timeLinePosition, timeLineProducer } from '@/types/timeLinePosition'

export default defineComponent({
  name: 'MultiVideoPart',
  emits: [
    'videoTimeupdate',
    'videoSeeked',
    'isPlayingStateChange',
    'isReadyToPlay',
    'videoIsWaiting',
    'videoIsNotWaiting',
    'videoCanNotBePlayed',
  ],
  props: {
    isMainVideo: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    src: {
      type: String as PropType<string>,
    },
    videoID: {
      type: Number
    },
    globalTimeLinePosition: {
      type: Object as PropType<timeLinePosition>,
    },
    seekedTimeLinePosition: {
      type: Object as PropType<timeLinePosition>,
    },
    allVideosArePlaying: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isAllVideosReadyToAutoPlay: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    oneOfVideosIsWaiting: {
      type: Boolean as PropType<boolean>,
    },
    style: {
      type: String
    }
  },
  data() {
    return {
      showControls: false,
      needToUpdateChartTimeline: true,
      isReadyToPlay: false,
      isUserWorkingViaVideoUI: false,
    }
  },
  mounted() {
    const video = this.$refs.video as HTMLVideoElement

    if (this.isMainVideo) {
      video.addEventListener('timeupdate', this.changeTimelinePositionEvent)
    }

    video.addEventListener('seeked', this.seekedEvent)
    video.addEventListener('play', this.playEvent)
    video.addEventListener('pause', this.pauseEvent)
    video.addEventListener('waiting', this.waitingEvent)
    video.addEventListener('canplay', this.canplayEvent)
    video.addEventListener('loadeddata', this.loadeddataEvent)
    video.addEventListener('error', this.errorEvent)
    video.addEventListener('mouseover', this.mouseEnterEvent)
    video.addEventListener('mouseleave', this.mouseOutEvent)

    window.addEventListener('blur', this.pauseVideoAndCallEvent)
  },
  beforeUnmount() {
    const video = this.$refs.video as HTMLVideoElement

    if (this.isMainVideo) {
      video.removeEventListener('timeupdate', this.changeTimelinePositionEvent)
    }

    video.removeEventListener('seeked', this.seekedEvent)
    video.removeEventListener('play', this.playEvent)
    video.removeEventListener('pause', this.pauseEvent)
    video.removeEventListener('waiting', this.waitingEvent)
    video.removeEventListener('canplay', this.canplayEvent)
    video.removeEventListener('loadeddata', this.loadeddataEvent)
    video.removeEventListener('error', this.errorEvent)
    video.removeEventListener('mouseover', this.mouseEnterEvent)
    video.removeEventListener('mouseleave', this.mouseOutEvent)

    window.removeEventListener('blur', this.pauseVideoAndCallEvent)
  },
  computed: {},
  watch: {
    globalTimeLinePosition: function (timeLinePosition): void {
      this.needToUpdateChartTimeline =
        this.checkIsNeedToUpdateChartTimelineWhenGlobalTimelineUpdated(
          timeLinePosition
        )
      if (
        this.isNeedToUpdateVideoTimelineWhenGlobalTimelineUpdated(
          timeLinePosition
        )
      ) {
        this.updateVideoCurrentTime(timeLinePosition.positionInPercentage)
      }
    },
    seekedTimeLinePosition: function (timeLinePosition): void {
      this.needToUpdateChartTimeline =
        this.isNeedToUpdateVideoTimelineWhenSeekedTimelineUpdated(
          timeLinePosition
        )
      if (
        this.checkIsNeedToUpdateChartTimelineWhenSeekedTimelineUpdated(
          timeLinePosition
        )
      ) {
        this.updateVideoCurrentTime(timeLinePosition.positionInPercentage)
      }
    },
    allVideosArePlaying: function (): void {
      if (this.allVideosArePlaying) {
        this.videoPlay()
      } else {
        this.videoPause()
      }
    },
    isAllVideosReadyToAutoPlay: function (): void {
      if (!this.isAllVideosReadyToAutoPlay) {
        return
      }
      this.videoPlay()
    },
    oneOfVideosIsWaiting: function (): void {
      if (this.oneOfVideosIsWaiting) {
        this.videoPause()
      } else {
        this.videoPlay()
      }
    },
  },
  methods: {
    videoPlay(): void {
      const video = this.$refs.video as HTMLVideoElement
      if (!video) {
        return
      }

      if (!video.paused) {
        return
      }

      playPromise = video.play()
    },
    videoPause(): void {
      const video = this.$refs.video as HTMLVideoElement
      if (!video) {
        return
      }

      if (video.paused) {
        return
      }

      setTimeout(() => {
        video.pause()
      }, 0)
    },
    changeTimelinePositionEvent(): void {
      if (!this.needToUpdateChartTimeline) {
        this.needToUpdateChartTimeline = true
        return
      }


      this.$emit('videoTimeupdate', {
        positionInPercentage: this.getVideoPositionInPercentage(),
        producer: timeLineProducer.VIDEO,
        producerID: this.videoID,
      })
    },
    seekedEvent(): void {
      const video = this.$refs.video as HTMLVideoElement
      const maxStartOverEventTimelineValue = 0.009
      const isEventWasTriggeredBecauseVideoStartedOver =
        video.currentTime <= maxStartOverEventTimelineValue

      if (
        isEventWasTriggeredBecauseVideoStartedOver ||
        !this.isUserWorkingViaVideoUI
      ) {
        return
      }

      this.$emit('videoSeeked', {
        positionInPercentage: this.getVideoPositionInPercentage(),
        producer: timeLineProducer.VIDEO_SEEKED,
        producerID: this.videoID,
      })
    },
    playEvent(): void {
      this.$emit('isPlayingStateChange', true)
    },
    pauseEvent(): void {
      this.$emit('isPlayingStateChange', false)
    },
    pauseVideoAndCallEvent(): void {
      this.videoPause()
      this.pauseEvent()
    },
    waitingEvent(): void {
      const video = this.$refs.video as HTMLVideoElement

      const isEventWasTriggeredBecauseVideoStartedOver = video.currentTime == 0
      const isWaitingForDownloadData =
        !isEventWasTriggeredBecauseVideoStartedOver

      if (!isWaitingForDownloadData) {
        return
      }
      this.$emit('videoIsWaiting', this.videoID)
    },
    errorEvent(): void {
      this.$emit('videoCanNotBePlayed', this.videoID)
    },
    canplayEvent(): void {
      this.$emit('videoIsNotWaiting', this.videoID)
    },
    loadeddataEvent(): void {
      if (!this.isReadyToPlay) {
        this.isReadyToPlay = true
        this.$emit('isReadyToPlay', this.videoID)
      }
    },
    mouseEnterEvent(): void {
      this.isUserWorkingViaVideoUI = true
    },
    mouseOutEvent(): void {
      this.isUserWorkingViaVideoUI = false
    },
    updateVideoCurrentTime(positionInPercentage: number): void {
      if (!this.$refs.video) {
        return
      }

      const video = this.$refs.video as HTMLVideoElement
      video.currentTime = TimelineHelper.convertPercentToNumber(
        positionInPercentage,
        video.duration
      )
    },
    getVideoPositionInPercentage(): number {
      if (!this.$refs.video) {
        return 0
      }

      const video = this.$refs.video as HTMLVideoElement
      return TimelineHelper.convertNumberToPercent(
        video.currentTime,
        video.duration
      )
    },
    isNeedToUpdateVideoTimelineWhenGlobalTimelineUpdated(
      timeLinePosition: timeLinePosition
    ): boolean {
      return timeLinePosition.producer === timeLineProducer.CHART
    },
    checkIsNeedToUpdateChartTimelineWhenGlobalTimelineUpdated(
      timeLinePosition: timeLinePosition
    ): boolean {
      return timeLinePosition.producer === timeLineProducer.VIDEO
    },
    isNeedToUpdateVideoTimelineWhenSeekedTimelineUpdated(
      timeLinePosition: timeLinePosition
    ): boolean {
      timeLinePosition
      return this.isMainVideo
    },
    checkIsNeedToUpdateChartTimelineWhenSeekedTimelineUpdated(
      timeLinePosition: timeLinePosition
    ): boolean {
      return timeLinePosition.producerID != this.videoID
    },
  },
  components: {},
})
