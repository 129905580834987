<template>
  <v-row class="text-form pl-4 pr-3 mb-3">
    <v-col cols="2">
      <h6 class="pl-4 pt-2">自動作業割り当て</h6>
    </v-col>
    <v-col cols="9" class="p-0 d-flex">
      <v-col cols="1" class="pt-0 pb-0">
        <v-switch
          v-model="showableRef"
          color="blue"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="4" class="pt-0 pb-0">
        <v-text-field
          v-show="showableRef"
          label="割り当てる時間"
          v-model="data.allocated_minute"
          suffix="分以上の停車"
          class="mt-0"
          maxlength="5"
          hide-details="auto"
          :error-messages="errors['allocated_minute']"
        />
      </v-col>
      <v-col cols="4" class="pt-0 pb-0">
        <v-select
          v-show="showableRef"
          label="割り当てる作業"
          :items="selectWorkEventTypes"
          item-value="value"
          item-title="name"
          v-model="data.allocated_work"
          hide-details="auto"
          :error-messages="errors['allocated_work']"
        ></v-select>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
  import { ref, reactive, watch } from 'vue'

  export default {
    props: {
      allocated_minute: Number,
      allocated_work: String,
      errors: Object,
      windowInnerWidth: Number
    },
    data() {
      return {
        selectWorkEventTypes: [
          {value: 'loading',         name: '荷積'},
          {value: 'unloading',       name: '荷卸'},
          {value: 'shipping',        name: '荷待'},
          {value: 'incidental_work', name: '付帯作業'},
          {value: 'refueling',       name: '給油'},
          {value: 'wait',            name: '待機'},
          {value: 'break',           name: '休憩'},
        ],
      }
    },
    setup(props, context) {
      const showableRef = ref(false);
      const data = reactive({ allocated_minute: '', allocated_work: '' });

      const unwatch = watch(() => [props.allocated_minute, props.allocated_work], () => {
          data.allocated_minute = props.allocated_minute;
          data.allocated_work = props.allocated_work;
          showableRef.value = props.allocated_minute > 0 ? true : false;

          unwatch();
        }
      );

      watch(() => data.allocated_minute, (val) => {
          context.emit('inputValue', { ['allocated_minute']: val });
        }
      );
      watch(() => data.allocated_work, (val) => {
          context.emit('inputValue', { ['allocated_work']: val });
        }
      );
      watch(() => showableRef.value, (bool) => {
          context.emit('inputValue', { ['is_active_allocated']: bool ? 1 : 0 });
          if (bool) return;

          data.allocated_minute = "";
          data.allocated_work = "";
        }
      );

      return { showableRef, data }
    },
    components: {}
  }
</script>

<style>
.checkbox-with-number-text-form .v-input__details {
  display: none;
}
</style>
