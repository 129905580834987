<template>
  <template v-if="dialogState">
    <GDialog
      ref="spot_modal"
      v-model="dialogState"
      max-width="40%"
      transition="custom-from-bottom-transition"
      persistent
    >
      <div class="row m-0 w-100 upload-panel v-theme--light">
        <div class="row m-0 p-0 w-100 uploading" v-if="uploading">
          <div class="d-flex justify-center m-0 text-white">
            {{ i18n.global.t('pages.cvr_uploads.uploading') }}
          </div>
        </div>
        <div class="row m-0">
          <div class="col-9">
            <VehicleAutoComplete
              :requestParams="{ model_types: AVAILABLE_CVR_UPLOAD_DEVICE_MODEL_TYPES }"
              v-model="form.vehicle"
              :error-messages="errors.vehicle"
            />
          </div>
        </div>
        <Datepicker
          :label="i18n.global.t('pages.cvr_uploads.startDateTime')"
          v-model="form.start_datetime"
          :errors="errors.start_datetime"
        />
        <Datepicker
          :label="i18n.global.t('pages.cvr_uploads.endDateTime')"
          v-model="form.end_datetime"
          :errors="errors.end_datetime"
        />
        <div class="row m-0 v-theme--light justify-content-between">
          <div class="col-9">
              <v-file-input
                counter
                multiple
                show-size
                v-model="form.files"
                :error-messages="errors.files"
              />
          </div>
        </div>
        <div class="row m-0 v-theme--light justify-content-end">
          <div style="width: fit-content" v-if="showableButtonArea">
            <v-btn class="reset-btn" @click="dialogState = false">
              {{ i18n.global.t('common.cancelBtn') }}
            </v-btn>
          </div>
          <div style="width: fit-content" v-if="showableButtonArea">
            <v-btn class="exec-btn" @click="moveFormView">
              {{ i18n.global.t('pages.cvr_uploads.uploadPanel.uploadBtn') }}
            </v-btn>
          </div>
        </div>
      </div>
    </GDialog>
  </template>
  <slot name="button" :dialogState="dialogState">
    <v-btn @click="dialogState = true" class="exec-btn m-1">
      {{ i18n.global.t('pages.cvr_uploads.uploadBtn') }}
    </v-btn>
  </slot>
</template>

<script setup>
import 'gitart-vue-dialog/dist/style.css';
import VehicleAutoComplete from '@/components/AutoCompletes/VehicleAutoComplete';
import Datepicker from '@/components/CvrConvert/UpdatePanelDatePicker';
import { DEVICE_TYPE_C01_5_ID, DEVICE_TYPE_C500_ID, DEVICE_TYPE_C01_5N_ID } from "@/services/consts"
import CvrConvert from '@/services/models/CvrConvert';
import { GDialog } from 'gitart-vue-dialog';
import { ref, reactive, defineProps, watch } from 'vue';
import { formatDateTime } from '@/services/functions';
import i18n from '@/lang/i18n';

let uploading = ref(false);
let showableButtonArea = ref(true);
let dialogState = ref(false);

const props = defineProps(['reload', 'showFlash']);
const reload = ref(props.reload);
const AVAILABLE_CVR_UPLOAD_DEVICE_MODEL_TYPES = [DEVICE_TYPE_C01_5_ID, DEVICE_TYPE_C500_ID, DEVICE_TYPE_C01_5N_ID];

const form = reactive({
  start_datetime: new Date(),
  end_datetime: new Date(),
  vehicle: null,
  files: [],
});

const initializeForm = () => {
  form.start_datetime = new Date();
  form.end_datetime = new Date();
  form.vehicle = null;
  form.files = [];
  form.types = ['F', 'R', 'I'];
};
watch(dialogState, () => {
  if (!dialogState.value) {
    initializeForm();
    initializeErrors();
  }
});

const errors = reactive({
  start_datetime: [],
  end_datetime: [],
  vehicle: [],
  files: [],
});
const initializeErrors = () => {
  errors.start_datetime = [];
  errors.end_datetime = [];
  errors.vehicle = [];
  errors.files = [];
};

const moveFormView = () => {
  if (!validate()) return;
  uploading.value = true;
  console.log(form);
  const files = excludeDtxFiles();

  CvrConvert.upload({
    start_datetime: formatDateTime(form.start_datetime),
    end_datetime: formatDateTime(form.end_datetime),
    device_id: form.vehicle.digital_tacograph,
    files: files,
  })
    .then(() => {
      props.showFlash(i18n.global.t('pages.cvr_uploads.uploadPanel.success'), 'success');
    })
    .catch((message) => {
      props.showFlash(message, 'error', false);
    })
    .finally(() => {
      uploading.value = false;
      dialogState.value = false;
      reload.value();
    });
};

const excludeDtxFiles = () => {
  const result = [];
  (form.files ?? []).forEach((file) => {
    const nameParts = file.name.split('.');
    const ext = nameParts[nameParts.length - 1];
    if (ext != 'dtx') {
      result.push(file)
    }
  })
  return result;
}

const validate = () => {
  let isValid = true;
  initializeErrors();
  if (!validateStartDateTime()) isValid = false;
  if (!validateEndDateTime()) isValid = false;
  if (!validateDateTimeRange()) isValid = false;
  if (!validateVehicle()) isValid = false;
  if (!validateFiles()) isValid = false;

  return isValid;
};

const validateVehicle = () => {
  if (!form.vehicle?.id) {
    errors.vehicle.push(i18n.global.t('pages.cvr_uploads.errorMessages.notSelect'));
  } else if (!form.vehicle?.digital_tacograph) {
    errors.vehicle.push(i18n.global.t('pages.cvr_uploads.errorMessages.hasNoDevice'));
  } else {
    return !errors.vehicle[0];
  }
};

const validateStartDateTime = () => {
  if (!form.start_datetime) {
    errors.start_datetime.push(i18n.global.t('pages.cvr_uploads.errorMessages.notSelectStartDateTime'));
    return false;
  }
  return true;
};

const validateEndDateTime = () => {
  if (!form.end_datetime) {
    errors.end_datetime.push(i18n.global.t('pages.cvr_uploads.errorMessages.notSelectEndDateTime'));
    return false;
  }
  return true;
};
const validateDateTimeRange = () => {
  if (new Date(form.start_datetime) >= new Date(form.end_datetime)) {
    errors.end_datetime.push(i18n.global.t('pages.cvr_uploads.errorMessages.invalidRange'));
  }
  return !errors.end_datetime[0];
};

const allowExts = ['mpg', 'aac', 'wav', 'dtx'];
const validateFiles = () => {
  if (!form.files[0]) {
    errors.files.push(i18n.global.t('pages.cvr_uploads.errorMessages.notSelect'));
  }
  (form.files ?? []).forEach((file) => {
    const nameParts = file.name.split('.');
    const ext = nameParts[nameParts.length - 1];
    if (allowExts.indexOf(ext) == -1) {
      errors.files.push(
        i18n.global.t('pages.cvr_uploads.errorMessages.invalidFileType')
      );
    }
  });
  return !errors.files[0];
};
</script>

<style scoped>
.upload-panel {
  position: relative;
}
.uploading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(150, 150, 150, 0.7);
  z-index: 1000;
}
.uploading > div {
  position: absolute;
  top: 50%;
}
.error .dp__input {
  border: 1px solid rgb(var(--v-theme-error));
}
.error *,
.v-input--error .v-messages__message {
  color: rgb(var(--v-theme-error));
  opacity: 1;
}
</style>
