<template>
  <div class="d-flex justify-content-end">
    <v-btn class="m-1 reset-btn" @click="cancel">
      閉じる
    </v-btn>
    <v-btn
      v-if="updateAuthority"
      class="m-1 exec-btn"
      @click="submit"
      :loading="running"
      :disabled="hasItemError || hasError || isLoading"
    >
      保存
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    items: [],
    hasError: {
      type: Boolean,
      default: false,
    },
    running: {
      type: Boolean,
      default: false,
    },
    isLoading:{
      type: Boolean,
      default: false,
    },
    updateAuthority: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hasItemError: {
        type: Boolean,
        default: false,
      }
    }
  },
  methods: {
    submit() {
      if (!this.hasItemError) {
        this.$emit('submit', this.items)
      }
    },
    cancel() {
      this.$emit('cancel')
    },
  },
  watch: {
    items: {
      handler() {
        this.hasItemError = false
        this.items.forEach((item) => {
          if (item.editing) this.hasItemError = true
        })
      },
      deep: true
    },
  },
  components: {
  },
}
</script>

<style>
.v-btn--disabled.v-btn--variant-contained {
  color:inherit;
  box-shadow: inherit;
  background: rgb(21, 130, 156) !important;
}
</style>
