import {accountType} from "@/services/consts";
import SelfAccount from "@/types/SelfAccount";
import Api from "@/services/api/ApiServiceFabrick";
import AuthorityCacheApi from "@/services/models/AuthorityCacheApi";
import UserHelper from "@/services/helpers/UserHelper";

const administratorAuthorityKey = "administrator"

interface AuthorityList {
  [key: string]: boolean
}

export default class AccessChecker {

  authorityList: AuthorityList
  account: SelfAccount

  constructor(authorityList:AuthorityList, account: SelfAccount) {
    this.authorityList = authorityList
    this.account = account
  }

  hasUserAccessTo( authorityKey: string ): boolean {
    return this.hasUserAdministratorAccess() || this.authorityList[authorityKey]
  }

  hasUserAdministratorAccess(): boolean {
    return this.authorityList[administratorAuthorityKey]
  }

  isItManagementOffice(): boolean{
    if ( !this.account || !this.account.officeable_type ){
      return false
    }

    const type = UserHelper.getOrganizationType(this.account);
    return accountType.managementOffice === type.split('\\')[2];
  }
  isItSubManagementOffice(): boolean{
    if ( !this.account || !this.account.officeable_type ){
      return false
    }

    const type = UserHelper.getOrganizationType(this.account);
    return accountType.subManagementOffice === type.split('\\')[2];
  }
}

export const getAccessCheckerInstance = async (): Promise<AccessChecker> => {

  await Api().isAPIAuthorityInitialized()

  const [account, authorityList] = await Promise.all([
    Api().getSelfAccount(),
    AuthorityCacheApi.getWithParse()
  ]);

  return  new AccessChecker(
    authorityList,
    account,
  )
}
