<template>
  <div class="select-office mt-2 mb-8 col-12">
    <v-row class="select-offcice-items">
      <v-col :cols="windowInnerWidth > 800 ? 5 : 8" class="offices-autocomplete">
      <ManagementOfficeAutoComplete
        v-model="modelValue.managementOffice"
        selectFirstIfOnlyOne
        :clearable="managementOfficeClearable"
        />
      </v-col>
      <v-col :cols="windowInnerWidth > 800 ? 5 : 8" class="offices-autocomplete">
      <SingleOperationOfficeAutoComplete
        v-model="modelValue.operationOffice"
        :managementOffice="modelValue.managementOffice"
        selectFirstIfOnlyOne
        :clearable="operationOfficeClearable"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import ManagementOfficeAutoComplete from "@/components/AutoCompletes/ManagementOfficeAutoComplete.vue"
  import SingleOperationOfficeAutoComplete from "@/components/AutoCompletes/SingleOperationOfficeAutoComplete.vue"
  import Api from "@/services/api/ApiServiceFabrick";

  export default {
    props: {
      windowInnerWidth: Number
    },
    data() {
      return {
        modelValue: {},
        isOperationOffice: false,
        isSubManagementOffice: false,
      }
    },
    mounted() {
      Api().getSelfAccount().then((data) => {
        if (data.login_organization_type == 'App\\Models\\SubManagementOffice') this.isSubManagementOffice = true
        if (data.login_organization_type == "App\\Models\\OperationOffice") this.isOperationOffice = true
      })
    },
    computed: {
      managementOfficeClearable() {
        return !this.isSubManagementOffice && !this.isOperationOffice;
      },
      operationOfficeClearable() {
        return !this.isOperationOffice;
      },
    },
    watch: {
      modelValue: {
        handler() {
          this.$emit('update:modelValue', this.modelValue.operationOffice)
        },
        deep: true,
        immediate: true
      },
    },
    components: {
      ManagementOfficeAutoComplete,
      SingleOperationOfficeAutoComplete,
    }
  }
</script>
<style>
  .select-office {
    display: flex;
  }
  .select-offcice-items {
    display: flex;
  }
  .offices-autocomplete {
    margin-right: 25px;
  }
</style>
