<template>
  <div class="d-flex" style="min-height: 45vw">
    <div class="content p-4 w-100">
      <h4 class="card-title mt-0">{{ title }}</h4>
      <div class="row download-data-histories-list">
        <v-table>
          <thead>
            <tr>
              <th>出力日時</th>
              <th class="text-center">ステータス</th>
              <th>ユーザー</th>
              <th>フォーマット</th>
              <th>ファイル名</th>
              <th>形式</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-if="items?.length != 0">
              <template v-for="item in items" :key="item.id">
                <Row :item="item" :accounts="accounts" />
              </template>
            </template>
            <template v-else-if="searched && !listLoading">
              <tr>
                <td colspan="9">データがありません。</td>
              </tr>
            </template>
          </tbody>
        </v-table>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/services/api/ApiServiceFabrick";
import Buttons from "@/components/SpotParts/ModalButtons";
import { downloadDataFormats } from "@/services/consts";
import Row from "@/components/DownloadDataHistoriesParts/DataRow";

export default {
  data() {
    return {
      formats: [{ title: "ロジ勤怠", value: "logi_attendance" }],
      items: [],
      accounts: [],
      title: "データ出力履歴",
    };
  },
  mounted() {
    document.title = this.title;
    this.getHistories();
    this.getAccounts();
  },
  methods: {
    makeGetRequestParams() {
      let today = new Date();
      const types = [];
      downloadDataFormats.forEach((v) => {
        types.push(v.request_type);
      });
      const params = {
        types: types,
        start: new Date(today.setMonth(today.getMonth() - 3)),
        operation_office_id: this.account?.officeable_id,
      };
      return params;
    },
    getHistories() {
      const params = this.makeGetRequestParams();
      Api()
        .getJobStatuses(params)
        .then((data) => {
          this.items = data.reverse();
        });
    },
    getAccounts() {
      Api()
        .autoCompleteAccounts()
        .then((data) => {
          this.accounts = data;
        });
    },
    closeModal() {
      this.dialogState = false;
    },
  },
  components: { Row },
};
</script>

<style>
.download-data-histories-list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
}
</style>
