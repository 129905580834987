import {
  subscribeToSensor,
  subscribeToVehicleSubscription,
} from '@/graphql/subscriptions'
import {
  Sensor,
  SubscribeToSensorSubscription,
  SubscribeToVehicleSubscriptionSubscription,
} from '@/API'
import { Observable } from '@/../node_modules/zen-observable-ts'
import vehicle from '@/types/vehicle'
import sensor from '@/types/sensor'
import subscribeToVehicleResponse from '@/types/responses/subscribeToVehicleResponse'
import subscribeToSensorResponse from '@/types/responses/subscribeToSensorResponse'
import SubscribeProvider from '@/services/subscribe/providers/SubscribeProvider'
import AppSyncClient from '@/services/subscribe/providers/Connection'
import gql from 'graphql-tag'


type SensorSubscriptionEvent = {
  data: SubscribeToSensorSubscription
}

type VehicleSubscriptionsSubscriptionEvent = {
  data: SubscribeToVehicleSubscriptionSubscription
}

class AppSyncProvider implements SubscribeProvider {
  private appSyncConnection = AppSyncClient.connection()

  public sensorAction = async (sensor: Sensor, vehicle: vehicle, sensorAction: (
      vehicle: vehicle,
      payload: Sensor
    ) => void,
  ) => {
    if (!sensor?.sn) return
    sensorAction(vehicle, sensor)
  }

  public subscribeToVehicleSubscriptions = (
    vehicle: vehicle,
    sensorAction: (
      vehicle: vehicle,
      payload: Sensor
    ) => any,
  ) => {
    const subscription = this.appSyncConnection.subscribe({
      query: gql(subscribeToVehicleSubscription),
      variables: {
        serial_no: vehicle.device_id,
      },
    }) as Observable<object>

    const subscriber = subscription.subscribe({
      next: (provider: VehicleSubscriptionsSubscriptionEvent) => {
        const topic =
          provider?.data?.subscribeToVehicleSubscription?.topic ?? ''
        const payload = JSON.parse(
          provider?.data?.subscribeToVehicleSubscription?.payload ?? '{}'
        )
        switch (true) {
          case /sensor/.test(topic):
            this.sensorAction(payload, vehicle, sensorAction)
            break
        }
      },
    })
    return subscriber
  }
}

export default AppSyncProvider
