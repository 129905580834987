import conn from "@/services/ApiConnection"
import Response from '@/types/responses/getActualVehicleRatioTransition'
import ActualVehicleRatioTransition from '@/types/ActualVehicleRatioTransition'

class ActualVehicleRatioTransitions {
  public static url = '/api/v1/summaries/actual_vehicle_ratio_transitions';

  public static async averageEachMonths(operationOfficeId:number|null = null):Promise<Response> {
    const params: {
      cache: boolean,
      params?: { operation_office_id:number }
    } = {cache: true};
    if (operationOfficeId) {
      params['params'] = { operation_office_id: operationOfficeId };
    }

    const res = await conn.get(this.url, params);
    let sums:number[] = []
    const averages:number[] = []
    let months:number[] = []
    const length = res.data.length

    res.data.forEach((v:ActualVehicleRatioTransition) => {
      if (isNaN(sums[0])) {
        sums = JSON.parse(JSON.stringify(v.actual_vehicle_retio))
        months = JSON.parse(JSON.stringify(v.months))
        return true
      }
      v.actual_vehicle_retio.forEach((v:number, k) => {
        if (sums) sums[k] += v
      })
    })
    months.forEach((v:number, i) => {
      const sum = sums[i] ?? 0
      averages.push(Math.round(sum * 10 / length) / 10)
    })
    return new Promise((resolve,reject) => {
      resolve({
        months: months,
        averages: averages,
        details: res.data ?? [],
      })
    })
  }
}
export default ActualVehicleRatioTransitions
