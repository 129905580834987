<script setup>
import ManagementOfficeAutoComplete from '@/components/AutoCompletes/ManagementOfficeAutoComplete';
import { reactive, ref, computed, watch } from 'vue';

const props = defineProps({
  loading: {},
  isManagementOffice: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['hideOrShow', 'search', 'formchange']);
const searchBtnLabel = ref('実行');
const hide = ref(false);
const form = reactive({
  status: 'only-active',
});
const errors = reactive({});
const workStatusArray = reactive([
  '',
  '商談を持ちます',
  'オペレーティング',
  '契約の解散',
  '保留中',
]);

const title = computed(() => {
  return hide.value ? '' : 'フィルター';
});
const searchAreaClass = computed(() => {
  if (hide.value) return 'search-area-hide p-4';
  return 'search-area p-4';
});

const managementOfficeClearable = computed(() => {
  return props.isManagementOffice;
})

function isHide(hideValue) {
  if (hide.value) return true;
  return hideValue;
}
function clearForm() {
  form.managementOffice = !managementOfficeClearable.value ? form.managementOffice : null;
  form.operationOffice = null;
  form.contactName = null;
  form.workStatus = null;
  form.status = 'only-active';
}
function toggleSidebar() {
  hide.value = !hide.value;
  isHide(hide.value);
  emit('hideOrShow', hide.value);
}
function changeSearchButton() {
  searchBtnLabel.value = props.loading ? '実行中...' : '実行';
}
function handleClickSearch() {
  emit('search', form);
}

watch(form, () => {
  emit('formchange', form);
});
watch(props, () => {
  changeSearchButton();
});
</script>
<template>
  <div :class="searchAreaClass">
    <h4 class="d-flex justify-content-between mt-0">
      {{ title }}
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fit=""
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        sandboxuid="0"
        @click="toggleSidebar"
      >
        <template v-if="isHide()">
          <path
            d="M7.615 11.795l4.59-4.59-1.41-1.41-6 6 6 6 1.41-1.41-4.59-4.59zm9.59 6v-12h-2v12h2z"
            fill-rule="evenodd"
            sandboxuid="0"
          ></path>
        </template>
        <template v-if="!isHide()">
          <path
            d="M6 7.41L10.438 12 6 16.59 7.363 18l5.802-6-5.802-6L6 7.41zM16.066 6H18v12h-1.934V6z"
            sandboxuid="0"
          />
        </template>
      </svg>
    </h4>
    <div :class="`strpied-tabled-with-hover ${isHide() ? 'd-none' : ''}`">
      <div class="d-grid gap-2">
        <ManagementOfficeAutoComplete
          v-model="form.managementOffice"
          :error-messages="errors.managementOffice"
          selectFirstIfOnlyOne
          :clearable="managementOfficeClearable"
        />
        <v-text-field label="運営会社名" v-model="form.operationOffice" />
        <v-text-field label="担当者名" v-model="form.contactName" />
        <v-select
          label="担当者ステータス"
          :items="workStatusArray"
          v-model="form.workStatus"
        />
        <div>
          <div style="font-size: 13px; color: gray; font-weight: bold">
            ステータス
          </div>
          <v-radio-group v-model="form.status" class="radio-group-row">
            <v-radio
              value="only-active"
              label="有効のみ"
              color="blue"
            ></v-radio>
            <v-radio value="all" label="全て" color="blue"></v-radio>
          </v-radio-group>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="col-md-3">
          <svg
            version="1.2"
            style="overflow: visible; width: 299px; height: 1px; opacity: 1; mix-blend-mode: normal; rotate(0deg);"
          >
            <defs>
              <path
                id="path-1650848226989195"
                d="M1126 703 C1126 703 1425 703 1425 703"
              ></path>
            </defs>
            <g transform="translate(-1126, -703)">
              <path
                style="
                  stroke: rgb(194, 186, 186);
                  stroke-width: 1;
                  stroke-linecap: butt;
                  stroke-linejoin: miter;
                  fill: none;
                "
                d="M1126 703 C1126 703 1425 703 1425 703"
              ></path>
            </g>
          </svg>
        </div>
        <div class="d-flex search-buttons">
          <v-btn
            class="ma-2 exec-btn"
            @click="handleClickSearch"
            :disabled="loading"
            :style="{
              backgroundColor: loading ? '#B3B8BB' : '',
            }"
          >
            {{ searchBtnLabel }}
          </v-btn>
          <v-btn class="ma-2 reset-btn" :disabled="loading" @click="clearForm">
            リセット
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
