// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    display: {
      mobileBreakpoint: 'md',
    },
    defaults: {
      global: {
        hideDetails: "auto",
      },
      VTable: {
        density: "compact",
      },
      VSelect: {
        variant: 'outlined',
        clearable: true,
        density: "compact",
      },
      VTextField: {
        variant: 'outlined',
        clearable: true,
        density: "compact",
      },
      VTextarea: {
        variant: 'outlined',
        clearable: true,
        density: "compact",
      },
      VCombobox: {
        variant: 'outlined',
        clearable: true,
        density: "compact",
      },
      VAutocomplete: {
        variant: 'outlined',
        prependInnerIcon: "mdi-magnify",
        clearable: true,
        density: "compact",
      },
      VFileInput: {
        variant: 'outlined',
        clearable: true,
        density: "compact",
      },
      VCheckbox: {
        density: "compact",
      },
    },
  }
)