<template>
  <div class="bd-highlight output-button-area mb-2">
    <ITTenkoStatusMessage
      :messages="downloadButtonNotification"
      :showModalButton="showModalButton"
      @openModal="openModal"
    />
  </div>
  <div class="d-flex justify-content-end bd-highlight mb-3 output-button-area">
    <v-btn
      class="exec-btn"
      :disabled="!downloadable"
      @click="handleClickOutputPdf"
      :style="{
        backgroundColor: downloading ? '#B3B8BB' : '',
      }"
    >
      {{ downloadBtnLabel }}
    </v-btn>
  </div>
</template>

<script>
import Api from "@/services/api/ApiServiceFabrick";
import { useAuth } from "@/services/user/Auth0UserProvider";
import ITTenkoStatusMessage from "@/components/DailyReportParts/ITTenkoStatusMessage.vue";

export default {
  setup() {
    const { loginWithRedirect } = useAuth();
    return { loginWithRedirect };
  },
  props: {
    operationOfficeId: null,
    deliveryPlans: [],
    options: {},
    showFlash: {
      type: Function,
    },
    isItTenkoReacquisition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      downloading: false,
      pageCompositionByDriver: "by_driver",
      byDriverValidationMsg: {
        driverLimit:
          "「まとめて(ドライバー別)」で出力できるドライバーは1名です。",
        deliveryPlanLimit:
          "「まとめて(ドライバー別)」で出力できる運行数は最大2件です。",
      },
      downloadButtonNotification: [],
      failedMessagePrefix: "Trip ID: ",
      failedMessage: "点呼データの取得中に異常が発生しました。",
      inQueueRetryMessage: "乗務後点呼データが存在しません。",
      failed: "failed",
      inQueueRetry: "in_queue_retry",
      showModalButton: false
    };
  },
  computed: {
    downloadable() {
      return this.deliveryPlans[0] && !this.downloading;
    },
    downloadBtnLabel() {
      return this.downloading ? "ダウンロード中..." : "ダウンロード";
    },
    validationMsgForByDriver() {
      if (this.options.pageComposition?.key != this.pageCompositionByDriver) {
        return "";
      }
      const driverLimit = 1;
      const deliveryPlanLimit = 2;

      const byDriverDeliveryPlanCount = {};
      for (let i = 0; i < this.deliveryPlans.length; i++) {
        const deliveryPlan = this.deliveryPlans[i];
        const driverId = deliveryPlan.driver.driver_id;

        byDriverDeliveryPlanCount[driverId] =
          (byDriverDeliveryPlanCount[driverId] || 0) + 1;

        const driverIdsCount = Object.keys(byDriverDeliveryPlanCount).length;
        if (driverIdsCount > driverLimit) {
          return this.byDriverValidationMsg.driverLimit;
        }

        if (byDriverDeliveryPlanCount[driverId] > deliveryPlanLimit) {
          return this.byDriverValidationMsg.deliveryPlanLimit;
        }
      }
      return "";
    },
  },
  methods: {
    handleClickOutputPdf() {
      this.setDownloadButtonNotificationMessage();
      const validationMsg = this.validationMsgForByDriver;
      if (validationMsg) {
        this.showFlash(validationMsg, "error", false);
        return false;
      }

      this.downloading = true;
      const params = this.makeCreateFileRequest();
      Api()
        .createJobStatus(params)
        .then((data) => {
          this.pollingJobStatus(data.id);
        })
        .catch((error) => {
          const res = error.response;
          switch (res.status) {
            case 401:
              this.loginWithRedirect({
                appState: { targetUrl: location.pathname },
              });
              break;
            case 403:
              location.reload();
              break;
            case 422:
          }
          this.downloading = false;
        });
    },
    pollingJobStatus(id) {
      const interval = setInterval(() => {
        Api()
          .getJobStatus(id)
          .then((res) => {
            if (res.status == "completed") {
              this.download(res.file_path);
              clearInterval(interval);
              this.downloading = false;
            }
            if (res.status == "failed") {
              let message = res.output_contents.message
              if (res.output_contents.detail_message) {
                message += "\n\n" + res.output_contents.detail_message
                this.showFlash(message, 'error', false)
              } else {
                alert(message)
              }
              clearInterval(interval);
              this.downloading = false;
            }
            this.setDownloadButtonNotificationMessage(res);
          });
      }, 3000);
    },
    download(path) {
      Api().fileDownload(path);
    },
    makeCreateFileRequest() {
      let type = "App\\Services\\OutputReports\\Daily\\PdfCreateService";
      if (this.options.outputFormat == "CSV") {
        type = "App\\Services\\OutputReports\\Daily\\CsvCreateService";
      }

      let page_structure = 0;
      if (this.options.pageComposition?.key == "operation") {
        page_structure = 2;
      }
      if (this.options.pageComposition?.key == "by_driver") {
        page_structure = 4;
      }
      return {
        type: type,
        operation_office_id: this.operationOfficeId,
        settings: {
          page_structure: page_structure,
          ...this.options,
        },
        trips: this.deliveryPlans.map(
          (deliveryPlan) => deliveryPlan.delivery_plan_id
        ),
        it_tenko_reacquisition: this.isItTenkoReacquisition,
      };
    },
    setDownloadButtonNotificationMessage(job = null) {
      this.showModalButton = false;
      if (job?.output_contents?.statuses) {
        this.downloadButtonNotification = this.checkFailedStatus(job.output_contents.statuses);
        return this.showModalButton = true;
      }
      if (
        job?.status == "working" &&
        job?.parameter?.tenko_job_enqueue_count > 0
      ) {
        return this.downloadButtonNotification = ["点呼データを取得中です。しばらくお待ちください。"];
      }
      this.downloadButtonNotification = [];
    },
    checkFailedStatus(statuses) {
      let messages = []
      Object.values(statuses).forEach((status, index) => {
        if (status == this.failed) {
          messages.push(this.makeMessages(this.failedMessage, statuses, index))
        }
        if (status == this.inQueueRetry) {
          messages.push(this.makeMessages(this.inQueueRetryMessage, statuses, index))
        }
      })
      return messages
    },
    makeMessages(templateMessage, statuses, index) {
      return (`${this.failedMessagePrefix}${Object.keys(statuses)[index]} ${templateMessage}`);
    },
    openModal() {
      this.$emit("openModal", this.downloadButtonNotification)
    },
  },
  watch: {
    deliveryPlans: {
      handler() {
        this.setDownloadButtonNotificationMessage();
      },
    },
  },
  components: {
    ITTenkoStatusMessage,
  },
};
</script>

<style>
.output-button-area .v-btn--disabled.v-btn--variant-contained {
  background-color: rgb(71, 170, 196) !important;
}
</style>
