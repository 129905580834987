import DeliveryPlanEventType from "@/types/DeliveryPlanEvent"
import DeliveryPlanWorkType from "@/types/DeliveryPlanWork"
import {WORK_TYPES} from "@/services/consts"
import i18n from '@/lang/i18n';

type errors = {
  work?: string[],
  spot_name?: string[],
  start_time?: string[],
  end_time?: string[],
}
const REST_TIME_THRESHOLD = 3 * 60 * 60 * 1000;

class DeliveryPlanEvent {

  public static convertEventToWork(events:DeliveryPlanEventType[]): DeliveryPlanWorkType[] {
    const works = [] as DeliveryPlanWorkType[]
    events.forEach((event) => {
      let start_time = '';
      if (event.start_time) {
        const _start_time = new Date(event.start_time);
        start_time = Intl.DateTimeFormat('ja-JP', {
          year: "numeric", month: "2-digit",  day: "numeric",
          hour: "numeric", minute: "numeric",
          second: "numeric",
          fractionalSecondDigits: 3
        }).format(_start_time)
      }

      let end_time = '';
      if (event.end_time) {
        const _end_time = new Date(event.end_time);
        end_time = Intl.DateTimeFormat('ja-JP', {
          year: "numeric", month: "2-digit",  day: "numeric",
          hour: "numeric", minute: "numeric",
          second: "numeric",
          fractionalSecondDigits: 3
        }).format(_end_time)
      }

      if (!event.works) event.works = []
      if (event.works[0]) {
        const work = event.works[0]
        const _work = work
        _work.work = event.work
        _work.date_time = start_time
        _work.status = event.work != "returning_warehouse"
        _work.spot_name = event.spot_name
        _work.allocated = event.allocated
        if (work?.before) {
          _work.before = {
            delivery_plan_id: event.delivery_plan_id,
            work: work.before.work,
            date_time: work.before.date_time,
            work_id: work.before.work_id
          };
        }
        works.push(_work)
      } else {
        const _work = {
          delivery_plan_id: event.delivery_plan_id,
          work: event.work,
          date_time: start_time,
          status: true,
          latitude: null,
          longitude: null,
          spot_id: null,
          spot_name: event.spot_name,
          mileage: null,
          get_spot_name_from_api: false,
          enqueue: true,
          allocated: event.allocated,
          work_id: null,
        } as DeliveryPlanWorkType
        works.push(_work)
      }
      if (event.works[1]) {
        const work = event.works[1]
        const _work = work
        _work.work = event.work
        _work.date_time = end_time
        _work.status = false
        _work.spot_name = event.spot_name
        _work.allocated = event.allocated
        if (work?.before) {
          _work.before = {
            delivery_plan_id: event.delivery_plan_id,
            work: work.before.work,
            date_time: work.before.date_time,
            work_id: work.before.work_id
          };
        }
        works.push(_work)
      } else if (event.work != "leaving_warehouse" && event.work != "returning_warehouse") {
        const _work = {
          delivery_plan_id: event.delivery_plan_id,
          work: event.work,
          date_time: end_time,
          status: false,
          latitude: null,
          longitude: null,
          spot_id: null,
          spot_name: event.spot_name,
          mileage: null,
          get_spot_name_from_api: false,
          enqueue: true,
          allocated: event.allocated,
          work_id: null,
        } as DeliveryPlanWorkType
        works.push(_work)
      }
    })

    return works
  }

  public static validate(item:DeliveryPlanEventType, beforeEditingItem:DeliveryPlanEventType, stoppingPeriods:any[]):errors|null {
    const errors:errors = {}
    let hasError = false
    let messages:string[] = []

    messages = this.validateWork(item)
    if (messages[0]) {
      errors.work = messages
      hasError = true
    }
    messages = this.validateSpotName(item)
    if (messages[0]) {
      errors['spot_name'] = messages
      hasError = true
    }
    messages = this.validateDateTimeSpan(item)
    if (messages[0]) {
      errors['start_time'] = messages
      hasError = true
    }
    messages = this.validateWorkRestMinFourHours(item)
    if (messages[0]) {
      errors.work = messages
      hasError = true
    }
    if(item.adding && stoppingPeriods.length > 0){
      const messageNotEditTimeInCaseOfAddRow = this.validateNotEditTimeInCaseOfAddRow(item, beforeEditingItem, stoppingPeriods)
      if (messageNotEditTimeInCaseOfAddRow.start_time) {
        errors['start_time'] = [messageNotEditTimeInCaseOfAddRow.start_time]
        hasError = true
      }
      if (messageNotEditTimeInCaseOfAddRow.end_time) {
        errors['end_time'] = [messageNotEditTimeInCaseOfAddRow.end_time]
        hasError = true
      }
    }
    return (hasError ? errors : null)
  }

  public static validateWork(item:DeliveryPlanEventType):string[] {
    const messages:string[] = []
    if (item.work) {
      if (!WORK_TYPES.includes(item.work)) {
        messages.push('値が不正です')
      }
    } else {
      messages.push('選択してください')
    }
    return messages
  }

  public static validateWorkRestMinFourHours(item:DeliveryPlanEventType):string[] {
    const messages:string[] = []
    if (item.work == "rest") {
      const start = new Date(item.start_time??0)
      const end = new Date(item.end_time??0)
      const diff = end.getTime() - start.getTime()
      if(diff < REST_TIME_THRESHOLD){
        messages.push(i18n.global.t('pages.daily_reports.updateModal.messages.restTimeLessThanThreshold'));
      }
    }
    return messages
  }

  public static validateSpotName(item:DeliveryPlanEventType):string[] {
    const messages:string[] = []
    if (!item.spot_name) {
      messages.push('入力してください')
    }
    return messages
  }

  public static validateDateTimeSpan(item:DeliveryPlanEventType):string[] {
    const messages:string[] = []
    if (item.work == 'leaving_warehouse') return messages
    if (!item.start_time || (!item.end_time && item.work !== 'returning_warehouse')) {
      messages.push('入力してください')
    }

    const beforeEnd = new Date(item.before_end??0)
    const afterStart = new Date(item.after_start??0)
    const start = new Date(item.start_time??0)
    const end = new Date(item.end_time??0)

    if (item.work == 'returning_warehouse') {
      if((beforeEnd > start) || (afterStart < start)) {
        messages.push('範囲を正しく入力してください')
      }
    } else {
      if (beforeEnd > start || afterStart < end || start >= end) {
        messages.push('範囲を正しく入力してください')
      }
    }
    return messages
  }

  public static validateNotEditTimeInCaseOfAddRow(item:DeliveryPlanEventType, beforeItem:DeliveryPlanEventType, stoppingPeriods:any[]) {
    const messages = {
      start_time: "",
      end_time: ""
    }
    const start = new Date(item.start_time??0)
    const end = new Date(item.end_time??0)
    const beforeStart = new Date(beforeItem.start_time??0)
    const beforeEnd = new Date(beforeItem.end_time??0)
    const currentStoppingPeriod = stoppingPeriods.find((stoppingPeriod) => {
      const stoppingPeriodStart = new Date(stoppingPeriod['start']);
      const stoppingPeriodEnd = new Date(stoppingPeriod['end']);
      return (stoppingPeriodStart <= beforeStart && beforeStart <= stoppingPeriodEnd) &&
             (stoppingPeriodStart <= beforeEnd && beforeEnd <= stoppingPeriodEnd)

    })
    const currentStoppingPeriodStart = new Date(currentStoppingPeriod['start']);
    const currentStoppingPeriodEnd = new Date(currentStoppingPeriod['end']);
    if( start < currentStoppingPeriodStart || currentStoppingPeriodEnd < start){
      messages.start_time = currentStoppingPeriod['start'] + 'より過去の値は設定できません。'
    }
    if( end < currentStoppingPeriodStart || currentStoppingPeriodEnd < end){
      messages.end_time = currentStoppingPeriod['end'] + 'より未来の値は設定できません。'
    }
    return messages
  }
}

export default DeliveryPlanEvent
