<script setup>
  import { ref, computed, watch } from "vue"
  import Api from "@/services/api/ApiServiceFabrick"
  import { useAuth } from "@/services/user/Auth0UserProvider"
  import { GDialog } from 'gitart-vue-dialog'

  const { loginWithRedirect } = useAuth()

  const props = defineProps({
    modalState: Boolean,
    title: String,
    downloadBtnClass: String,
    closeBtnClass: String,
    type: String,
    operationOfficeId: Number,
    notes: String,
    templateCsvFileType: String,
    btnClass: String,
    btnName: String,
    disabled: Boolean,
    showable: Boolean,
    modalMaxWidth: {
      type: String,
      default: '30%'
    },
    downloadBtnName: {
      type: String,
      default: 'ダウンロード'
    },
    closeBtnName: {
      type: String,
      default: '閉じる'
    },
    sampleFileDownloadText: {
      type: String,
      default: 'CSVサンプルをダウンロード'
    },
    downloadExtension: {
      type: String,
      default: 'text/csv'
    },
    isDisabledDownloadBtn: {
      type: Boolean,
      default: true,
    },
    subTitle: {
      type: String,
      default: ''
    },
    settings: {},
    filterItems: {},
    trips: [],
    deliveryPlans: [],
  })

  const emit = defineEmits(['closeModal'])

  const state = ref(false)
  const uploadFileResults = ref([])
  const operationOffice = ref({})
  const officeAutocompleteStatus = ref(false)
  const validationMessages = ref([])
  const downloading = ref(false)

  watch(() => props.modalState, (value) => {
      state.value = value
      if (state.value) return
    }
  )

  function downloadFile() {
    if (!props.operationOfficeId) {
      return validationMessages.value = ['運営会社を選択してください。']
    }
    validationMessages.value = []
    downloading.value = true

    let params = {
      operation_office_id: props.operationOfficeId ?? null,
      type: props.type,
      settings: props.settings ?? null,
      filter_items: props.filterItems ?? null,
      trips: props.trips ?? [],
    }

    Api().createJobStatus(params).then((data) => {
      pollingJobStatus(data.id)
    }).catch((error) => {
      const res = error.response
      switch (res.status) {
        case 401:
          loginWithRedirect({ appState: { targetUrl: location.pathname } })
          break
        case 403:
          location.reload()
          break
        case 422:
          validationMessages.value = res.data.message['file']
      }
      downloading.value = false
    })
  }

  function pollingJobStatus(id) {
    const interval = setInterval(() => {
      Api().getJobStatus(id).then((res) => {
        if (res.status == "completed") {
          download(res.file_path)
          clearInterval(interval)
          downloading.value = false
        }
        if (res.status == "failed") {
          alert(res?.output_contents?.message)
          clearInterval(interval)
          downloading.value = false
        }
      })
    }, 3000)
  }

  function download(path) {
    if (!path) return

    Api().fileDownload(path)
  }

  function close() {
    let isReacquire = false
    const successMsg = uploadFileResults.value?.['success']
    if (successMsg && Number(successMsg.split(' ')[1]) >= 1) {
      isReacquire = true
    }
    emit("closeModal", isReacquire, 'download')
  }
</script>

<template>
  <GDialog
    v-if="state"
    v-model="state"
    :max-width="modalMaxWidth"
    transition="custom-from-bottom-transition"
    :persistent='true'
  >
      <div class="modal-header justify-content-center mt-2">
        <label>{{ title }}</label>
      </div>

      <div class="modal-body mx-4">
        <label>{{ subTitle }}</label>
        <slot name="supplementary-item" />

        <div>{{ notes }}</div>

        <div class="mt-2" style="color: #3c8dbc;" v-show="!!templateCsvFileType">
          <span class="sample-file-download" @click="download">{{ sampleFileDownloadText }}</span>
        </div>
      </div>

      <div class="d-flex justify-content-end m-3 mt-4">
        <v-btn :class="closeBtnClass" class="close-btn" @click="close" :disabled="downloading">
          {{ closeBtnName }}
        </v-btn>

        <v-btn
          :class="downloadBtnClass"
          class="file-upload-btn"
          @click="downloadFile"
          :loading="downloading"
          :disabled="props.isDisabledDownloadBtn"
          :style="{
            backgroundColor: props.isDisabledDownloadBtn ? '#B3B8BB' : '',
          }"
        >
          {{ downloadBtnName }}
        </v-btn>
      </div>
  </GDialog>
</template>