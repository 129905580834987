<script setup>
import { reactive, watch } from 'vue';
import ValidationErrors from '@/components/Common/ValidationErrors';

const emits = defineEmits(['inputTime']);
const props = defineProps({
  settingsMonthlyReport: Object,
  errors: Array,
  windowInnerWidth: Number,
});
const title = '時間外労働時間上限設定';
const overTimeWorkingHoursSetting = reactive({ monthly: {} });
const initailSetting = {};
for (let i = 1; i <= 12; i++)
  initailSetting[i.toString()] = { hours: null, minutes: null };
overTimeWorkingHoursSetting.monthly = initailSetting;

const unwatch = watch(
  () => props.settingsMonthlyReport,
  (settings) => {
    const times = {};
    for (let i = 1; i <= 12; i++) {
      const strI = i.toString();
      times[strI] = {};
      const splitHoursAndMinutes = settings['over_time_working_hour_thresholds']
        ? settings['over_time_working_hour_thresholds'][strI]?.split(':')
        : overTimeWorkingHoursSetting.monthly;
      times[strI]['hours'] = Array.isArray(splitHoursAndMinutes)
        ? Number(splitHoursAndMinutes[0])
        : null;
      times[strI]['minutes'] = Array.isArray(splitHoursAndMinutes)
        ? Number(splitHoursAndMinutes[1])
        : null;
    }
    overTimeWorkingHoursSetting.monthly = times;

    unwatch();
  }
);

watch(
  () => overTimeWorkingHoursSetting.monthly,
  () => {
    const emitValue = {};
    for (let i = 1; i <= 12; i++) {
      const strI = i.toString();
      emitValue[strI] = {
        hours: overTimeWorkingHoursSetting.monthly[strI].hours,
        minutes: overTimeWorkingHoursSetting.monthly[strI].minutes,
      };
    }
    emits('inputTime', 'settings_monthly_report', {
      over_time_working_hour_thresholds: emitValue,
    });
  },
  { deep: true }
);
</script>

<template>
  <v-row class="radio-form pl-4 pr-3 mb-2">
    <v-col v-show="windowInnerWidth < 800" cols="4">
      <h6 class="pt-2 pl-4" style="font-weight: bold">{{ title }}</h6>
    </v-col>
  </v-row>
  <v-row
    v-for="(time, month) in overTimeWorkingHoursSetting.monthly"
    :key="month"
    class="pl-4 pr-3 mb-2"
  >
    <v-col v-show="windowInnerWidth > 800" cols="2">
      <h6 v-if="month == 1" class="pt-2 pl-4" style="font-weight: bold">
        {{ title }}
      </h6>
    </v-col>
    <v-col cols="1">
      <div class="pl-3" style="font-size: 14px">{{ month }}月</div>
    </v-col>
    <v-col
      :cols="windowInnerWidth > 800 ? 3 : 5"
      :style="windowInnerWidth > 800 ? '' : 'margin-right: 23px;'"
    >
      <v-text-field
        v-model="overTimeWorkingHoursSetting.monthly[month].hours"
        suffix="時間"
        class="mt-0"
        density="compact"
        :min="0"
        maxlength="4"
        :error="errors[`settings_monthly_report.over_time_working_hour_thresholds.${month}.hours`]?.length ? true : false"
      />
      <ValidationErrors
        :messages="
          errors[
            `settings_monthly_report.over_time_working_hour_thresholds.${month}.hours`
          ]
        "
      />
    </v-col>
    <v-col :cols="windowInnerWidth > 800 ? 3 : 5">
      <v-text-field
        v-model="overTimeWorkingHoursSetting.monthly[month].minutes"
        suffix="分"
        class="mt-0"
        density="compact"
        :min="0"
        maxlength="2"
        :error="errors[`settings_monthly_report.over_time_working_hour_thresholds.${month}.minutes`]?.length ? true : false"
      />
      <ValidationErrors
        :messages="
          errors[
            `settings_monthly_report.over_time_working_hour_thresholds.${month}.minutes`
          ]
        "
      />
    </v-col>
  </v-row>
</template>

<style scoped>
.radio-form .radio-group-row .v-selection-control-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.working-hours-setting-form .v-label {
  font-size: 14px;
}
</style>
