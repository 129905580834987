import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { AxiosAdapter } from "axios";
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from "axios-extensions";
import { isCurrentRoleIncludedSelfRoles, removeCurrentRole } from "@/services/functions"
import { useAuth } from "@/services/user/Auth0UserProvider";
import SelectRoleChecker from '@/router/selectRoleChecker';

const conn = axios.create({
  baseURL: process.env.VUE_APP_REST_API_BASE_URL,
  headers: { "Cache-Control": "no-cache" },
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
      enabledByDefault: false,
    })
  ),
});
conn.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const userInfo = useAuth();
  if (userInfo.isAuthenticated.value) {
    const token = await userInfo.getTokenSilently();
    conn.defaults.headers.common["Authorization"] = "Bearer " + token;
  }
  const role = SelectRoleChecker.getCurrentRole();
  if (role) {
    conn.defaults.params = {}
    conn.defaults.params['organization_type'] = role.organization_type
    conn.defaults.params['organization_id'] = role.organization_id
  }
  return config;
});
conn.interceptors.response.use(async (config: AxiosResponse) => {
  return config;
}, async(error: AxiosError) => {
  if (error.response) {
    if (error.response.status === 403) {
      if (!await isCurrentRoleIncludedSelfRoles()) removeCurrentRole()
    }
  }
  return Promise.reject(error);
})

export default conn;
