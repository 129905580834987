import conn from "@/services/ApiConnection";
import getCvrUploadResponse from "@/types/responses/getCvrUpload";
import axios from "axios";
import createCvrUploadType from "@/types/requests/createCvrUpload"

type Form = {
  start_datetime: string;
  end_datetime: string;
  device_id: number;
  files: any[];
};

const MAX_UPLOAD_RETRY_COUNT = 10;

export default class CvrConvert {
  public static async upload(form: Form): Promise<getCvrUploadResponse> {
    let error: string;
    let ret: getCvrUploadResponse;
    try {
      const ret = await this.create(form);
      for (let i = 0; i < form?.files?.length; i++) {
        const url = await this.retryable(this.createPresignedUrl, [
          ret.job_id,
          form.files[i].name,
        ]);
        await this.retryable(this.uploadFile, [url, form.files[i]]);
      }
      await this.uploadComplete(ret.job_id);
    } catch (e: any) {
      error = e.message;
    }
    return new Promise((resolve, reject) => {
      if (error) reject(error);
      resolve(ret);
    });
  }

  private static async retryable(
    func: any,
    params: any,
    retryCount = 0
  ): Promise<any> {
    let error: Error | null = null;
    let ret: any;

    return new Promise((resolve, reject) => {
      let waiting = false;
      const timer = setInterval(function () {
        if (waiting) return;

        waiting = true;
        func(...params)
          .then((res: any) => {
            clearInterval(timer);
            resolve(res);
          })
          .catch((e: Error) => {
            waiting = false;
            console.log(e);
            error = e;
            retryCount++;
          });
        if (retryCount + 1 > MAX_UPLOAD_RETRY_COUNT) {
          clearInterval(timer);
          reject(error);
        }
      }, 1000);
    });
  }

  public static async request(form: createCvrUploadType): Promise<getCvrUploadResponse> {
    let error: Error | null = null;
    const res = await conn
      .post("/api/v1/cvr_upload_request", {
        start_datetime: form.start_datetime,
        end_datetime: form.end_datetime,
        device_id: form.device_id,
        types: form.types
      })
      .catch((e) => {
        error = e;
      });

    return new Promise((resolve, reject) => {
      if (!error) {
        resolve(res?.data);
      } else {
        reject(error)
      }
    });
  }

  public static async create(form: Form): Promise<getCvrUploadResponse> {
    const res = await conn
      .post("/api/v1/cvr_uploads", {
        start_datetime: form.start_datetime,
        end_datetime: form.end_datetime,
        device_id: form.device_id,
        types: ["F", "R", "I"],
      })
      .catch((e) => {
        throw new Error("作成に失敗しました。");
      });

    return new Promise((resolve) => {
      resolve(res.data);
    });
  }

  public static async getPresignedUrl(job_id: string): Promise<string> {
    const res = await conn
      .get(`/api/v1/cvr_uploads/${job_id}/presigned_url`)
      .catch((e) => {
        throw new Error("ダウンロード準備に失敗しました。");
      });

    return new Promise((resolve) => {
      resolve(res?.data?.presigned_url);
    });
  }

  public static async createPresignedUrl(
    job_id: string,
    filename: string
  ): Promise<string> {
    const res = await conn
      .post(`/api/v1/cvr_uploads/${job_id}/presigned_url`, {
        filename: filename,
      })
      .catch((e) => {
        throw new Error("アップロード準備に失敗しました。");
      });

    return new Promise((resolve) => {
      resolve(res?.data?.presigned_url);
    });
  }

  public static async uploadFile(url: string, file: File): Promise<boolean> {
    await axios.put(url, file).catch((e) => {
      throw new Error("アップロードに失敗しました。");
    });

    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }

  public static async uploadComplete(
    job_id: string
  ): Promise<getCvrUploadResponse> {
    const res = await conn
      .put(`/api/v1/cvr_uploads/${job_id}`, { status: "submitted" })
      .catch((e) => {
        throw new Error("ステータス更新に失敗しました。");
      });

    return new Promise((resolve) => {
      resolve(res?.data);
    });
  }

  public static async download(job: getCvrUploadResponse): Promise<boolean> {
    let error: string;
    const presignedUrl = await this.getPresignedUrl(job.job_id);

    const res = await axios
      .get(presignedUrl, { responseType: "blob" })
      .catch((e) => {
        error = e.message;
      });

    return new Promise((resolve, reject) => {
      if (error) reject(error);
      if (!res?.data) return reject("ダウンロードに失敗しました。");

      const blob = new Blob([res.data]);
      const url = URL.createObjectURL(blob);

      const paths = job.file_path.split("/");
      const fileName = paths[paths.length - 1];

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
      resolve(true);
    });
  }
}
