<script setup>
  import VueApexCharts from "vue3-apexcharts"
  import { ref, computed, watch } from 'vue'
  import { format, parse } from 'date-fns'

  const props = defineProps({
    sensorDatas: Object,
    dateTimes: Array[String],
    yaxisMin: Number,
    yaxisMax: Number,
    isLoading: Boolean,
    width: Number,
    viewConditions: Object,
    displayUnitStatus: Object,
    tickAmount: Number,
  })
  const emits = defineEmits(['zoomed', 'chartLabels'])
  const lineWidth = 1
  const isZoomed = ref(false)
  const loading = ref(false)
  const chartRef = ref(null)
  const legends = ref([true, true, true, true])
  const chart = ref(null)
  const xaxisLabels = ref([])
  const xaxisMin = ref()
  const xaxisMax = ref()
  const xAxisSplitCount = 4

  watch(() => props.isLoading, (bool) => {
    loading.value = bool
  })

  function zoomed (chartContext, { xaxis }){
    if (!xaxis?.min) return
    emits('zoomed', true, xaxis)
  }

  function makeMinAxis () {
    return new Date(props.viewConditions.startTime).getTime()
  }

  function makeMaxAxis () {
    return new Date(props.viewConditions.endTime).getTime()
  }

  function onLegendClick (chartContext, seriesIndex, config) {
    legends.value[seriesIndex] = !legends.value[seriesIndex]
    emitLabels()
  }

  function hideSeries () {
    legends.value.forEach((v, i) => {
      if(!v) {
        let ch = `ch${i + 1}`
        chart.value.hideSeries(ch)
      }
    })
  }

  function makeXaxis (xaxis = null) {
    const startTime = xaxis?.min ? xaxis.min : props.viewConditions.startTime
    const endTime = xaxis?.max ? xaxis.max : props.viewConditions.endTime

    const startUnixTime = new Date(startTime).getTime()
    const endUnixTime = new Date(endTime).getTime()

    const span = (endUnixTime - startUnixTime) / xAxisSplitCount
    xaxisLabels.value = []
    const unixTimeArray = []
    for (let i = 0; i < xAxisSplitCount + 1; i++) {
      unixTimeArray.push(new Date(startUnixTime + span * i))
    }
    unixTimeArray.forEach((v, i) =>{
      xaxisLabels.value.push(format(new Date(v), changeFormat(unixTimeArray[i - 1], v, i)))
    })

    emitLabels()
  }

  function changeFormat (beForedateTime, dateTime, index) {
    if (index == 0 || index == 4) return 'MM-dd HH:mm'
    if (new Date(beForedateTime).getFullYear() != new Date(dateTime).getFullYear()) return 'yyyy-MM-dd HH:mm'
    if (new Date(beForedateTime).getMonth() != new Date(dateTime).getMonth()
      || new Date(beForedateTime).getDate() != new Date(dateTime).getDate()
    ) return 'MM-dd HH:mm'
    return 'HH:mm'
  }

  function makeSeriesName (name) {
    const index = name.slice(-1)
    const unitStatus = props.displayUnitStatus[`unit${index}_status`]

    return name + ' ' + `<span class="material-symbols-outlined battery-icon">${batteryLevel(unitStatus)}</span>`
  }

  function batteryLevel (unitStatus) {
    switch (unitStatus) {
      case "0000":
        return 'battery_full'
      case "0001":
        return 'battery_6_bar'
      case "0010":
      case "0011":
        return 'battery_5_bar'
      case "0100":
      case "0101":
        return 'battery_4_bar'
      case "0110":
      case "0111":
        return 'battery_3_bar'
      case "1000":
        return 'battery_2_bar'
      case "1001":
        return 'battery_1_bar'
      default:
        return 'battery_unknown'
    }
  }

  function emitLabels () {
    emits('chartLabels', [xaxisLabels.value, legends.value])
  }

  const data = computed(() => {
    return {
      chartOptions: {
        colors: ['#0089b3', '#ff553f', '#00ffd8', '#ff802f'],
        chart: {
          events: {
            updated: function() {
              hideSeries()
              makeXaxis()
            },
            beforeResetZoom: function() {
              emits('zoomed', false)
            },
            scrolled: function(chartContext, { xaxis }) {
              emits('zoomed', true, xaxis)
              makeXaxis(xaxis)
            }
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              zoom: !loading.value,
              zoomin: !loading.value,
              zoomout: !loading.value,
              pan: !loading.value,
              reset: !loading.value
            }
          },
          id: 'realtime',
          animations: {
            enabled: true,
            easing: 'linear',
            dynamicAnimation: {
              speed: 1000
            }
          },
          zoom: {
            enabled: !loading.value,
            type: 'x',
            autoScaleYaxis: false,
          },
          locales: [{
            name: 'ja',
          }],
          defaultLocale: 'ja',
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            formatter: function(value) {
              return format(new Date(value),'yyyy-MM-dd HH:mm:ss')
            },
          },
          y: {
            formatter: function(value) {
              if (value == null) return 'N/A'
              return value
            }
          }
        },
        legend: {
          show: true,
          position: "left",
          formatter: function(seriesName, opts) {
            return makeSeriesName(seriesName)
          }
        },
        xaxis: {
          categories: props.dateTimes,
          type: 'datetime',
          min: makeMinAxis(),
          max: makeMaxAxis(),
          labels: {
            show: false
          }
        },
        yaxis: {
          tickAmount: props.tickAmount,
          min: props.yaxisMin,
          max: props.yaxisMax,
          decimalsInFloat: 1,
        },
        stroke: {
          width: 2
        },
      },
      series: [
        {
          name: "ch1",
          data: props.sensorDatas.unit1_channel1,
        },
        {
          name: "ch2",
          data: props.sensorDatas.unit2_channel1,
        },
        {
          name: "ch3",
          data: props.sensorDatas.unit3_channel1,
        },
        {
          name: "ch4",
          data: props.sensorDatas.unit4_channel1,
        },
      ],
    }
  })

  function styles () {
    return `width: ${props.width + 70}px;`
  }
</script>

<template>
  <div class="mx-10 h-200">
    <VueApexCharts
      ref="chart"
      height="300px"
      type="line"
      :options="data.chartOptions"
      :series="data.series"
      @zoomed="zoomed"
      @legendClick="onLegendClick"
    />
  </div>
  <div class="pr-10 d-flex justify-space-between" :style="styles()" style="margin: 0 0 0 auto;">
    <div v-for="xaxisLabel in xaxisLabels" :key="xaxisLabel">
      <p style="font-size: 12px;">{{ xaxisLabel }}</p>
    </div>
  </div>
</template>

<style>
  .battery-icon {
    vertical-align: middle;
  }
</style>
