<script setup>
  import 'gitart-vue-dialog/dist/style.css'
  import { GDialog } from 'gitart-vue-dialog'
  import { ref, onMounted, watch, toRefs } from 'vue'

  const props = defineProps({
    item: {
      default: null
    },
    itemName: {
      type: String
    },
    updatableStatusButton: {
      type: Boolean,
    },
    updatingStatus: {
      type: Boolean,
      default: false
    }
  })

  const dialogState = ref(false)
  const currentStatus = ref(false)
  const { updatingStatus, item } = toRefs(props)
  const emits = defineEmits(['submit'])

  onMounted(() => {
    currentStatus.value = !!item?.value.status
  })

  function closeModal () {
    dialogState.value = false
  }
  function submit () {
    const params = { id: item.value.id, status: item.value.status ? 0 : 1 }
    const key = 'status'
    emits('submit', params, key)
  }
  function viewStatus () {
    return item?.value.status ? '有効' : '無効'
  }
  function nextStatus () {
    return item?.value.status ? '無効' : '有効'
  }

  watch(item, () => {
    currentStatus.value = !!item?.value.status
  })
  watch(dialogState, () => {
    currentStatus.value = !!item?.value.status
  })
  watch(updatingStatus, (status) => {
    if (status) return;
    closeModal();
  })
</script>

<template>
  <GDialog
    v-model="dialogState"
    max-width="34%"
    transition="custom-from-bottom-transition"
  >
    <div class="modal-header justify-content-center pt-3">
      <label>確認</label>
    </div>
    <div class="row mr-3 ml-3 pl-5 v-theme--light">
      {{itemName}}を{{nextStatus()}}にします。よろしいですか？
    </div>
    <div class="d-flex justify-content-end m-3">
      <v-btn class="m-1 reset-btn" @click="closeModal" :disabled="updatingStatus">
        閉じる
      </v-btn>
      <v-btn class="m-1 exec-btn" @click="submit" :loading="updatingStatus">
        保存
      </v-btn>
    </div>
  </GDialog>

  <v-container
    class="px-0 py-2"
    fluid
    style="width: 3vw;"
  >
    <v-switch v-if="updatableStatusButton"
      v-model="currentStatus"
      @change="dialogState = true"
      color="success"
      hide-details
    ></v-switch>
    <v-switch v-if="!updatableStatusButton"
      v-model="currentStatus"
      @change="dialogState = true"
      color="success"
      :class="currentStatus ? 'text-success' : ''"
      disabled
      hide-details
    ></v-switch>
  </v-container>
</template>

<style>
.modal-header {
  font-weight: 600;
}
</style>
<style lang="scss">
.custom-rotate-transition {
  &-enter-from {
    transform: translate(0, 30px) rotate(20deg);
    opacity: 0;
  }
  &-leave-to {
    transform: translate(0, 30px) rotate(10deg);
    opacity: 0;
  }
}
.custom-from-bottom-transition {
  &-enter-from {
    transform: translate(0, 100%);
    opacity: 0;
  }
  &-leave-to {
    transform: translate(0, -30%);
    opacity: 0;
  }
}
.error *, .v-input--error .v-messages__message {
  color: rgb(var(--v-theme-error));
  opacity: 1;
}
</style>