<template>
  <div ref="card">
    <card>
      <template v-slot:header>
        <slot name="header" />
      </template>
      <div class="d-flex justify-content-around align-items-center mt-3">
        <template v-if="chartData?.datasets">
          <slot name="left-panel" />
          <DoughnutChart
            :chartData="chartData"
            :options="options"
            :height="height"
            :width="width"
          />
        </template>
      </div>
    </card>
  </div>
</template>

<script>
  import { Chart, registerables } from "chart.js";
  import { DoughnutChart } from 'vue-chart-3';

  import Card from '@/components/Cards/StsCard.vue'
  Chart.register(...registerables);

  export default {
    name: 'doughnut-chart-card',
    components: {
      DoughnutChart,
      Card,
    },
    methods: {
      handleResize: function() {
        const card = this.$refs.card;
        if (card) {
          const rect = card.getBoundingClientRect();
          const cardWidth = rect.width
          this.tableStyle = `width: ${cardWidth / 2.6}px !important;`
        }
      },
    },
    mounted: function () {
      this.handleResize()
      window.addEventListener('resize', this.handleResize)
    },
    props: {
      chartData: null,
      options: null,
      height: {
        type: String,
        default: '300'
      },
      width: {
        type: String,
        default: '350'
      },
    }
  }
</script>
<style>
  .card #doughnut-chart {
    height: 200px !important;
    width: 200px !important;
  }
</style>
