<template>
  <template v-if="!!formInputs?.form?.common">
    <p class="vehicle-ini-setting-title">COMMON</p>
    <div class="row mr-3 v-theme--light">
      <div class="col-6">
        <v-text-field
          class="pt-2"
          label="デバイスID"
          v-model="form.machine_id"
          :error-messages="errors[errorKeyPrefix + '.' + key]"
          :readonly="true"
        />
      </div>
      <template v-for="(title, key) in keys" v-bind:key="key">
        <Input
          :label="title"
          v-model="form[key]"
          :formKey="key"
          parentKey="common"
          :formInputs="formInputs"
          :readonly="selectDefault && readonlyInputs.includes(key)"
          :errors="errors[errorKeyPrefix + '.' + key]"
        >
          <template v-if="key == 'overwrite_deny'">
            <pre style="color: blue">
  ALL_ALLOW = 全て上書き設定
  ALL_DENY = 全て上書き禁止
  NORMAL_DENY = 常時録画上書き禁止
  EVENT_DENY = イベント録画上書き禁止
  DIGITACHO_DENY = デジタコデータ上書き禁止
            </pre>
          </template>
        </Input>
      </template>
    </div>
  </template>
</template>

<script>
import Api from '@/services/api/ApiServiceFabrick';
import Input from '@/components/VehicleFormParts/SettingFormInput.vue';

export default {
  props: {
    modelValue: {},
    formInputs: {},
    selectDefault: {
      type: Boolean,
      default: false,
    },
    errors: {},
    selfOfficeType: null,
  },
  data() {
    return {
      form: {},
      readonlyInputs: [],
      errorKeyPrefix: 'initial_params.common',
      keys: {
        ftp_on: 'FTPON',
        ftp_id: 'FTPID',
        ftp_pass: 'FTPPASS',
        alert_idling_first_sec: 'アイドリング超過時間（秒）：0～9999秒',
        alert_idling_second_sec: 'アイドリング警告繰返時間（秒）：0～9999秒',
        volume: '音量：0～5',
        alert_idling_sound: 'アイドリング警告アラート',
        gps_dr: 'GPSデットレコニング',
        camera_on: 'CAMERA_ON',
        overwrite_deny: 'OVERWRITE_DENY',
        wakeup_update: 'WAKEUP_UPDATE',
        upload_trip_data: 'UPLOAD_TRIP_DATA',
      },
    };
  },
  methods: {},
  watch: {
    modelValue: {
      handler() {
        this.form = this.modelValue;
      },
    },
    formInputs: {
      handler() {
        if (!this.formInputs?.form?.common) return;
        const key = Object.keys(this.formInputs.default_params)[0];
        this.readonlyInputs = Object.keys(
          this.formInputs.default_params[key]?.parameters?.common
        );
      },
    },
    form: {
      handler() {
        this.$emit('update:modelValue', this.form);
      },
      deep: true,
    },
  },
  components: {
    Input,
  },
};
</script>

<style></style>
