<template>
  <div class="d-flex" style="min-height: 45vw;">
    <div class="content p-4 w-100">
      <div class="d-flex">
        <h4 class="card-title mt-0">月報出力</h4>
        <p class="ml-5 pb-1 mb-1 align-self-end operation-office-initial-announce"
          v-show="operationOfficeInitialMessage && beforeSearchParams == null">
          当月を表示しています
        </p>
        <div class="ml-auto ml-2 mb-1 align-self-end">
          <ManualLink :pageName="pageName" />
        </div>
      </div>
      <div>
        <MonthlyDriverOrVehicleList
          :pageInfo="pageInfo"
          :searched="searched"
          :notReady="notReady"
          :isOperationOffice="isOperationOffice"
          :isDepartment="isDepartment"
          :items="driverOrVehicleList"
          :pagenate="searchMonthlyReportList"
          :listLoading="listLoading"
          :editableWorkingTime="!!Number(parseSettingsMonthlyReport()['enable_set_start_and_end_of_working_time'])"
          :operationOfficeId="targetOperationOffice?.id"
          :monthly="getMonthly"
          @select="handleSelectDriverOrVehicleIds"
        />
        <pagenate
          :range="2"
          :totalPage="totalPage"
          v-model="currentPage"
        />
        <hr />
      </div>
      <h4>出力条件</h4>
      <div>
        <MonthlyReportOutputLayout
          @change="setOutputSettings"
          v-model:existDriverOrVehicleList="existDriverOrVehicleList"
          v-model:aggregation="aggregation"
        />
        <div v-show="showableButtonArea">
          <ButtonArea
            :operationOfficeId="operationOfficeId"
            :operationOfficeName="operationOfficeName"
            :groupName="groupName"
            :monthly="monthly"
            :driverOrVehicleIds="selectedDriverOrVehicleIds"
            :outputSettings="selectOutputSettings"
            :showFlash="showFlash"
          />
        </div>
      </div>
    </div>

    <MonthlyReportSearchForm
      :loading="listLoading"
      :isOperationOffice="isOperationOffice"
      :isManagementOffice="isManagementOffice"
      :isSubManagementOffice="isSubManagementOffice"
      :isDepartment="isDepartment"
      @formchange="handleChangeSerarchForm"
      @search="searchMonthlyReportList"
    />
  </div>
</template>

<script>
  import { MANAGEMENT_OFFICE_TYPE, SUB_MANAGEMENT_OFFICE_TYPE, OPERATION_OFFICE_TYPE, DEPARTMENT_OFFICE_TYPE } from "@/services/consts.ts"
  import MonthlyReportSearchForm from "@/components/MonthlyReportSearchForm"
  import MonthlyDriverOrVehicleList from "@/components/MonthlyDriverOrVehicleList"
  import MonthlyReportOutputLayout from "@/components/MonthlyReportOutputLayout"
  import ButtonArea from "@/components/MonthlyReportOutputButtonArea"
  import Api from "@/services/api/ApiServiceFabrick";
  import PagenateItems from "@/components/PagenateItems"
  import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
  import ManualLink from "@/components/ManualLink";
  import {ref} from 'vue'
  import { useAuth } from "@/services/user/Auth0UserProvider"

  export default {
    props: {
      showFlash: {
        type: Function
      },
      switchContentLoading: {
        type: Function
      }
    },
    data() {
      return {
        form: {},
        currentPage: 1,
        totalPage: 0,
        selectedDriverOrVehicleIds: {},
        listLoading: false,
        searched: false,
        beforeSearchParams: null,
        aggregations: { "ドライバー": "driver", "車両": "vehicle" },
        existDriverOrVehicleList: false,
        aggregation: 'driver',
        operationOfficeInitialMessage: false,
        notReady: true,
        targetOperationOffice: null,
        pageName: "monthlyReport",
        isOperationOffice: false,
        isSubManagementOffice: false,
        isManagementOffice: false,
        isdepartment: false,
        defaultAggregation: "ドライバー",
      }
    },
    computed: {
      borderWidth() {
        return '1000';
      },
      getMonthly() {
        const monthIndex = this.form?.selectYearMonth?.month ?? new Date().getMonth();
        const month = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'][monthIndex]

        const year = this.form?.selectYearMonth?.year ?? new Date().getFullYear();
        return year + '/' + month;
      }
    },
    setup() {
      document.title = "月報出力"
      const showableButtonArea = ref(true)
      const setShowableButtonArea = async () => {
        const authorityList = await AuthorityCacheApi.getWithParse()
        showableButtonArea.value = authorityList["octlink.monthly_report.output"]
      }
      setShowableButtonArea()

      const { loginWithRedirect } = useAuth();

      return { showableButtonArea, loginWithRedirect }
    },
    mounted() {
      this.switchContentLoading(true)
      Api().getSelfAccount().then((data) => {
        this.isManagementOffice = data.login_organization_type == MANAGEMENT_OFFICE_TYPE
        this.isSubManagementOffice = data.login_organization_type == SUB_MANAGEMENT_OFFICE_TYPE
        this.isOperationOffice = data.login_organization_type == OPERATION_OFFICE_TYPE
        this.isDepartment = data.login_organization_type == DEPARTMENT_OFFICE_TYPE
        this.switchContentLoading(false)
        this.notReady = false
        if (this.isOperationOffice || this.isDepartment) return this.getInitialMonthlyReport()
      })
    },
    watch: {
      currentPage: {
        handler() {
          this.getMonthlyReports(this.currentPage, false)
        }
      }
    },
    methods: {
      async getInitialMonthlyReport() {
        this.listLoading = true
        let selfDepartment
        let selfOperationOffice
        if (this.isDepartment) {
          selfDepartment = await this.getDepartment()
          selfOperationOffice = await this.getOperationOffice(selfDepartment[0].operationOfficeId)
        } else {
          selfOperationOffice = await this.getSelfOffice()
        }
        const request = this.makeInitialParams(selfOperationOffice, selfDepartment)
        this.apiGetMonthlyReports(request, this.defaultAggregation)
      },
      async getDepartment() {
        let department
        await Api().autoCompleteDepartments().then((data) => {
          department = data
        })
        return department
      },
      async getSelfOffice() {
        let selfOffice
        await Api().getSelfOffice().then((data) => {
          selfOffice = data
        })
        return selfOffice
      },
      async getOperationOffice(operationOfficeId) {
        let operationOffice
        await Api().getOperationOffice(operationOfficeId).then((data) => {
          operationOffice = data
        })
        return operationOffice
      },
      makeInitialParams(operationoffice, departments = []) {
        const department = departments.length == 1 ? departments[0] : null

        return {
          page: 1,
          operation_office_id: operationoffice?.id,
          management_office_id: operationoffice?.management_officeable_id,
          management_officeable_type: operationoffice?.management_officeable_type,
          department_id: department?.id,
          monthly: this.getMonthly,
          type: this.aggregations[this.defaultAggregation]
        }
      },
      handleChangeSerarchForm(form) {
        this.form = form
      },
      setOutputSettings(settings) {
        this.selectOutputSettings = settings
      },
      handleSelectDriverOrVehicleIds(selectedDriverOrVehicleIds) {
        this.selectedDriverOrVehicleIds = selectedDriverOrVehicleIds
      },
      searchMonthlyReportList() {
        this.operationOfficeInitialMessage = false
        this.searched = true
        this.getMonthlyReports()
      },
      getMonthlyReports(page = 1, updateOption = true) {
        this.listLoading = true
        let req
        const aggregation = this.form?.targetOfAggregation
        if (updateOption || !this.beforeSearchParams) {
          req = this.makeSearchParams(page, aggregation)
          this.beforeSearchParams = req
        } else {
          req = this.beforeSearchParams
          req.page = page
        }
        this.apiGetMonthlyReports(req, aggregation)
      },
      apiGetMonthlyReports(req, aggregation) {
        Api().getMonthlyReports(req).then((data) => {
          this.targetOperationOffice = this.form.operationOffice;

          this.driverOrVehicleList = data.items
          this.aggregation = this.aggregations[aggregation]
          this.existDriverOrVehicleList = this.driverOrVehicleList.length != 0
          this.currentPage = data.current_page
          this.totalPage = data.total_page
          this.operationOfficeInitialMessage = true
          this.listLoading = false

          this.operationOfficeId = this.form.operationOffice?.id
          this.operationOfficeName = this.form.operationOffice?.name
          this.groupName = this.form.group?.name
          this.monthly = this.getMonthly
        }).catch((error) => {
          const res = error.response
          switch (res.status) {
            case 401:
              this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
              break
            case 403:
              location.reload()
              break
            case 422:
          }
          this.listLoading = false
        })
      },
      makeSearchParams(page, aggregation){
        const monthly = this.getMonthly
        const ret = {
          page: page,
          operation_office_id: this.form?.operationOffice?.id,
          management_office_id: this.form?.managementOffice?.id,
          management_officeable_type: this.form?.managementOffice?.type,
          monthly: monthly,
          group_id: this.form?.group?.id,
          department_id: this.form?.department?.id,
          type: this.aggregations[aggregation]
        }
        if (aggregation == "ドライバー") {
          ret['driver_id'] = this.form?.driver?.driver_id
          ret['account_id'] = this.form?.driver?.id
        }
        if (aggregation == "車両") ret['vehicle_id'] = this.form?.vehicle?.id

        return ret
      },
      parseSettingsMonthlyReport() {
        if (!this.targetOperationOffice) return {};

        return JSON.parse(this.targetOperationOffice.settings_monthly_report);
      }
    },
    components: {
      MonthlyReportSearchForm,
      MonthlyDriverOrVehicleList,
      MonthlyReportOutputLayout,
      ButtonArea,
      pagenate: PagenateItems,
      ManualLink
    }
  }
</script>

<style>
.main-panel {
  height: initial !important;
  max-height: initial !important;
}
.exec-btn {
  background-color: rgb(21, 130, 156);
  color: white !important;
}
.reset-btn {
  background-color: rgb(245, 246, 247);
  color: rgb(21, 130, 156) !important;
  font-weight: bold;
}
.operation-office-initial-announce {
  font-size: 10px
}

</style>
