<template>
  <v-row class="text-form pl-4 pr-3">
    <v-col cols="3">
      <h6 class="pt-2 pl-4">{{ title }}</h6>
    </v-col>
    <v-col cols="9" class="pt-0">
      <v-textarea
        class="small pt-2"
        v-model="textValue"
        rows="3"
        :error-messages="errors"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: String,
    errors: Array,
    item: [String, Number],
    itemKey: String,
  },
  data: () => ({
    textValue: null,
  }),
  watch: {
    item: {
      handler(value) {
        this.textValue = value;
      },
    },
    textValue: {
      handler(newTextValue) {
        if (newTextValue == this.item) return;

        this.$emit('updateItem', this.itemKey, newTextValue);
      },
    },
  },
  components: {},
};
</script>

<style></style>
