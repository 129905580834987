<template>
  <div ref="modal_form" style="height: 90vh; overflow: auto">
    <div class="modal-header justify-content-center pt-3">
      <label>{{ label }}</label>
    </div>
    <div class="row mr-3 ml-3 v-theme--light">
      <div class="col-10">
        <v-text-field
          label="名称"
          v-model="form.name"
          :error-messages="errors.name"
        />
      </div>
    </div>
    <div class="row mr-3 ml-3 v-theme--light">
      <div class="col-5">
        <v-select
          class="small"
          v-model="selectedPageComposition"
          :items="displayPageCompositions()"
          label="ページ単位"
          :clearable="false"
        >
        </v-select>
      </div>
    </div>
    <div class="row mr-3 ml-3 v-theme--light">
      <v-checkbox
        v-model="allSelect"
        dense
        class="mb-0 all-select"
        label="チャート表示をすべて選択"
        @change="selectAll"
      />
      <v-select
        class="small"
        v-model="selectedChartDisplay"
        :items="chartDisplayList"
        label="チャート表示"
        multiple
      />
    </div>
    <div class="row mr-3 ml-3 v-theme--light">
      <div class="col-10">
        <v-textarea
          label="メモ"
          height="300px"
          v-model="form.note"
        ></v-textarea>
      </div>
    </div>
    <Buttons
      @cancel="closeModal"
      @submit="submit"
      :running="running"
      :hasError="hasError"
    />
  </div>
</template>

<script setup>
import 'gitart-vue-dialog/dist/style.css';
import Api from '@/services/api/ApiServiceFabrick';
import Buttons from '@/components/DailyReportLayoutParts/ModalButtons';
import {
  dailyReportChartDisplay,
  dailyReportPageCompositions,
} from '@/services/consts';
import { ref, defineProps, defineEmits, watch, onMounted } from 'vue';

const props = defineProps(['item', 'showFlash', 'modelValue']);
const emit = defineEmits(['updateItem', 'update:modelValue']);

const chartDisplayList = [];
dailyReportChartDisplay.forEach((v) => {
  chartDisplayList.push(v['label']);
});
const selectedChartDisplay = ref([]);

const form = ref({ id: '' });
const errors = ref({});
const selectedPageComposition = ref(dailyReportPageCompositions[0].label);
const name = ref(null);
const running = ref(false);
const modal_form = ref(null);
const allSelect = ref(false);

onMounted(() => {
  mirrorForm();
});

const displayPageCompositions = () => {
  return dailyReportPageCompositions.map((v) => {
    return v.label;
  });
};

const selectAll = () => {
  if (allSelect.value) {
    selectedChartDisplay.value = chartDisplayList;
  } else {
    selectedChartDisplay.value = [];
  }
};
watch(selectedChartDisplay, () => {
  if (arrayEquals(chartDisplayList, selectedChartDisplay.value)) {
    allSelect.value = true;
  } else {
    allSelect.value = false;
  }
});
const arrayEquals = (baseArr, arr) => {
  let ret = true;
  baseArr.forEach((v) => {
    if (arr.indexOf(v) < 0) ret = false;
  });
  return ret;
};

const validate = () => {
  let invalid = false;
  invalid = !validateName() || invalid;
  return !invalid;
};
const validateName = () => {
  if (form.value.name) {
    errors.value.name = [];
    return true;
  } else {
    errors.value.name = ['入力してください'];
    return false;
  }
};
const submit = () => {
  if (!validate()) {
    modal_form.value.scrollTop = 0;
    return;
  }
  running.value = true;

  let method = Api().createDailyReportLayout;
  if (props.item.id) {
    method = Api().updateDailyReportLayout;
  }

  const params = makeRequestParam();
  method(params)
    .then(() => {
      emit('updateItem');
      props.showFlash('保存に成功しました。', 'success', true);
      running.value = false;
      closeModal();
    })
    .catch((error) => {
      const res = error.response;
      switch (res?.status) {
        case 401:
          props.showFlash('保存に失敗しました。', 'error', false);
          props.closeModal();
          this.loginWithRedirect({
            appState: { targetUrl: location.pathname },
          });
          break;
        case 403:
          props.showFlash('保存に失敗しました。', 'error', false);
          location.reload();
          break;
        case 422:
          errors.value = res.data.errors;
          modal_form.value.scrollTop = 0;
      }
      running.value = false;
    });
};

const makeRequestParam = () => {
  const param = {
    id: form.value.id,
    name: form.value.name,
    note: form.value.note,
  };
  setSelectedChartDisplayToParam(param);
  setPageCompositionsToParam(param);
  setIsNoteToParam(param);
  if (!form.value?.id) param.id = '';
  return param;
};

const mirrorForm = () => {
  form.value = JSON.parse(JSON.stringify(props.item));
  setSelectedChartDisplayFromForm();
  setPageCompositionsFromForm();
};

const setSelectedChartDisplayFromForm = () => {
  selectedChartDisplay.value = [];
  dailyReportChartDisplay.forEach((v) => {
    if (form.value[v.key]) selectedChartDisplay.value.push(v.label);
  });
};

const setSelectedChartDisplayToParam = (param) => {
  dailyReportChartDisplay.forEach((v) => {
    param[v.key] = selectedChartDisplay.value.indexOf(v.label) >= 0;
  });
};

const setPageCompositionsFromForm = () => {
  dailyReportPageCompositions.forEach((v) => {
    if (v.data == form.value.page_structure)
      selectedPageComposition.value = v.label;
  });
};

const setPageCompositionsToParam = (param) => {
  dailyReportPageCompositions.forEach((v) => {
    if (v.label == selectedPageComposition.value) param.page_structure = v.data;
  });
};

const setIsNoteToParam = (param) => {
  param.is_note = form.value.note ? '有' : '無し';
};

const closeModal = () => {
  emit('update:modelValue', false);
};
</script>

<style scoped lang="scss">
.modal-header {
  font-weight: 600;
}
.custom-rotate-transition {
  &-enter-from {
    transform: translate(0, 30px) rotate(20deg);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, 30px) rotate(10deg);
    opacity: 0;
  }
}

.custom-from-bottom-transition {
  &-enter-from {
    transform: translate(0, 100%);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, -30%);
    opacity: 0;
  }
}
</style>
