<template>
  <template v-if="!!formInputs?.form?.event">
    <p class="vehicle-ini-setting-title">EVENT</p>
    <div class="row mr-3 v-theme--light">
      <template v-for="title, key in keys" v-bind:key="key">
        <Input
          :label="title"
          v-model="form[key]"
          :formKey="key"
          parentKey="event"
          :formInputs="formInputs"
          :readonly="selectDefault && readonlyInputs.includes(key)"
          :errors="errors[errorKeyPrefix+'.'+key]"
        />
      </template>
    </div>
  </template>
</template>

<script>
import Api from "@/services/api/ApiServiceFabrick";
import Input from "@/components/VehicleFormParts/SettingFormInput.vue";

export default {
  props: {
    modelValue: {},
    formInputs: {},
    selectDefault: {
      type: Boolean,
      default: false
    },
    errors: {},
  },
  data() {
    return {
      form: {
        'pretime': '',
        'posttime': '',
      },
      readonlyInputs: [],
      errorKeyPrefix: 'initial_params.event',
      keys: {
        'pretime': 'PRETIME',
        'posttime': 'POSTTIME',
      }
    }
  },
  methods: {
  },
  watch: {
    modelValue: {
      handler() {
        this.form = Object.assign(this.form, this.modelValue)
      }
    },
    formInputs: {
      handler() {
        if (!this.formInputs?.form?.event) return
        const key = Object.keys(this.formInputs.default_params)[0]
        this.readonlyInputs = Object.keys(this.formInputs.default_params[key]?.parameters?.event ?? [])
      }
    },
    form: {
      handler() {
        this.$emit('update:modelValue', this.form)
      },
      deep: true
    },
  },
  components: {
    Input,
  },
}
</script>

<style>
</style>
