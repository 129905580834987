<template>
  <div class="d-inline-flex" :class="className">
      <span class="header-sort-icons" :class="className">
        <span class="nc-icon nc-stre-up sort-icon" :class="isSelected('ASC') ? 'selected' : ''" @click="sort('ASC')" />
        <span class="nc-icon nc-stre-down sort-icon" :class="isSelected('DESC') ? 'selected' : ''" @click="sort('DESC')" />
      </span>
      <span class="header-sort-label" :class="sortKey == name ? 'selected' : ''" @click="toggleSort">{{label}}</span>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
      className: {
        type: String,
        default: '',
      },
      modelValue: null
    },
    computed: {
      sortKey() {
        if (this.modelValue?.sort != this.name) return
        return this.modelValue?.sort
      },
      sortType() {
        if (this.modelValue?.sort != this.name) return
        return this.modelValue?.type
      },
    },
    methods: {
      sort(type) {
        this.$emit('update:modelValue', {
          sort: this.name,
          type: type
        })
      },
      isSelected(type) {
        return this.sortType == type
      },
      toggleSort() {
        const type = this.sortType == 'ASC' ? 'DESC' : 'ASC'
        this.sort(type)
      },
    }
  }
</script>

<style scoped>
.sortable-header {
  min-width: 3vw;
}
.header-sort-icons {
  display: flex;
  flex-direction: column;
  padding-right: 0.5vw;
}
.header-sort-icons>div {
  display: block;
}
.header-sort-label {
  word-break: keep-all;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.selected {
  font-weight: bold;
}
</style>
