import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import LightBootstrap from './light-bootstrap-main'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
import i18n from "@/lang/i18n"

import './assets/sass/gitart-vue-dialog.scss'
import './assets/css/global.scss'

import { Amplify } from 'aws-amplify';
import aws_exports from './aws-exports'

Amplify.configure({
  API: {
    GraphQL: {
      ...(aws_exports.API.GraphQL),
      defaultAuthMode: 'apiKey',
      apiKey: process.env.VUE_APP_GRAPHQL_API_KEY,
      endpoint: process.env.VUE_APP_GRAPHQL_API_END_POINT ?? '',
      region: process.env.VUE_APP_GRAPHQL_REGION
    }
  }
})


loadFonts()
AuthorityCacheApi.delete() // delete cache for authority

createApp(App)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      liblalies: ['place']
    },
  })
  .use(LightBootstrap)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .mount('#app')

