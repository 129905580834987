<template>
  <div class="mt-6 mb-4">
    <v-table class="table-border">
      <tr>
        <th colspan="4" class="h5 font-weight-bold py-3 title text-center">
          減点設定
        </th>
      </tr>
      <v-container class="my-2 mx-auto">
        <div class="border">
          <tr>
            <th
              :rowspan="windowInnerWidth > 800 ? 2 : 4"
              class="select-title px-2 col-1"
            >
              速度減点
            </th>
            <td
              :rowspan="windowInnerWidth > 800 ? 2 : 4"
              :class="windowInnerWidth > 800 ? 'col-2' : 'col-2 center'"
            >
              <v-radio-group row="true" v-model="checked">
                <v-radio
                  :class="
                    windowInnerWidth > 800
                      ? 'radio-button'
                      : 'radio-button pb-12'
                  "
                  value="times"
                  label="アラート検知"
                  color="blue"
                  :checked="checked == 'times'"
                />
                <v-radio
                  :class="
                    windowInnerWidth > 800
                      ? 'pt-7 radio-button'
                      : 'radio-button pt-12'
                  "
                  value="seconds"
                  label="速度超過秒数"
                  color="blue"
                  :checked="checked == 'seconds'"
                />
              </v-radio-group>
            </td>
            <td class="col-4">
              <v-col>
                <v-select
                  :items="options"
                  suffix="回で"
                  v-model="settings.overSpeedTimes"
                  :clearable="false"
                />
              </v-col>
            </td>
            <td class="col-4" v-show="windowInnerWidth > 800">
              <v-col>
                <v-select
                  :items="options"
                  v-model="settings.overSpeedTimesScore"
                  suffix="点 減点"
                  :clearable="false"
                />
              </v-col>
            </td>
          </tr>
          <tr v-show="windowInnerWidth <= 800">
            <td>
              <v-col>
                <v-select
                  :items="options"
                  v-model="settings.overSpeedTimesScore"
                  suffix="点 減点"
                  :clearable="false"
                />
              </v-col>
            </td>
          </tr>
          <tr>
            <td class="col-4">
              <v-col>
                <v-select
                  :items="OverSpeedSecondsOption"
                  suffix="秒で"
                  v-model="settings.overSpeedSeconds"
                  :clearable="false"
                />
              </v-col>
            </td>
            <td class="col-4 pb-2" v-show="windowInnerWidth > 800">
              <v-col>
                <v-select
                  :items="options"
                  v-model="settings.overSpeedSecondsScore"
                  suffix="点 減点"
                  :clearable="false"
                />
              </v-col>
            </td>
          </tr>
          <tr v-show="windowInnerWidth <= 800">
            <td>
              <v-col>
                <v-select
                  :items="options"
                  v-model="settings.overSpeedSecondsScore"
                  suffix="点 減点"
                  :clearable="false"
                />
              </v-col>
            </td>
          </tr>
        </div>
        <div class="border">
          <tr>
            <th
              :rowspan="windowInnerWidth > 800 ? '' : 2"
              class="col-1 px-2 select-title"
            >
              減速減点
            </th>
            <td
              :rowspan="windowInnerWidth > 800 ? '' : 2"
              class="col-2 sub-title"
            >
              アラート検知
            </td>
            <td class="pt-3 col-4">
              <v-col>
                <v-select
                  :items="options"
                  suffix="回で"
                  v-model="settings.decelerationTimes"
                  :clearable="false"
                />
              </v-col>
            </td>
            <td class="col-4 pb-2" v-show="windowInnerWidth > 800">
              <v-col>
                <v-select
                  :items="options"
                  v-model="settings.decelerationScore"
                  suffix="点 減点"
                  :clearable="false"
                />
              </v-col>
            </td>
          </tr>
          <tr v-show="windowInnerWidth <= 800">
            <td>
              <v-col>
                <v-select
                  :items="options"
                  v-model="settings.decelerationScore"
                  suffix="点 減点"
                  :clearable="false"
                />
              </v-col>
            </td>
          </tr>
        </div>
        <div class="border">
          <tr>
            <th
              :rowspan="windowInnerWidth > 800 ? '' : 2"
              class="col-1 px-2 select-title"
            >
              加速減点
            </th>
            <td
              :rowspan="windowInnerWidth > 800 ? '' : 2"
              class="col-2 sub-title"
            >
              アラート検知
            </td>
            <td class="pt-3 col-4">
              <v-col>
                <v-select
                  :items="options"
                  suffix="回で"
                  v-model="settings.accelerationTimes"
                  :clearable="false"
                />
              </v-col>
            </td>
            <td class="col-4 pb-2" v-show="windowInnerWidth > 800">
              <v-col>
                <v-select
                  :items="options"
                  v-model="settings.accelerationScore"
                  suffix="点 減点"
                  :clearable="false"
                />
              </v-col>
            </td>
          </tr>
          <tr v-show="windowInnerWidth <= 800">
            <td>
              <v-col>
                <v-select
                  :items="options"
                  v-model="settings.accelerationScore"
                  suffix="点 減点"
                  :clearable="false"
                />
              </v-col>
            </td>
          </tr>
        </div>
        <div class="border">
          <tr>
            <th
              :rowspan="windowInnerWidth > 800 ? '' : 2"
              class="col-1 px-2 select-title"
            >
              旋回減点
            </th>
            <td
              :rowspan="windowInnerWidth > 800 ? '' : 2"
              class="col-2 sub-title"
            >
              アラート検知
            </td>
            <td class="pt-3 col-4">
              <v-col>
                <v-select
                  :items="options"
                  suffix="回で"
                  v-model="settings.sharpSteeringWheelTimes"
                  :clearable="false"
                />
              </v-col>
            </td>
            <td class="col-4 pb-2 pr-0" v-show="windowInnerWidth > 800">
              <v-col>
                <v-select
                  :items="options"
                  v-model="settings.sharpSteeringWheelScore"
                  suffix="点 減点"
                  :clearable="false"
                />
              </v-col>
            </td>
          </tr>
          <tr v-show="windowInnerWidth <= 800">
            <td class="pb-2">
              <v-col>
                <v-select
                  :items="options"
                  v-model="settings.sharpSteeringWheelScore"
                  suffix="点 減点"
                  :clearable="false"
                />
              </v-col>
            </td>
          </tr>
        </div>
      </v-container>
    </v-table>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {},
    windowInnerWidth: Number,
  },
  data() {
    return {
      settings: {},
      demeritSetting: {},
      checked: '',
      options: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
      ],
      OverSpeedSecondsOption: [
        '10',
        '20',
        '30',
        '40',
        '50',
        '60',
        '70',
        '80',
        '90',
        '100',
      ],
    };
  },
  methods: {
    initialValue() {
      if (this.modelValue && this.modelValue != null) {
        const settings = this.modelValue;
        this.checked = 'times';
        if (!settings.over_speed?.times) {
          this.checked = 'seconds';
        }

        settings['overSpeedTimes'] =
          settings && settings.over_speed.times
            ? settings.over_speed.times
            : '1';
        settings['overSpeedTimesScore'] = settings.over_speed.times
          ? settings.over_speed.score
          : '1';
        settings['overSpeedSeconds'] = settings.over_speed.seconds
          ? settings.over_speed.seconds
          : '10';
        settings['overSpeedSecondsScore'] = settings.over_speed.seconds
          ? settings.over_speed.score
          : '1';
        settings['decelerationTimes'] = settings.rapid_deceleration.times
          ? settings.rapid_deceleration.times
          : '1';
        settings['decelerationScore'] = settings.rapid_deceleration.score
          ? settings.rapid_deceleration.score
          : '1';
        settings['accelerationTimes'] = settings.rapid_acceleration.times
          ? settings.rapid_acceleration.times
          : '1';
        settings['accelerationScore'] = settings.rapid_acceleration.score
          ? settings.rapid_acceleration.score
          : '1';
        settings['sharpSteeringWheelTimes'] = settings.sharp_steering_wheel
          .times
          ? settings.sharp_steering_wheel.times
          : '1';
        settings['sharpSteeringWheelScore'] = settings.sharp_steering_wheel
          .score
          ? settings.sharp_steering_wheel.score
          : '1';

        this.settings = settings;
      }
      return this.settings;
    },
    changeSettingsValue() {
      this.$emit('update:modelValue', this.settings);
    },
  },
  watch: {
    modelValue: {
      handler() {
        if (this.modelValue) {
          this.initialValue();
        }
      },
    },
    settings: {
      handler() {
        this.changeSettingsValue();
      },
      deep: true,
    },
    checked: {
      handler() {
        this.settings['checked'] = this.checked;
      },
    },
  },
};
</script>

<style>
.title {
  border-bottom: 2px solid #dee2e6;
}
.table-border {
  border: 2px solid #dee2e6;
}
.center {
  vertical-align: middle;
}
.select-title {
  border-right: 2px solid #dee2e6;
  vertical-align: middle;
}
.radio-button {
  color: #212529;
}
.sub-title {
  vertical-align: middle;
  font-size: 16px;
  padding-left: 20px;
  color: #00000099;
}
</style>
