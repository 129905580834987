import AuthorityCacheApi from "@/services/models/AuthorityCacheApi"
import accountType from "@/types/account"
import { GENERATE_ACCOUNT_PASSWORD_PREFIX, GENERATE_EMAIL_DOMAIN, SECOND_LEVEL_DEPARTMENT_KEY } from "@/services/consts"
import { nextTick } from 'vue';
import SelectRoleChecker from '@/router/selectRoleChecker'
import roleType from "@/types/responses/getRole"
import Role from "@/types/SelfRole";

export const round = (num:number, numberOfDigit:number=0 as number): number => {
  const multiplier = 10 ** numberOfDigit;
  return Math.round(num * multiplier) / multiplier;
}
export const numberToHourAndMinite = (num:number):string => {
  const hour = Math.floor(num / 3600).toString(10).padStart(2, '0')
  const minite = Math.floor(num % 3600 / 60).toString(10).padStart(2, '0')
  return hour + ":" + minite
}
export const dateToDayAndTime = (date:Date):string => {
  const pad = date.getHours() >= 10 ? '' : '0'
  return date.getDate() + "/" + pad + date.toLocaleTimeString()
}
export const addSecondsToDateString = (date_time:string, seconds:number):string => {
  const time = new Date(date_time).valueOf() + seconds * 1000
  return new Date(time).toLocaleString()
}

export const openLink = (url:string, newTab:boolean) => {
  const a = document.createElement("a") as HTMLAnchorElement;
  document.body.appendChild(a);
  a.setAttribute('style', "display: none");
  a.href = url;
  if ( newTab ){
    a.target = '_blank';
  }
  a.click();
  document.body.removeChild(a);
};

export const utcToJst = (date: Date): Date => {
  return new Date(date.setMinutes(date.getMinutes() - date.getTimezoneOffset()));
}

export const formatDateTime = (date: Date, separateDateChar = '-'): string => {
  return formatDate(date, separateDateChar) + ' ' + formatTime(date);
}

export const formatDateTimeJP = (date: Date): string => {
  return formatDateJP(date) + ' ' + formatTime(date);
}

export const formatDateTimeWithSeconds = (date: Date): string => {
  return formatDate(date) + ' ' + formatTimeWithSeconds(date);
}

export const formatDate = (date: Date, separateChar = '-'): string => {
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear().toString();
  return y + separateChar + (m <= 9 ? '0' + m : m) + separateChar + (d <= 9 ? '0' + d : d);
}

export const formatDateJP = (date: Date): string => {
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear().toString();
  return y + '年' + (m <= 9 ? '0' + m : m) + '月' + (d <= 9 ? '0' + d : d) + '日';
}

export const dateToNumber = (date: Date): number => {
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear().toString();
  return Number(y + (m <= 9 ? '0' + m : m) + (d <= 9 ? '0' + d : d));
}

export const formatTime = (date: Date): string => {
  const h = date.getHours();
  const mm = date.getMinutes();
  return (h <= 9 ? '0' + h : h) + ':' + (mm <= 9 ? '0' + mm : mm);
}

export const formatTimeFromSeconds = (seconds: number): string => {
  const h = Math.floor(seconds / 3600);
  const mm = Math.floor(seconds % 3600 / 60);
  return (h <= 9 ? '0' + h : h) + ':' + (mm <= 9 ? '0' + mm : mm);
}

export const formatTimeWithSeconds = (date: Date): string => {
  const h = date.getHours();
  const mm = date.getMinutes();
  const s = date.getSeconds();
  return (h <= 9 ? '0' + h : h) + ':' + (mm <= 9 ? '0' + mm : mm) + ':' + (s <= 9 ? '0' + s : s);
}

export const convertSecondsFromStringTime = (time: string | null): number => {
  if (!time) return 0;

  const splitedTimeArray = time.split(':');
  const hour = splitedTimeArray[0] ?? 0;
  const minute = splitedTimeArray[1] ?? 0;

  return (Number(hour) * 60 * 60) + (Number(minute) * 60);
}

export const calculatePageCount = (all_elements_count: number, elements_per_page: number): number => {
  if (all_elements_count < 1) {
    return 0;
  }

  if (all_elements_count <= elements_per_page) {
    return 1;
  }
  return Math.ceil(all_elements_count / elements_per_page)
}
export const deepMergeObject = (object1:object, object2:object) => {
  const obj1 = JSON.parse(JSON.stringify(object1))
  const obj2 = JSON.parse(JSON.stringify(object2))

  Object.keys(obj2).forEach((key:string) => {
    if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
      obj1[key] = Array.from(new Set(obj1[key].concat(obj2[key])))
      return
    }
    if (typeof(obj1[key]) === 'object' && obj1[key] != null
        && typeof(obj2[key]) === 'object' && obj2[key] != null) {
      obj1[key] = deepMergeObject(obj1[key], obj2[key])
      return
    }
    obj1[key] = obj2[key]
  })
  return obj1
}

export const searchValuesFromObject = (obj:object, values:{[key:string]: any}) => {
  const _obj = JSON.parse(JSON.stringify(obj))
  const keys = Object.keys(values)
  const ret:{[name: string]: any} = {}

  keys.forEach((key:string) => {
    if (typeof(_obj[key]) === 'object' && _obj[key] != null) {
      ret[key] = searchValuesFromObject(_obj[key], values[key])
    } else {
      ret[key] = searchValueFromObject(_obj, key)
    }
    if (ret[key] == null ) ret[key] = ''
  })
  return ret
}

const searchValueFromObject = (obj:object, key:string) => {
  const _obj = JSON.parse(JSON.stringify(obj))
  let ret = null

  Object.keys(_obj).forEach((_key:string) => {
    if (key == _key) {
      ret = _obj[_key]
      return
    }
    if (typeof(_obj[_key]) === 'object' && _obj[_key] != null) {
      const value = searchValueFromObject(_obj[_key], key)
      if (value != null) {
        ret = value
        return
      }
    }
  })
  return ret
}

export const ObjectToFormData = (param: object, form: FormData, chainKey: string) => {
  const obj = JSON.parse(JSON.stringify(param))

  Object.keys(obj).forEach((key:string) => {
    let _key = chainKey
    if (chainKey != '') {
      _key += `[${key}]`
    } else {
      _key += key
    }

    if (typeof(obj[key]) == 'object' && obj[key] != null) {
      ObjectToFormData(obj[key], form, _key)
    } else if (obj[key] != null) {
      form.set(_key, obj[key])
    }
  })
  return form
}

export const filterObject = (object1:object, object2:object) => {
  const obj1 = JSON.parse(JSON.stringify(object1))
  const obj2 = JSON.parse(JSON.stringify(object2))
  const ret:{ [name: string]: any }  = {}

  Object.keys(obj2).forEach((key:string) => {
    if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
      ret[key] = obj1[key]
      return
    }
    if (typeof(obj1[key]) === 'object' && obj1[key] != null
        && typeof(obj2[key]) === 'object' && obj2[key] != null) {
      ret[key] = filterObject(obj1[key], obj2[key])
      return
    }
    ret[key] = obj1[key]
  })
  return ret
}

export const sizeFromByte = (_size:number, _digit = 1):string => {
  if (!_size) return '';
  const sizes = ['B', 'KB', 'MB', 'GB', 'PB'];
  let size = _size;
  const digit = 10 ** _digit;

  for (let i = 0; i < sizes.length; i++) {
    if (size / 1024 < 1) {
      size = Math.floor(size * digit) / digit;
      return size.toString(10) + ' ' + sizes[i];
    }
    size = size / 1024;
  }
  return 'Unknown';
}

export const deleteParams = (params:any, deleteKeys:Array<string>) => {
  deleteKeys.forEach((key:string) => {
    delete(params[key])
  })
  return params
}

export const getAuthorities = (isError = false) => {
  if (isError) AuthorityCacheApi.reflesh()
  return AuthorityCacheApi.getWithParse()
}

export const generatePassword = (driverId: number): string => {
  let passwordElement = String(driverId)
  const lowestLength = 4
  if (passwordElement.length < lowestLength) passwordElement = passwordElement.padStart(lowestLength, "0")

  return `${GENERATE_ACCOUNT_PASSWORD_PREFIX}${passwordElement}`
}

export const generateEmail = (driverId: number, officeId: number): string => {
  return `${driverId}@${officeId}${GENERATE_EMAIL_DOMAIN}`
}

export const scrollTop = (elementId: string) => {
  nextTick(() => {
    const scrollPoint = document.getElementById(elementId)
    scrollPoint?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
  })
}

export const accountName = (account: accountType): string => {
  return `${account.last_name}${account.first_name}`
}

export const isCurrentRoleIncludedSelfRoles = async (): Promise<boolean> => {
  const allRoles = await SelectRoleChecker.getSelfRoles()
  const currentRole: Role | null = SelectRoleChecker.getCurrentRole()
  if (!currentRole) return false

  return allRoles.findIndex((role) => {
    return role.id === currentRole.id
  }) != -1
}

export const removeCurrentRole = (): void => {
  SelectRoleChecker.removeCurrentRole()
  location.reload()
}

export const isSecondLevelDepartment = (key: number): boolean => {
  return key == SECOND_LEVEL_DEPARTMENT_KEY
}
