<template>
  <div class="working-hours-setting-form">
    <v-row class="radio-form pl-4 pr-3 mb-2">
      <v-col :cols="windowInnerWidth > 800 ? 2 : 3">
        <h6 class="pt-2 pl-4" style="font-weight: bold">{{ title }}</h6>
      </v-col>
      <v-col
        :cols="
          windowInnerWidth <= 800 ||
          (windowInnerWidth >= 992 && windowInnerWidth <= 1197)
            ? 6
            : 3
        "
        class="pr-0"
      >
        <v-radio-group
          v-model="workingHoursSetting.type"
          class="radio-group-row"
        >
          <v-radio value="0" label="日別で設定" color="blue" />
          <v-radio value="1" label="月別で設定" color="blue" />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row
      v-show="workingHoursSetting.type == 0"
      class="radio-form pl-4 pr-3 mb-2"
    >
      <v-col v-show="windowInnerWidth > 800" :cols="2"> </v-col>
      <v-col :cols="windowInnerWidth > 800 ? 3 : 6">
        <ValidationErrors
          :messages="
            errors['settings_monthly_report.over_time_thresholds.daily.hours']
          "
        >
          <v-text-field
            v-model="workingHoursSetting.daily.hours"
            suffix="時間"
            class="mt-0"
            density="compact"
            maxlength="2"
            :error="
              errors[
                'settings_monthly_report.over_time_thresholds.daily.hours'
              ]?.length ? true : false
            "
          />
        </ValidationErrors>
      </v-col>
      <v-col :cols="windowInnerWidth > 800 ? 3 : 6">
        <ValidationErrors
          :messages="
            errors['settings_monthly_report.over_time_thresholds.daily.minutes']
          "
        >
          <v-text-field
            v-model="workingHoursSetting.daily.minutes"
            suffix="分"
            class="mt-0"
            density="compact"
            maxlength="2"
            :error="
              errors[
                'settings_monthly_report.over_time_thresholds.daily.minutes'
              ]?.length ? true : false
            "
          />
        </ValidationErrors>
      </v-col>
    </v-row>
    <v-row
      v-show="workingHoursSetting.type == 1"
      v-for="(time, month) in workingHoursSetting.monthly"
      :key="month"
      class="pl-4 pr-3 mb-2"
    >
      <v-col v-show="windowInnerWidth > 800" cols="2"> </v-col>
      <v-col cols="1">
        <div class="pl-3" style="font-size: 14px">{{ month }}月</div>
      </v-col>
      <v-col
        :cols="windowInnerWidth > 800 ? 3 : 5"
        :style="windowInnerWidth > 800 ? '' : 'margin-right: 23px;'"
      >
        <ValidationErrors
          :messages="
            errors[
              `settings_monthly_report.over_time_thresholds.${month}.hours`
            ]
          "
        >
          <v-text-field
            v-model="workingHoursSetting.monthly[month].hours"
            suffix="時間"
            class="mt-0"
            density="compact"
            :min="0"
            maxlength="4"
            :error="
              errors[
                `settings_monthly_report.over_time_thresholds.${month}.hours`
              ]?.length ?? 0 > 0
            "
          />
        </ValidationErrors>
      </v-col>
      <v-col :cols="windowInnerWidth > 800 ? 3 : 5">
        <ValidationErrors
          :messages="
            errors[
              `settings_monthly_report.over_time_thresholds.${month}.minutes`
            ]
          "
        >
          <v-text-field
            v-model="workingHoursSetting.monthly[month].minutes"
            suffix="分"
            class="mt-0"
            density="compact"
            :min="0"
            maxlength="2"
            :error="
              errors[
                `settings_monthly_report.over_time_thresholds.${month}.minutes`
              ]?.length ?? 0 > 0
            "
          />
        </ValidationErrors>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { reactive, watch } from 'vue';
import ValidationErrors from '@/components/Common/ValidationErrors';

export default {
  props: {
    settingsMonthlyReport: Object,
    errors: Array,
    windowInnerWidth: Number,
  },
  setup(props, context) {
    const title = '労働時間上限設定';
    const workingHoursSetting = reactive({
      type: 0,
      daily: { hours: null, minutes: null },
      monthly: {},
    });
    const setting = {};
    for (let i = 1; i < 13; i++)
      setting[i.toString()] = { hours: null, minutes: null };
    workingHoursSetting.monthly = setting;

    const unwatch = watch(
      () => props.settingsMonthlyReport,
      (settings) => {
        workingHoursSetting.type = settings['over_time_thresholds_type'];

        if (workingHoursSetting.type == 0) {
          const time = { hours: null, minutes: null };
          const splitHoursAndMinutes =
            settings['over_time_thresholds']['daily']?.split(':');
          time['hours'] = Array.isArray(splitHoursAndMinutes)
            ? Number(splitHoursAndMinutes[0])
            : null;
          time['minutes'] = Array.isArray(splitHoursAndMinutes)
            ? Number(splitHoursAndMinutes[1])
            : null;

          workingHoursSetting.daily = time;
        }

        if (workingHoursSetting.type == 1) {
          const times = {};
          for (let i = 1; i < 13; i++) {
            const strI = i.toString();
            times[strI] = {};
            const splitHoursAndMinutes =
              settings['over_time_thresholds'][strI]?.split(':');
            times[strI]['hours'] = Array.isArray(splitHoursAndMinutes)
              ? Number(splitHoursAndMinutes[0])
              : null;
            times[strI]['minutes'] = Array.isArray(splitHoursAndMinutes)
              ? Number(splitHoursAndMinutes[1])
              : null;
          }
          workingHoursSetting.monthly = times;
        }

        unwatch();
      }
    );

    watch(
      () => workingHoursSetting.type,
      (typeNum) => {
        let settings = {};
        if (typeNum == 0) {
          settings = {
            daily: {
              hours: workingHoursSetting.daily.hours,
              minutes: workingHoursSetting.daily.minutes,
            },
          };
        }

        if (typeNum == 1) {
          for (let i = 1; i < 13; i++) {
            const strI = i.toString();
            settings[strI] = {
              hours: workingHoursSetting.monthly[strI].hours,
              minutes: workingHoursSetting.monthly[strI].minutes,
            };
          }
        }
        context.emit('selected', typeNum, settings);
      }
    );

    watch(
      () => workingHoursSetting.daily,
      () => {
        const emitValue = {
          daily: {
            hours: workingHoursSetting.daily.hours,
            minutes: workingHoursSetting.daily.minutes,
          },
        };

        context.emit('inputTime', 'settings_monthly_report', {
          over_time_thresholds: emitValue,
        });
      },
      { deep: true }
    );

    watch(
      () => workingHoursSetting.monthly,
      () => {
        const emitValue = {};
        for (let i = 1; i < 13; i++) {
          const strI = i.toString();
          emitValue[strI] = {
            hours: workingHoursSetting.monthly[strI].hours,
            minutes: workingHoursSetting.monthly[strI].minutes,
          };
        }
        context.emit('inputTime', 'settings_monthly_report', {
          over_time_thresholds: emitValue,
        });
      },
      { deep: true }
    );

    return { title, workingHoursSetting };
  },
  components: { ValidationErrors },
};
</script>

<style scoped>
.radio-form .radio-group-row .v-selection-control-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.working-hours-setting-form .v-label {
  font-size: 14px;
}
</style>
