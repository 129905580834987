<template>
  <div class="mb-4">
    <v-row class="select-form pl-4 pr-3">
      <v-col :cols="windowInnerWidth > 800 ? 2 : 3">
        <h6 class="pt-2 pl-4" style="font-weight: bold;">{{title}}</h6>
      </v-col>
      <v-col :cols="windowInnerWidth > 800 ? 6 : 9" class="pt-0">
        <v-select
          class="small"
          v-model="selectedLabel"
          :items="displayLabels"
          density="compact"
          :clearable="false"
        >
        </v-select>
        <div v-for="(error, index) in errors" :key="index" style="color: red; font-size: 80%;">
          {{error}}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'

  export default {
    props: {
      title: String,
      items: Array,
      selectedItem: [String, Number, Boolean],
      emitKey: String,
      errors: Array,
      windowInnerWidth: Number
    },
    setup(props, context) {
      const selectedLabel = ref('');
      const displayLabels = props.items.map((v) => { return v.label });

      const unwatch = watch(() => props.selectedItem, () => {
        selectedLabel.value = props.items.find((v) => {
          return v.key == props.selectedItem
        })?.label;

        unwatch();
      });

      watch(() => selectedLabel.value, (label) => {
          const key = props.items.find((v) => {
            return v.label == label
          })?.key;
          context.emit('selected', 'settings_monthly_report', { [props.emitKey]: key });
        }
      );

      return { displayLabels, selectedLabel }
    }
  }
</script>

<style>
</style>
