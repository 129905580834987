<template>
  <div class="d-flex justify-content-end m-3">
    <v-btn
      class="m-1 reset-btn"
      :disabled="running"
      @click="cancel"
    >
      閉じる
    </v-btn>
    <v-btn
      v-show="isUpdateAuthority"
      class="m-1 exec-btn"
      @click="submit"
      :loading="running"
    >
      保存
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    items: [],
    hasError: {
      type: Boolean,
      default: false,
    },
    running: {
      type: Boolean,
      default: false,
    },
    isUpdateAuthority: {
      type: Boolean,
    }
  },
  data() {
    return {
    }
  },
  methods: {
    submit() {
      if (!this.hasError) {
        this.$emit('submit', this.items)
      }
    },
    cancel() {
      this.$emit('cancel')
    },
  },
  components: {
  },
}
</script>

<style>
.v-btn--disabled.v-btn--variant-contained {
  color:inherit;
  box-shadow: inherit;
  background: rgb(21, 130, 156) !important;
}
</style>
