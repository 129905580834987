

import {defineComponent, PropType} from "vue";
import {calculatePageCount} from "@/services/functions";

export default defineComponent({
  setup() {
    return {}
  },
  data() {
    return {
      selectedDeviceType: null as number | null,
    }
  },
  props: {
    itemsPerPage: {
      type: Number as PropType<number>,
      required: true
    },
    currentPage: {
      type: Number as PropType<number>,
      required: true
    },
    allElementsCount: {
      type: Number as PropType<number>,
      required: true
    },
  },
  mounted() {
    //
  },
  name: "TableItemCount",
  computed: {
    itemStartIndex(): number {
      return (this.currentPage-1) * this.itemsPerPage + 1
    },
    itemEndIndex(): number {
      return this.isLastPage ? this.allElementsCount : this.currentPage * this.itemsPerPage
    },
    isLastPage(): boolean {
      return this.currentPage == calculatePageCount(this.allElementsCount, this.itemsPerPage)
    },
  },
  watch: {
  },
  methods: {},
  components: {},
})
