/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://rla7eohuivewnodtg6x4hgj3je.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fv2tabbzkjbmngcwxb7pwsp7pm",
    "API": {
        "GraphQL": {
            "endpoint": "https://rla7eohuivewnodtg6x4hgj3je.appsync-api.ap-northeast-1.amazonaws.com/graphql",
            "region": "ap-northeast-1",
            "defaultAuthMode": "apiKey",
            "apiKey": "da2-oydtqism75fzthhjchw7nlnim4"
        }
    }
};


export default awsmobile;
