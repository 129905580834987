
import { defineComponent, ref } from "vue";
import axios from "@/services/ApiConnection";
import AppSyncClient from '@/services/subscribe/providers/Connection'
import { useRouter } from "vue-router";
import { useAuth } from "@/services/user/Auth0UserProvider";
import vLoaderAnimation from "@/components/template/v-loader-animation.vue";
import Api from "@/services/api/ApiServiceFabrick";
import AuthorityCacheApi from "@/services/models/AuthorityCacheApi";

export default defineComponent({
  name: "App",
  setup() {
    const { initializeAuth } = useAuth();
    const ready = ref(false);
    const { push } = useRouter();
    let showPageAfterRedirect = true;

    initializeAuth(
      {
        domain: process.env.VUE_APP_AUTH0_DOMAIN ?? '',
        client_id: process.env.VUE_APP_AUTH0_CLIENTID ?? '',
        audience: process.env.VUE_APP_AUTH0_AUDIENCE ?? '',
        useRefreshTokens: true,
      },
      (appState) => {
        if (appState && appState.targetUrl) {
          push(appState.targetUrl).then(() => (ready.value = true));
        }
      },
      () => (showPageAfterRedirect = false)
    ).then(async () => {
      const userInfo = useAuth();
      if (userInfo.isAuthenticated.value) {
        // tokenを取得
        const token = await userInfo.getTokenSilently();
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        AppSyncClient.token = token

        Api()
          .getAuthority()
          .then((data) => {
            AuthorityCacheApi.put(data);
          })
          .catch((error) => {
            AuthorityCacheApi.put({});
            showPageAfterRedirect = true;
            ready.value = true;
            switch (error.response.status) {
              case 401:
              case 403:
                push("/forbidden");
                break;
            }
          })
          .finally(async () => {
            if (!showPageAfterRedirect) {
              // add account in cache here
              // to prevent showing white screen
              // after loading animation
              await Api().getSelfAccount();
              ready.value = true;
            }
          });
      } else {
        ready.value = true;
      }
    });

    return {
      ready,
    };
  },
  components: {
    vLoaderAnimation,
  },
});
