<template>
  <div class="mb-4">
    <v-row class="select-form pl-4 pr-3">
      <v-col :cols="windowInnerWidth > 800 ? 2 : 3">
        <h6 class="pt-2 pl-4" style="font-weight: bold">{{ title }}</h6>
      </v-col>
      <v-col class="d-flex pt-0 pr-4">
        <v-text-field
          v-model="firstImprinterName"
          class="mt-0 mr-1"
          density="compact"
        />
        <v-text-field
          v-model="secondImprinterName"
          class="mt-0 mr-1"
          density="compact"
        />
        <v-text-field
          v-model="thirdImprinterName"
          class="mt-0"
          density="compact"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    imprinterNames: Array,
    windowInnerWidth: Number,
  },
  setup(props, context) {
    const title = '押印者名';
    const firstImprinterName = ref('');
    const secondImprinterName = ref('');
    const thirdImprinterName = ref('');

    const unwatch = watch(
      () => props.imprinterNames,
      () => {
        firstImprinterName.value = props.imprinterNames[0];
        secondImprinterName.value = props.imprinterNames[1];
        thirdImprinterName.value = props.imprinterNames[2];

        unwatch();
      }
    );

    const emitImprinterName = (imprinterNames) => {
      context.emit('inputImprinterName', { imprinter_name: imprinterNames });
    };

    watch(
      () => firstImprinterName.value,
      (value) => {
        const imprinterNames = [
          value,
          secondImprinterName.value,
          thirdImprinterName.value,
        ];
        emitImprinterName(imprinterNames);
      }
    );

    watch(
      () => secondImprinterName.value,
      (value) => {
        const imprinterNames = [
          firstImprinterName.value,
          value,
          thirdImprinterName.value,
        ];
        emitImprinterName(imprinterNames);
      }
    );

    watch(
      () => thirdImprinterName.value,
      (value) => {
        const imprinterNames = [
          firstImprinterName.value,
          secondImprinterName.value,
          value,
        ];
        emitImprinterName(imprinterNames);
      }
    );

    return {
      title,
      firstImprinterName,
      secondImprinterName,
      thirdImprinterName,
    };
  },
};
</script>

<style>
.select-form .v-label {
  display: none;
}
.select-form .v-field__field {
  padding-top: 7px;
}
</style>
