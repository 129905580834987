<template>
  <div class="monthly-report-output-layout pt-3 pb-3">
    <v-row class="mb-1">
      <v-col>
        <v-select
          class="small"
          v-model="selectedReportType"
          :items="displayReportTypes"
          label="帳票"
          :clearable="false"
        >
        </v-select>
      </v-col>
      <v-col>
        <v-text-field
          v-model="monthlyScore"
          :label="monthlyScoreLabel"
          :prefix="highlightSettingsPrefix"
          :suffix="highlightSettingsSufix"
          type="number"
          clearable
          class="small"
        >
        </v-text-field>
      </v-col>
      <v-col v-show="showableDailyhighlightSettings">
        <v-text-field
          v-model="dailyScore"
          :label="dailyScoreLabel"
          :prefix="highlightSettingsPrefix"
          :suffix="highlightSettingsSufix"
          type="number"
          clearable
          class="small"
        >
        </v-text-field>
      </v-col>
      <v-col v-show="showableSortOrder">
        <v-select
          class="small"
          v-model="selectedSortOrder"
          :items="displaySortOrders"
          label="ドライバー並び順"
        >
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      outputSettings: {},
      displayReportTypes: [],
      displaySortOrders: [],
      showableSortOrder: false,
      showableDailyhighlightSettings: true,
      reportTypes: [
        {
          key: 'report1',
          label: '安全運転実績',
          data: 'SafeDrivingActualResult',
        },
        {
          key: 'report2',
          label: '安全運転レポート',
          data: 'SafeDrivingReport',
        },
        { key: 'report3', label: '燃費', data: 'FuelEconomy' },
        { key: 'report4', label: '運転スコア', data: 'DrivingScore' },
      ],
      highlightSettings: {
        SafeDrivingActualResult: {
          monthlyLabel: '強調設定 月次運転スコア',
          dailyLabel: '強調設定 日次運転スコア',
          prefix: '強調点数',
          sufix: '以下',
        },
        SafeDrivingReport: {
          monthlyLabel: '強調設定 月次運転スコア',
          dailyLabel: '',
          prefix: '強調点数',
          sufix: '以下',
        },
        FuelEconomy: {
          monthlyLabel: '強調設定 月次燃費',
          dailyLabel: '強調設定 日次燃費',
          prefix: '燃費',
          sufix: 'km/L以下',
        },
        DrivingScore: {
          monthlyLabel: '強調設定 月次運転スコア',
          dailyLabel: '強調設定 日次運転スコア',
          prefix: '運転スコア',
          sufix: '点以下',
        },
      },
      sortOrderLists: {
        FuelEconomy: [
          { key: 'sort1', label: '登録日', data: 'created_at_desc' },
          { key: 'sort2', label: '燃費上位順', data: 'score_desc' },
          { key: 'sort3', label: '燃費下位順', data: 'score_asc' },
        ],
        DrivingScore: [
          { key: 'sort1', label: '登録日', data: 'created_at_desc' },
          { key: 'sort2', label: 'スコア上位順', data: 'score_desc' },
          { key: 'sort3', label: 'スコア下位順', data: 'score_asc' },
        ],
      },
      selectedReportType: null,
      selectedSortOrder: null,
      monthlyScore: 0,
      dailyScore: 0,
      monthlyScoreLabel: null,
      dailyScoreLabel: null,
      highlightSettingsPrefix: null,
      highlightSettingsSufix: null,
    };
  },
  created() {
    const reportType = this.reportTypes[0].data;
    this.selectedReportType = this.reportTypes[0].label;

    this.setDisplayReportTypes();
    this.setMonthlyAndDailyLabel(reportType);
  },
  methods: {
    makeReportTypeName(reportType) {
      return (
        'App\\Services\\OutputReports\\Monthly\\Drivers\\' +
        reportType +
        '\\PdfCreateService'
      );
    },
    setDisplayReportTypes() {
      this.displayReportTypes = this.reportTypes.map((v) => {
        return v.label;
      });
    },
    setDisplaySortOrders(sortOrderList) {
      this.displaySortOrders = sortOrderList
        ? sortOrderList.map((v) => {
            return v.label;
          })
        : [];
    },
    setMonthlyAndDailyLabel(reportType) {
      const highlightSetting = this.highlightSettings[reportType];
      this.monthlyScoreLabel = highlightSetting.monthlyLabel;
      this.dailyScoreLabel = highlightSetting.dailyLabel;
      this.highlightSettingsPrefix = highlightSetting.prefix;
      this.highlightSettingsSufix = highlightSetting.sufix;
    },
    changeOutputSettings() {
      this.$emit('change', this.outputSettings);
    },
  },
  watch: {
    selectedReportType: {
      handler() {
        // reset report type
        const selectedReportType = this.reportTypes.find(
          (v) => v.label == this.selectedReportType
        );
        this.outputSettings.type = this.makeReportTypeName(
          selectedReportType.data
        );

        // reset sort order
        const sortOrderList = this.sortOrderLists[selectedReportType.data];
        if (sortOrderList) {
          this.selectedSortOrder = sortOrderList[0].label;
          this.outputSettings.sort = sortOrderList[0].data;
          this.showableSortOrder = true;
        } else {
          this.selectedSortOrder = null;
          this.outputSettings.sort = null;
          this.showableSortOrder = false;
        }
        this.setDisplaySortOrders(sortOrderList);

        // set 0 for monthly and daily score
        this.monthlyScore = 0;
        this.outputSettings.require_monthly_score = 0;
        this.dailyScore = 0;
        this.outputSettings.require_daily_score = 0;

        // reset monthly and daily label
        this.setMonthlyAndDailyLabel(selectedReportType.data);
        this.showableDailyhighlightSettings =
          selectedReportType.data != this.reportTypes[1]['data'];

        this.changeOutputSettings();
      },
    },
    monthlyScore: {
      handler(value) {
        if (value == '') value = 0;
        this.outputSettings.require_monthly_score = value;
        this.changeOutputSettings();
      },
    },
    dailyScore: {
      handler(value) {
        if (value == '') value = 0;
        this.outputSettings.require_daily_score = value;
        this.changeOutputSettings();
      },
    },
    selectedSortOrder: {
      handler(value, preValue) {
        if (!value || !preValue) return;

        const selectedReportType = this.reportTypes.find(
          (v) => v.label == this.selectedReportType
        ).data;
        const sortOrderList = this.sortOrderLists[selectedReportType].find(
          (v) => v.label == value
        );
        this.outputSettings.sort = sortOrderList.data;

        this.changeOutputSettings();
      },
    },
  },
};
</script>