<template>
  <div class="d-flex" style="min-height: 45vw;">
    <div class="content p-4 w-100">
      <div class="d-flex">
        <h4 class="card-title mt-0">{{ title }}</h4>
        <div class="ml-auto ml-2 mb-1 align-self-end">
          <ManualLink :pageName="pageName" />
        </div>
      </div>
      <div class="row">
        <div class="col-3 mt-2">
          <v-select
            class="small mt-2"
            v-model="form.format"
            :items="formats"
            label="フォーマット"
            :clearable="false"
          >
          </v-select>
        </div>
        <div
          class="col-3 mt-2"
        >
          <SingleOperationOfficeAutoComplete
            v-model="form.operationOffice"
            class="mt-2"
            :selectFirstIfOnlyOne="true"
            :clearable="false"
            :error-messages="errors.operationOffice"
          />
        </div>
        <div class="col-4">
          <span class="small">期間</span>
              <Datepicker
                locale="jp"
                cancel-text="キャンセル"
                select-text="選択"
                v-model="form.selectDateRange"
                format="yyyy/MM/dd"
                range
                auto-apply
                :close-on-auto-apply="false"
                :max-range="30"
                :clearable="false"
              />
        </div>
      </div>
      <div class="d-flex justify-content-end mt-3">
        <v-btn
          class="exec-btn"
          @click="handleClickOutputPdf"
          :style="{
            backgroundColor : downloading ? '#B3B8BB' : ''
          }"
        >
        {{downloadBtnLabel}}
        </v-btn>
      </div>
    </div>
  </div>
  <GDialog
    v-model="dialogState"
    max-width="30%"
    transition="custom-from-bottom-transition"
  >
    <div class="modal-header justify-content-center pt-3">
      <label>受付完了</label>
    </div>
    <div class="row mt-2 mr-3 ml-3 pl-5 v-theme--light">
      ダウンロードを受け付けました。<br />
      出力完了後メールでお知らせします。
    </div>
    <div class="d-flex justify-content-end m-3">
      <v-btn class="m-1 reset-btn" @click="closeModal">
        閉じる
      </v-btn>
    </div>
  </GDialog>
</template>

<script>
  import Api from "@/services/api/ApiServiceFabrick";
  import Datepicker from '@vuepic/vue-datepicker'
  import { GDialog } from 'gitart-vue-dialog'
  import { downloadDataFormats, DEPARTMENT_OFFICE_TYPE } from '@/services/consts'
  import { formatDate } from '@/services/functions';
  import ManualLink from "@/components/ManualLink"
  import selectRoleChecker from "@/router/selectRoleChecker";
  import SingleOperationOfficeAutoComplete from '@/components/AutoCompletes/SingleOperationOfficeAutoComplete.vue';

  export default {
    data() {
      return {
        title: "データ出力",
        pageName: "downloadData",
        form: {
          format: "logi_attendance",
          operationOffice: null,
          selectDateRange: [new Date(), new Date()],
        },
        formats: downloadDataFormats,
        account: null,
        dialogState: false,
        downloading: false,
        errors: {},
      }
    },
    mounted() {
      document.title = this.title;
      this.form.selectYearMonth = this.getThisYearMoth()
      this.getAccount()
    },
    computed: {
      downloadBtnLabel() {
        return this.downloading ? "ダウンロード中..." : "ダウンロード"
      }
    },
    methods: {
      handleClickOutputPdf() {
        if (!this.isValid()) return
        this.downloading = true
        const params = this.makeCreateFileRequest()
        Api().createJobStatus(params).then((data) => {
          this.dialogState = true
          this.downloading = false
        }).catch((error) => {
          const res = error.response
          switch (res.status) {
            case 401:
              this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
              break
            case 403:
              location.reload()
              break
            case 422:
          }
          this.downloading = false
        })
      },
      makeCreateFileRequest() {
        const type = this.getType(this.form?.format);

        const startDate = this.form.selectDateRange[0];
        const endDate = this.form.selectDateRange[1] ?? new Date(startDate.getTime());
        const operationOfficeId = this.setOperationOfficeId()

        const params = {
          type: type,
          operation_office_id: operationOfficeId,
          format: this.form.format,
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
        }
        return params
      },
      setOperationOfficeId() {
        if (this.account.login_department_type == DEPARTMENT_OFFICE_TYPE) {
          const role = selectRoleChecker.getCurrentRole();
          return role?.organization?.operation_office_id;
        }

        return this.form.operationOffice?.id
      },
      getType(format) {
        for(let i = 0; i < downloadDataFormats.length; i++) {
          if (downloadDataFormats[i].value == format) return downloadDataFormats[i].request_type;
        }
      },
      getThisYearMoth() {
        const today = new Date()
        return { year: today.getFullYear(), month: today.getMonth() }
      },
      getAccount() {
        Api().getSelfAccount().then((data) => {
          this.account = data
        })
      },
      closeModal() {
        this.dialogState = false
      },
      isValid() {
        this.errors = {}
        if (!this.form.operationOffice) {
          this.errors.operationOffice = this.$t("pages.downloadData.messages.operationOfficeRequired")
          return false
        }
        return true
      }
    },
    components: {
      Datepicker,
      GDialog,
      ManualLink,
      SingleOperationOfficeAutoComplete,
    }
  }
</script>

<style>
.dp__button_bottom {
  display: none;
}
</style>
