<template>
  <card class="card-stats border-dark pl-3 pr-3">
    <template v-slot:header>
      <div class="text-left">
        <slot name="header"></slot>
      </div>
    </template>
    <div class="border-bottom" style="height: 100%;">
      <div class="d-flex status-card-body">
        <slot name="icon" />
        <slot name="content"></slot>
      </div>
    </div>
    <template v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </card>
</template>

<script>
  import Card from './StsCard.vue'
  export default {
    name: 'stats-card',
    components: {
      Card
    }
  }

</script>

<style>
.status-card-body {
  position: absolute;
  height: fit-content;
  top: 50%;
  transform: translate(0, -50%);
}
.status-card-body>* {
  margin: 1rem;
  height: fit-content;
}
.card-stats .card-body {
  height: 185px;
  display: block !important;
}
.card {
  margin-bottom: inherit !important;
}
.card .card-footer {
  padding-top: 10px !important;
}
.card-stats .card-body {
  position: relative;
}

</style>
