import i18n from '@/lang/i18n';

// statuses
export const CVR_UPLOAD_DEFAULT_STATUS = 'ready';
export const CVR_UPLOAD_READY = 'ready';
export const CVR_UPLOAD_UPLOADING = 'uploading';
export const CVR_UPLOAD_SUBMITTED = 'submitted';
export const CVR_UPLOAD_PROGRESSING = 'progressing';
export const CVR_UPLOAD_UNREADY = 'unready';
export const CVR_UPLOAD_ACCEPTED = 'accepted';
export const CVR_UPLOAD_PROCESSING = 'processing';
export const CVR_UPLOAD_FINALIZED = 'finalized'; // parent status
// finished statuses
export const CVR_UPLOAD_ERROR = 'error';
export const CVR_UPLOAD_REJECTED = 'rejected';
export const CVR_UPLOAD_COMPLETE = 'complete';
export const CVR_UPLOAD_NO_INPUT_SKIPPED = 'no_input_skipped';
export const CVR_UPLOAD_REMOVED = 'removed';
export const CVR_UPLOAD_CANCELED = 'canceled';
export const CVR_UPLOAD_DISCONTINUED = 'discontinued';
export const CVR_UPLOAD_ABORTED = 'aborted';
export const CVR_UPLOAD_FAILED = 'failed';

export const cvrUploadsStatuses = {
  [CVR_UPLOAD_READY]: {
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_READY}`),
    class: "bg-info"
  },
  [CVR_UPLOAD_UPLOADING]: {
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_UPLOADING}`),
    class: "bg-info"
  },
  [CVR_UPLOAD_SUBMITTED]: {
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_SUBMITTED}`),
    class: "bg-info"
  },
  [CVR_UPLOAD_PROGRESSING]: {
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_PROGRESSING}`),
    class: "bg-info"
  },
  [CVR_UPLOAD_COMPLETE]: {
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_COMPLETE}`),
    class: "bg-success"
  },
  [CVR_UPLOAD_ERROR]: {
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_ERROR}`),
    class: "bg-danger text-white"
  },
  [CVR_UPLOAD_CANCELED]: {
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_CANCELED}`),
    class: "bg-warning"
  },
  [CVR_UPLOAD_DISCONTINUED]: {
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_DISCONTINUED}`),
    class: "bg-warning"
  },
  [CVR_UPLOAD_NO_INPUT_SKIPPED]: {
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_NO_INPUT_SKIPPED}`),
    class: "bg-warning"
  },

  [CVR_UPLOAD_UNREADY]: { // デバイス ON/OFF を確認できていない状態 *デフォルト状態
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_UNREADY}`),
    class: "bg-info"
  },
  [CVR_UPLOAD_ACCEPTED]:  { // デバイスがアップロードのリクエストを受け付けた状態
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_ACCEPTED}`),
    class: "bg-info"
  },
  [CVR_UPLOAD_PROCESSING]:  { // デバイスがアップロードしたメディアをサーバーで変換中の状態 *新規追加
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_PROCESSING}`),
    class: "bg-info"
  },
  [CVR_UPLOAD_ABORTED]: { // デバイス OFF を確認できた状態 *新規追加
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_ABORTED}`),
    class: "bg-warning"
  },
  [CVR_UPLOAD_FAILED]: { // サーバー側の処理中に何らかのエラーが発生した状態 *新規追加
    label: i18n.global.t(`pages.cvr_uploads.statuses.${CVR_UPLOAD_FAILED}`),
    class: "bg-danger text-white"
  },
};

export const CVR_UPLOAD_READY_STATUSES = [
  CVR_UPLOAD_READY,
  CVR_UPLOAD_UPLOADING,
  CVR_UPLOAD_SUBMITTED,
  CVR_UPLOAD_PROGRESSING,
  CVR_UPLOAD_UNREADY,
  CVR_UPLOAD_ACCEPTED,
  CVR_UPLOAD_PROCESSING,
];

export const CVR_UPLOAD_CANCELABLE_STATUSES = [
  CVR_UPLOAD_ERROR,
  CVR_UPLOAD_REJECTED,
  CVR_UPLOAD_COMPLETE,
  CVR_UPLOAD_NO_INPUT_SKIPPED,
  CVR_UPLOAD_REMOVED,
  CVR_UPLOAD_CANCELED,
  CVR_UPLOAD_DISCONTINUED,
  CVR_UPLOAD_ABORTED,
  CVR_UPLOAD_FAILED,
];

export const CVR_UPLOAD_COMPLETE_STATUSES = [
  CVR_UPLOAD_COMPLETE,
  CVR_UPLOAD_CANCELED,
  CVR_UPLOAD_DISCONTINUED,
  CVR_UPLOAD_NO_INPUT_SKIPPED
];

export const CVR_UPLOAD_REQUEST_SELECT_RANGE = [
  '1分間',
  '2分間',
  '3分間'
];

export const CAMERA1_TYPE = 'F';
export const CAMERA2_TYPE = 'R';
export const CAMERA3_TYPE = 'I';
export const CVR_UPLOAD_REQUEST_CAMERA_TYPES = {
  [CAMERA1_TYPE]: i18n.global.t('pages.cvr_uploads.cameraName.front'),
  [CAMERA2_TYPE]: i18n.global.t('pages.cvr_uploads.cameraName.rear'),
  [CAMERA3_TYPE]: i18n.global.t('pages.cvr_uploads.cameraName.in'),
};
export const CVR_UPLOAD_REQUEST_SELECTABLE_CAMERA = {
  "camera_on": CAMERA1_TYPE,
  "camr_on": CAMERA2_TYPE,
  "cami_on": CAMERA3_TYPE,
};
export const CVR_UPLOAD_REQUEST_CHECK_INI_FILE_CAMERA_COLUMN = [
  "camera_on",
  "cami_on",
  "camr_on"
];
export const CVR_UPLOAD_REQUEST_ACTIVE_CAMERA_STATUS = "ON";
export const CVR_UPLOAD_REQUEST_INACTIVE_CAMERA_STATUS = "OFF";

export const CVR_REQUEST_TYPE = 'request'
export const CVR_UPLOAD_TYPE = 'upload'
