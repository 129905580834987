<template>
  <table class="w-100 delivery-plan-works-table">
    <thead>
      <tr>
        <th>No</th>
        <th>作業</th>
        <th>住所・拠点</th>
        <th>着時刻</th>
        <th>発時刻</th>
        <th>作業時間</th>
        <th>走行時間</th>
        <th>走行距離</th>
        <th>積載/給油</th>
        <th>編集</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(item, index) in items" :key="'work-' + index" >
        <template v-if="item.editing">
          <tr class="tr-nohover">
            <td>{{index + 1}}</td>
            <template v-if="item.work != 'returning_warehouse'">
              <td>
                <div class='d-flex flex-column '>
                  <select class="border work-table-input-box" v-model="item.work">
                    <template v-for="(value, name) in selectWorkEventTypes" :key="'work_type_' + value">
                      <option :value="name">{{value}}</option>
                    </template>
                  </select>
                  <small v-for="(message, i) in errors?.work ?? []" class="text-danger" :key="'error-work-'+i">{{message}}</small>
                </div>
              </td>
            </template>
            <template v-else>
              <td>{{selectWorkEventTypes[item.work]}}</td>
            </template>
            <td>
              <div class='d-flex flex-column'>
                <input class="border work-table-input-box" v-model="item.spot_name" />
                <small v-for="(message, i) in errors?.spot_name ?? []" class="text-danger" :key="'error-work-'+i">{{message}}</small>
              </div>
            </td>
            <td>
              <Datepicker
                class=""
                :clearable="false"
                v-model="item.start_time"
                format="yyyy/MM/dd HH:mm:ss"
                :enableSeconds="true"
                :flow="['calendar','time', 'seconds']"
              />
              <small v-for="(message, i) in errors?.start_time ?? []" class="text-danger" :key="'error-work-'+i">{{message}}</small>
            </td>
            <template v-if="item.work != 'returning_warehouse'">
              <td>
                <Datepicker
                  class=""
                  :clearable="false"
                  v-model="item.end_time"
                  format="yyyy/MM/dd HH:mm:ss"
                  :enableSeconds="true"
                  :flow="['calendar','time', 'seconds']"
                />
                <small v-for="(message, i) in errors?.end_time ?? []" class="text-danger" :key="'error-work-'+i">{{message}}</small>
              </td>
            </template>
            <template v-else>
              <td></td>
            </template>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <button
                class="update-dailyreport-botton save-button"
                @click="saveRow(item,item)"
              >
                <span class="material-symbols-outlined">
                  save
                </span>
              </button>
              <button
                class="update-dailyreport-botton delete-button"
                v-if="item.work != 'leaving_warehouse' && item.adding"
                @click="removeRow(item)"
              >
                <span class="material-symbols-outlined">
                  delete
                </span>
              </button>
            </td>
          </tr>
          <tr
            v-if="item.work == 'returning_warehouse'"
          >
            <td></td>
            <td></td>
            <td></td>
            <td colspan=2>
              <label
                class="text-black-75"
              >
                選択可能な範囲 {{item.before_end}} ~ {{item.after_start}}
              </label>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td>{{index + 1}}</td>
            <td>{{workEventTypes[item.work]}}{{item.edited_at ? '*' : ''}}</td>
            <td>{{item.spot_name}}</td>
            <td>{{viewLeavingTime(item)}}</td>
            <td>{{viewReturningTime(item)}}</td>
            <td>{{viewWorkingTime(item)}}</td>
            <td>{{viewDrivingTime(item)}}</td>
            <td>{{viewMileage(item)}}</td>
            <td>
              <WorkloadUpdateModal
                v-if="!editing && (item.work == 'loading' || item.work == 'unloading'  || item.work == 'refueling')"
                :workItem="item"
                :operationOfficeId="this.operationOfficeId"
                :isEdit="this.isEdit"
                :updateAuthority="updateAuthority"
                @setWorkId="(workId) => item.work_id = workId"
                @setRefuelingAmount="setRefuelingAmount"
              />
            </td>
            <td style="width: 70px">
              <button
                class="update-dailyreport-botton edit-button"
                v-if="isDisplayEditButton(item)"
                @click="startEdit(item)"
              >
                <span class="material-symbols-outlined">
                  edit
                </span>
              </button>
              <button
                class="update-dailyreport-botton delete-button"
                v-if="isDisplayDeleteButton(item)"
                @click="removeRow(item)"
              >
                <span class="material-symbols-outlined">
                  delete
                </span>
              </button>
            </td>
            <td>
              <div
                v-if="item.allocated"
                class="allocated-text"
              >
                自動割り当て
              </div>
            </td>
          </tr>
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
import Api from "@/services/api/ApiServiceFabrick";
import Geocoder from '@/services/models/Gercoder';
import GoogleMapsApiLoader from 'google-maps-api-loader';
import DeliveryPlanWork from '@/services/models/DeliveryPlanWork'
import DeliveryPlanEvent from '@/services/models/DeliveryPlanEvent'
import DeliveryPlanDrivingStoppingPeriod from '@/services/models/DeliveryPlanDrivingStoppingPeriod'
import Workload from '@/services/models/Workload'
import Datepicker from '@vuepic/vue-datepicker'
import {
  round,
  dateToDayAndTime,
  addSecondsToDateString,
} from "@/services/functions";
import { useAuth } from "@/services/user/Auth0UserProvider"
import WorkloadUpdateModal from "@/components/DailyReportUpdateModalParts/WorkloadUpdateModal";
import { 
  WORK_EVENT_TYPES,
  SELECT_WORK_EVENT_TYPES
} from "@/services/consts";

export default {
  setup() {
    const { loginWithRedirect } = useAuth();

    return {
      round,
      dateToDayAndTime,
      loginWithRedirect
    }
  },
  props: {
    deliveryPlan: {},
    modelValue: {},
    dialogState: {
      type: Boolean,
      default: false,
    },
    operationOfficeId: null,
    updateAuthority: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      items: [],
      stoppingPeriods: [],
      selectWorkEventTypes: SELECT_WORK_EVENT_TYPES,
      workEventTypes: WORK_EVENT_TYPES,
      editing: false,
      errors: {},
      hasError: false,
      geocoder: null,
      addItems: {},
      deleteItems: [],
      isEdit: false, // 積載量／給油量編集モーダル用フラグ
    }
  },
  mounted() {
    this.getWorks()
  },
  methods: {
    roundForView(num) {
      return round(num,2).toFixed(2)
    },
    viewReturningTime(item) {
      if (item.work == 'returning_warehouse')  return ''
      if (!item.end_time) return ''
      return dateToDayAndTime(new Date(item.end_time))
    },
    viewLeavingTime(item) {
      if (item.work == 'leaving_warehouse')  return ''
      if (!item.start_time) return ''
      return dateToDayAndTime(new Date(item.start_time))
    },
    viewWorkingTime(item) {
      if (item.work == 'leaving_warehouse')  return ''
      return item.working_time
    },
    viewDrivingTime(item) {
      if (item.work == 'leaving_warehouse') return ''
      return item.driving_time
    },
    viewMileage(item) {
      if (item.work == 'leaving_warehouse') return ''
      if (!item.mileage) return '0.00'
      return this.roundForView(item.mileage)
    },
    viewLoading(item) {
      if (item.work == 'loading') return '○'
      return ''
    },
    startEdit(item) {
      this.editing = true
      item.editing = true
      if(item.work == 'stopping'){
        item.adding = true
      }
      // バリデーション用に編集前の値を一時保存
      this.beforeEditingItem = structuredClone(item)
    },
    initialItem(item) {
      return {
        delivery_plan_id: item.delivery_plan_id,
        work: null,
        start_time: null,
        end_time: null,
        working_time: null,
        driving_time: null,
        latitude: null,
        longitude: null,
        spot_id: null,
        spot_name: null,
        mileage: null,
        get_spot_name_from_api: null,
        start_id: null,
        end_id: null,
        edited_at: null,
        added_at: null,
        editing: false,
        adding: false,
      }
    },
    addNextRow(item) {
      this.editing = true
      const index = this.items.indexOf(item)
      const nextItem = this.initialItem(item)
      nextItem.editing = true
      nextItem.adding = true
      const defaultTime = addSecondsToDateString(item.end_time ?? '', 1)
      nextItem.start_time = defaultTime
      nextItem.end_time = defaultTime
      this.items.splice(index + 1,0, nextItem)
    },
    saveRow(item, row) {
      if(!this.validateRow(row)) {
        item = row
        item.edited_at = new Date().toLocaleTimeString()
        item.editing = false
        if(item.works[0] && item.works[1]){
          Workload.generateWorkId(item.delivery_plan_id, item.work, true, item.start_time)
          .then((digestHex) => {
            item.works[0].work_id = digestHex
          });
          Workload.generateWorkId(item.delivery_plan_id, item.work, false, item.end_time)
          .then((digestHex) => {
            item.works[1].work_id = digestHex
          });
        }
        this.editing = false
        this.isEdit = true
        this.searchAgain()
      }
    },
    removeRow(item) {
      this.clearErrors()
      const index = this.items.indexOf(item)
      item.adding = false
      if(item.work == 'stopping'){
        this.deleteItems.push({
          start_time: item.start_time,
          end_time: item.end_time,
        })
      }
      this.items.splice(index, 1)
      this.editing = false
    },
    getWorks() {
      this.editing = false
      this.editClear()
      Api().getDeliveryPlanWorks(this.deliveryPlan?.delivery_plan_id).then((data) => {
        this.items = DeliveryPlanWork.convertWotkToEvent(data)
      }).catch((error) => {
        const res = error.response
        switch (res.status) {
          case 401:
            this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
            break
          case 403:
            location.reload()
            break
          case 422:
        }
      })
    },
    search(operationOfficeId, minute){
      this.minute = minute
      // 検索結果をクリア
      this.clear()
      this.deleteItems = []
      // 検索対象は存在するか
      Api().getStoppingPeriodsWithSpot(this.deliveryPlan?.delivery_plan_id, operationOfficeId).then((data) => {
        this.stoppingPeriods = DeliveryPlanDrivingStoppingPeriod.filterStoppingStatus(data)
        // ミリ秒切り捨て
        this.stoppingPeriods = DeliveryPlanDrivingStoppingPeriod.roundStoppingPeriods(this.stoppingPeriods)
        // 作業が入っていない空の区間を生成
        let emptyPeriods = DeliveryPlanDrivingStoppingPeriod.getEmptyPeriodsFromEvent(this.items)
        // 空の区間と停止区間が一致しているか
        let periods = DeliveryPlanDrivingStoppingPeriod.getOverlappingRange(minute, this.stoppingPeriods, emptyPeriods)
        this.addItems = DeliveryPlanDrivingStoppingPeriod.convertStoppingPeriodsToDeliveryPlanWork(periods)
        this.$emit("changeSearchMessage", this.addItems.length / 2 + "件ヒット")
      }).catch((error) => {
        const res = error.response
        switch (res.status) {
          case 401:
            this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
            break
          case 403:
            location.reload()
            break
          case 422:
            this.$emit("changeSearchErrorMessage", "検索に失敗しました")
        }
      })
    },
    searchAgain(){
      if(!this.minute) return;
      this.clear()
      // 作業が入っていない空の区間を生成
      let emptyPeriods = DeliveryPlanDrivingStoppingPeriod.getEmptyPeriodsFromEvent(this.items)
      // 空の区間と停止区間が一致しているか
      let periods = DeliveryPlanDrivingStoppingPeriod.getOverlappingRange(this.minute, this.stoppingPeriods, emptyPeriods)
      this.addItems = DeliveryPlanDrivingStoppingPeriod.convertStoppingPeriodsToDeliveryPlanWork(periods)
      this.$emit("changeSearchMessage", this.addItems.length / 2 + "件ヒット")
    },
    clear(){
      // 以前表示されていたものは削除
      let new_items = this.items.filter((item) => {
        return item.work != "stopping"
      });
      this.items = new_items
    },
    editClear(){
      this.isEdit = false
    },
    validateRow(item) {
      this.hasError = false
      const errors = DeliveryPlanEvent.validate(item, this.beforeEditingItem, this.stoppingPeriods)
      if (errors) {
        this.hasError = true
      }
      this.errors = errors ?? {}
      return this.hasError
    },
    clearErrors() {
      this.errors = {}
      this.hasError = false
    },
    clearSearchItems() {
      this.minute = null
    },
    async getAddress(){
      if(!this.geocoder){
        // <GMapMap></GMapMap>を使用しないので別ライブラリからgoogleオブジェクトを呼び出す必要あり
        let google = await GoogleMapsApiLoader({
          apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
        });
        this.geocoder = new Geocoder();
      }

      this.items.map((item) => {
        if(item.spot_name == null && item.work == "stopping" && item.latitude && item.longitude){
          let latlng = {
            lat: Number(item.latitude),
            lng: Number(item.longitude)
          }
          this.geocoder.geocode("", latlng).then((position) => {
            item.spot_name = this.removePostingCode(position.address)
            item.get_spot_name_from_api = true
          }).catch((err) => {
            console.log(err)
          });
        }
      })
    },
    removePostingCode(address){
      let removedAddress = address
      if(address.includes('〒')){
        removedAddress = address.substring(10)
      }
      return removedAddress
    },
    setRefuelingAmount(){
      this.$emit("setRefuelingAmount")
    },
    isDisplayEditButton(item) {
      return item.work != 'leaving_warehouse' && !this.editing && this.updateAuthority
    },
    isDisplayDeleteButton(item) {
      return item.work != 'leaving_warehouse' && !this.editing && (item.adding || item.allocated) && this.updateAuthority
    },
  },
  components: {
    Datepicker,
    WorkloadUpdateModal,
  },
  watch: {
    dialogState: {
      handler() {
        if(this.dialogState) {
          this.getWorks()
        } else {
          this.items = []
          this.$emit("clearModal")
        }
      },
    },
    items: {
      handler() {
        DeliveryPlanWork.setWorkingAndDrivingTime(this.items)
        DeliveryPlanWork.setBeforeAndAfterTimes(this.deliveryPlan, this.items)
        this.$emit('update:modelValue', this.items)
      },
      deep: true,
    },
    addItems: {
      handler(){
        // 変形してマージ、ソート
        let itms = DeliveryPlanWork.convertWotkToEvent(this.addItems)
        // removeした検索アイテムは除く
        itms = itms.filter((itm) =>{
            const isExistDeleteItem = this.deleteItems.some((deleteItem) => {
              return itm.start_time === deleteItem.start_time || itm.end_time === deleteItem.end_time
            })
            return !isExistDeleteItem
        })
        // 検索結果レコードは新規登録扱い
        itms.forEach((itm) => {
          itm.works.forEach((work) => {
            delete work.before
          })
        })
        this.items.push(...itms)
        this.items.forEach((item) => {
          // Date型が混ざってるとソートでおかしくなる
          item.start_time = DeliveryPlanDrivingStoppingPeriod.convertDateToString(item.start_time)
          item.end_time = DeliveryPlanDrivingStoppingPeriod.convertDateToString(item.end_time)
        })
        this.items.sort((a, b) =>{
          return (a.start_time > b.start_time ? 1 : -1);
        });
        // 住所取得
        this.getAddress();
      }
    },
  }
}
</script>

<style>
.delivery-plan-works-table .v-select {
  width: fit-content;
  min-width: 60%;
}
.delivery-plan-works-table .v-field__field {
}
.delivery-plan-works-table .update-dailyreport-botton {
  border-radius: 3px;
  padding: 3px;
  margin: 1px;
}
.delivery-plan-works-table .update-dailyreport-botton>span {
  display: block;
}
.delivery-plan-works-table .add-button {
  color: white;
  background-color: green;
}
.delivery-plan-works-table .edit-button {
  color: white;
  background-color: blue;
}
.delivery-plan-works-table .save-button {
  color: white;
  background-color: orange;
}
.delivery-plan-works-table .delete-button {
  color: white;
  background-color: red;
}

.delivery-plan-works-table td {
  height: 34px;
}

.delivery-plan-works-table .notification-message {
  color: blue;
  margin: 0px;
}
.delivery-plan-works-table .error-message {
  color: red;
  margin: 0px;
}
.delivery-plan-works-table .work-table-input-box{
  padding: 6px;
  border-radius: 4px;
}
.allocated-text{
  color: red;
}
</style>
