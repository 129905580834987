<template>
  <table class="w-100">
    <thead>
      <tr>
        <th>車番</th>
        <th>出庫</th>
        <th>出庫時メーター</th>
        <th>帰庫</th>
        <th>帰庫時メーター</th>
        <th>総走行距離</th>
        <th>実車距離</th>
        <th>一般/高速/専用道距離</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{deliveryPlan?.car_number}}</td>
        <td>{{deliveryPlan?.leaving_warehouse_date_time}}</td>
        <td>{{roundForView(parseFloat(deliveryPlan?.out_of_service_meter))}}</td>
        <td>{{deliveryPlan?.returning_warehouse_date_time}}</td>
        <td>{{roundForView(parseFloat(deliveryPlan.out_of_service_meter) + parseFloat(deliveryPlan.total_mileage))}}</td>
        <td>{{roundForView(deliveryPlan?.total_mileage)}}</td>
        <td>{{roundForView(deliveryPlan?.actual_vehicle_distance)}}</td>
        <td>
          {{roundForView(deliveryPlan?.general_speed_distance)}}
          /{{roundForView(deliveryPlan?.high_speed_distance)}}
          /0.00
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {
  round
} from "@/services/functions";

export default {
  setup() {
    return {
      round
    }
  },
  props: {
    deliveryPlan: {},
  },
  methods: {
    roundForView(num) {
      return round(num,2).toFixed(2)
    }
  },
  components: {
  },
}
</script>

<style>
</style>
