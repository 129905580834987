import {
  routingAuthorityNames,
  AUTHORITY_CACHE_RETRY_COUNT,
  AUTHORITY_CACHE_RETRY_INTERVAL
} from "@/services/consts"
import getAuthorityResponse from "@/types/responses/getAuthority"
import Api from "@/services/api/ApiServiceFabrick";

const cacheVersion = "authority_v1"
export const authorityURL = "/api/v1/request_user/authority"

class AuthorityCacheApi {
  public static async put(lists: getAuthorityResponse):Promise<void> {
    lists[routingAuthorityNames.Dashboard] = true

    const cache = await caches.open(cacheVersion)
    return cache.put(authorityURL, new Response(JSON.stringify(lists)))
  }

  public static async delete():Promise<boolean> {
    const cache = await caches.open(cacheVersion)
    return cache.delete(authorityURL)
  }

  public static async reflesh():Promise<getAuthorityResponse> {
    this.delete()
    await Api().getAuthority()
    .then((data) => {
      this.put(data)
    })
    .catch(() => {
      this.put({})
    });
    return this.getWithParse()
  }

  public static async getWithParse(retry = 0):Promise<getAuthorityResponse> {
    const cache = await caches.open(cacheVersion)
    const res = await cache.match(authorityURL)
    const txt = res ? await res.text() : undefined

    if (!txt && retry < AUTHORITY_CACHE_RETRY_COUNT) {
      return new Promise(resolve => setTimeout(() => {
        const result = this.getWithParse(retry + 1)
        return resolve(result)
      }, AUTHORITY_CACHE_RETRY_INTERVAL))
    }
    return JSON.parse(txt ?? '{}')
  }
}

export default AuthorityCacheApi
