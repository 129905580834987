

import {defineComponent, PropType} from "vue";
import {sortType} from "@/services/consts";

export default defineComponent({
  setup() {
    return {
      sortType
    }
  },
  data() {
    return {
      selectedDeviceType: null as number | null,
    }
  },
  props: {
    columnTitle: {
      type: String as PropType<string>,
      required: true
    },
    sortField: {
      type: String as PropType<string>,
      required: true
    },
    currentSortType: {
      type: String as PropType<string|undefined>,
      required: true
    },
    currentSortField: {
      type: String as PropType<string|undefined>,
      required: true,
      default: 'def'
    },
  },
  mounted() {
    //
  },
  name: "TableSort",
  computed: {
  },
  emits: ['toggleSort', 'changeSort'],
  watch: {},
  methods: {
    getClassForSort(needSortType: sortType): object {
      if ( this.currentSortField == this.sortField && this.currentSortType == needSortType ){
        return {'font-weight-bold': true}
      }
      return {}
    },
  },
  components: {},
})
