<script setup>
import { reactive, watch, ref } from 'vue';

const props = defineProps({
  email: { type: String },
  errors: { type: Object },
});

const emits = defineEmits(['inputValue']);
const emailValue = ref('');
const data = reactive({ email: '' });
const unwatch = watch(
  () => props.email,
  (propsEmail) => {
    emailValue.value = propsEmail;
    unwatch();
  }
);

watch(emailValue, () => {
  data.email = emailValue;
  emits('inputValue', data);
});
</script>
<template>
  <v-row class="text-form pl-4 pr-3 mb-3">
    <v-col cols="2" class="my-auto">
      <h6 class="pl-4">メール<span style="color: red">✴︎</span></h6>
    </v-col>
    <v-col cols="10" class="pt-0">
      <v-text-field
        v-model="emailValue"
        maxlength="255"
        :error-messages="errors?.email"
      />
    </v-col>
  </v-row>
</template>
