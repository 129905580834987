<template>
  <transition name="fade" v-show="show">
    <div class="v-theme--light message-position">
      <div class="w-100 opacity-75 justify-content-between" :class="className">
        <pre>{{ message }}</pre>
        <span class="material-symbols-outlined" @click="hideFlash">
          close
        </span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    fadeOut: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showFlash() {
      if (this.fadeOut) {
        setTimeout(() => {
          this.hideFlash();
        }, 5000);
      }
    },
    hideFlash() {
      this.$emit('hide');
    },
  },
  computed: {
    className() {
      const defaultClass = 'd-inline-flex ';
      switch (this.type) {
        case 'success':
          return defaultClass + 'bg-success';
        case 'info':
          return defaultClass + 'bg-info';
        case 'warn':
          return defaultClass + 'bg-warning';
        case 'error':
          return defaultClass + 'bg-danger';
        default:
      }
      return '';
    },
  },
  watch: {
    show: {
      handler() {
        if (this.show) {
          this.showFlash();
        }
      },
    },
  },
};
</script>

<style scoped>
.fade-enter {
  opacity: 1;
}
.fade-enter-active {
  transition: opacity 0s;
}
.fade-enter-to {
  opacity: 1;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-leave-to {
  opacity: 0;
}
pre {
  margin: 0px;
  padding: 0.5em 1em;
  color: white;
}
span {
  color: white;
  padding: 0.4em;
}
.message-position {
  margin-top: 48px;
}
@media (min-width: 960px) {
  .message-position {
    margin-left: 56px;
  }
}
</style>
