<template>
  <template v-if="!!formInputs?.form?.device">
    <p class="vehicle-ini-setting-title">DEVICE</p>
    <div class="row mr-3 v-theme--light">
      <template v-for="title, key in keys" v-bind:key="key">
        <Input
          :label="title"
          v-model="form[key]"
          :formKey="key"
          parentKey="device"
          :formInputs="formInputs"
          :readonly="selectDefault && readonlyInputs.includes(key)"
          :errors="errors[errorKeyPrefix+'.'+key]"
        />
      </template>
      <MqttCidInput
        v-model="form.mqtt_cid"
        :errors="errors[errorKeyPrefix+'.mqtt_cid']"
        :formInputs="formInputs"
        :operationOfficeId="selectedOperationOffice?.id ?? selectedOperationOffice"
        :deviceId="selectedDevice?.id ?? selectedDevice"
      />
    </div>
  </template>
</template>

<script>
import Api from "@/services/api/ApiServiceFabrick";
import Input from "@/components/VehicleFormParts/SettingFormInput.vue";
import MqttCidInput from "@/components/VehicleFormParts/MqttCidInput.vue";

export default {
  props: {
    modelValue: {},
    selectedDevice: {},
    selectedOperationOffice: {},
    formInputs: {},
    selectDefault: {
      type: Boolean,
      default: false
    },
    errors: {},
  },
  data() {
    return {
      form: {
        'update_master_id': '',
        'connection_type': '',
        'ip_address': '',
        'port': '',
        'keepalive_timer': '',

        'username': '',
        'password': '',
        'will_retain': '',
        'will_qos': '',
        'will_flag': '',
        'category': '',
        'http_address': '',
        'filesvr_address': '',
        'auid': '',
        'aupw': '',
        'ini_id': '',
        'ini_pw': '',
        'fw_id': '',
        'fw_pw': '',
        'fileupload_address': '',
        'fileupload_id': '',
        'fileupload_pw': '',
        'sim_apn': '',
        'sim_id': '',
        'sim_pass': '',
      },
      readonlyInputs: [],
      errorKeyPrefix: 'initial_params.device',
      keys: {
        'update_master_id': 'UPDATE_MASTER_ID',
        'connection_type': 'CONNECTION_TYPE',
        'ip_address': 'MQTT Endpoint',
        'port': 'PORT',
        'keepalive_timer': 'KEEPALIVE',

        'username': 'USER_NAME',
        'password': 'PASSWORD',
        'will_retain': 'WILL_RETAIN',
        'will_qos': 'WILL_QOS',
        'will_flag': 'WILL_FLAG',
        'category': 'CATEGORY',
        'http_address': 'HTTP_ADDRESS',
        'filesvr_address': 'FILESVR_ADDRESS',
        'auid': 'AUID',
        'aupw': 'AUPW',
        'ini_id': 'INIID',
        'ini_pw': 'INIPW',
        'fw_id': 'FWID',
        'fw_pw': 'FWPW',
        'fileupload_address': 'FILEUPLOAD_ADDRESS',
        'fileupload_id': 'FILEUPLOAD_ID',
        'fileupload_pw': 'FILEUPLOAD_PW',
        'sim_apn': 'SIM_APN',
        'sim_id': 'SIM_ID',
        'sim_pass': 'SIM_PASS',
      }
    }
  },
  methods: {
  },
  watch: {
    modelValue: {
      handler() {
        this.form = Object.assign(this.form, this.modelValue)
      },
      deep: true,
    },
    formInputs: {
      handler() {
        if (!this.formInputs?.form?.device) return
        const key = Object.keys(this.formInputs.default_params)[0]
        this.readonlyInputs = Object.keys(this.formInputs.default_params[key]?.parameters?.device ?? [])
      }
    },
    form: {
      handler() {
        this.$emit('update:modelValue', this.form)
      },
      deep: true
    },
  },
  components: {
    Input,
    MqttCidInput,
  },
}
</script>

<style>
</style>
