<script setup>
import { ref, computed, onMounted } from 'vue'
const props = defineProps({
  btnClass: {
    type: String,
    default: "exec-btn mt-0 ml-1"
  },
  title: {
    type: String,
  },
  items: [],
  isLoading: {
    type: Boolean,
    default: false
  },
  elementId: {
    type: String,
    default: "drop-down-buttons"
  },
})

const emit = defineEmits(['selectDropDownItem'])
const btnStatus = ref(false)

onMounted(() => {
  document.addEventListener("click", handleClickOutside)
})

const isActiveDropDown = computed(() => {
  return btnStatus.value
})

function changeBtnStatus (boolean) {
  btnStatus.value = boolean
}
function selectItem (item) {
  changeBtnStatus(false)
  emit('selectDropDownItem', item)
}

function handleClickOutside (event) {
  if (!document.getElementById(props.elementId)) return
  if (!document.getElementById(props.elementId).contains(event.target)) {
    changeBtnStatus(false)
  }
}
</script>

<template>
  <div
    :id="props.elementId"
    class="btn-wrapper"
  >
    <v-btn ref="btnElement" :class="btnClass" :loading="props.isLoading" @click="changeBtnStatus(true)">
      {{ props.title }}
    </v-btn>
    <div v-if="isActiveDropDown" class="drop-down-menue ml-1" :style="btnWidth">
      <div class="candidate-list py-0 d-flex text-nowrap" v-for="(item, key) in items" :key="key" @click="selectItem(key)">
        <span class="p-3 mx-auto" >{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .btn-wrapper {
    position: relative;
  }
  .drop-down-menue {
    position: absolute;
    border: 1px solid white;
    border-radius: 3px;
    z-index: 1001;
    background-color: white;
    box-shadow: 0px 5px 5px 0px lightgray;
  }
  .exec-btn {
    background-color: rgb(21, 130, 156);
    color: white !important;
  }
  .candidate-list {
    cursor: pointer;
    font-size: 90%;
  }.candidate-list:hover {
    background: #f8f9fa;
  }
</style>