<template>
<GDialog
  v-if="dialogState"
  v-model="dialogState"
  max-width="90%"
  transition="custom-from-bottom-transition"
>
  <div class="modal-header justify-content-center">
    <label>プレビュー</label>
  </div>
  <ModalBody
    :deliveryPlanRecords="deliveryPlanRecords"
    :downloading="downloading"
    @close="closeModal"
    @submit="submit"
  />
</GDialog>
</template>

<script>
import 'gitart-vue-dialog/dist/style.css'
import { GDialog } from 'gitart-vue-dialog'
import ModalBody from '@/components/Every24hoursReportModalParts/ModalBody'
import Api from "@/services/api/ApiServiceFabrick";
import { useAuth } from "@/services/user/Auth0UserProvider"

export default {
  props: {
    deliveryPlanRecords: [],
    operationOfficeId: null
  },
  data: () => ({
    dialogState: false,
    downloading: false
  }),
  setup() {
    const { loginWithRedirect } = useAuth();
    return { loginWithRedirect }
  },
  methods: {
    closeModal() {
      this.dialogState = false
      this.$emit('set', [])
    },
    submit(reportContents) {
      this.downloading = true
      const params = {
        type: "App\\Services\\OutputReports\\Every24hours\\PdfCreateService",
        operation_office_id: this.operationOfficeId,
        report_contents: JSON.stringify(reportContents)
      }
      Api().createJobStatus(params).then((data) => {
        this.pollingJobStatus(data.id)
      }).catch((error) => {
        const res = error.response
        switch (res.status) {
          case 401:
            this.loginWithRedirect({ appState: { targetUrl: location.pathname } })
            break
          case 403:
            location.reload()
            break
          case 422:
        }
        this.downloading = false
      })
    },
    pollingJobStatus(id) {
      const interval = setInterval(() => {
        Api().getJobStatus(id).then((res) => {
          if(res.status == "completed") {
            this.download(res.file_path)
            clearInterval(interval)
            this.downloading = false
          }
          if (res.status == "failed") {
            alert(res.output_contents.message)
            clearInterval(interval)
            this.downloading = false
          }
        })
      }, 3000)
    },
    download(path) {
      Api().fileDownload(path)
    },
  },
  watch: {
    deliveryPlanRecords: {
      handler() {
        if (this.deliveryPlanRecords.length) this.dialogState = true
      }
    }
  },
  components: {
    GDialog,
    ModalBody,
  },
}
</script>

<style>
.modal-header {
  font-weight: 600;
}
</style>
<style lang="scss">
.custom-rotate-transition {
  &-enter-from {
    transform: translate(0, 30px) rotate(20deg);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, 30px) rotate(10deg);
    opacity: 0;
  }
}

.custom-from-bottom-transition {
  &-enter-from {
    transform: translate(0, 100%);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, -30%);
    opacity: 0;
  }
}
  .error *, .v-input--error .v-messages__message {
    color: rgb(var(--v-theme-error));
    opacity: 1;
  }
</style>
