import Api from "@/services/api/ApiServiceFabrick";
import Role from "@/types/SelfRole";

export default class SelectRoleChecker {
  static SELECTED_ROLE_KEY = 'selected_role';
  static ROLE_COUNT_KEY = 'role_count';

  static selectRole(role: Role): void {
    localStorage.setItem(this.SELECTED_ROLE_KEY, JSON.stringify(role))
  }

  static getCurrentRole(): Role|null {
    const role = localStorage.getItem(this.SELECTED_ROLE_KEY)
    if (role) return JSON.parse(role)
    return null
  }

  static removeCurrentRole(): Role|null {
    localStorage.removeItem(this.SELECTED_ROLE_KEY)
    return null
  }

  static async getSelfRoles(): Promise<Role[]> {
    const res = await Api().getSelfRoles();
    localStorage.setItem(this.ROLE_COUNT_KEY, String(res.length))
    return Promise.resolve(res)
  }

  static async getRoleCount() {
    let count = localStorage.getItem(this.ROLE_COUNT_KEY)
    if (count === null) await this.getSelfRoles()
    count = localStorage.getItem(this.ROLE_COUNT_KEY) ?? '0'
    return Promise.resolve(parseInt(count));
  }
}
