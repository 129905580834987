<template>
  <div class="group-form d-flex" style="position: relative; min-height: 45vw">
    <div class="content p-4 w-100">
      <h4 class="card-title mt-0">{{ title }}</h4>
      <div class="row mr-3 v-theme--light">
        <div class="mb-4">
          <v-row class="text-form pl-4 pr-3 mb-3">
            <v-col cols="2">
              <h6 class="pl-4">管理会社<span style="color: red">✴︎</span></h6>
            </v-col>
            <v-col cols="10" class="pt-0">
              <ManagementOfficeAutoComplete
                v-model="managementOffice"
                selectFirstIfOnlyOne
                :error-messages="errors['management_officeable_id'] ?? []"
                :readonly="loading"
                :initialManagementId="initialManagementId"
                :initialManagementType="initialManagementType"
              />
            </v-col>
          </v-row>
          <v-row class="text-form pl-4 pr-3 mb-3">
            <v-col cols="2">
              <h6 class="pl-4">運営会社<span style="color: red">✴︎</span></h6>
            </v-col>
            <v-col cols="10" class="pt-0">
              <SingleOperationOfficeAutoComplete
                v-model="operationOffice"
                :managementOffice="managementOffice"
                selectFirstIfOnlyOne
                :error-messages="errors['operation_office_id'] ?? []"
                :readonly="loading"
                :initialOperationId="initialOperationId"
              />
            </v-col>
          </v-row>
          <v-row class="text-form pl-4 pr-3 mb-3">
            <v-col cols="2">
              <h6 class="pl-4">グループ名<span style="color: red">✴︎</span></h6>
            </v-col>
            <v-col cols="10" class="pt-0">
              <ValidationErrors :messages="errors['group_name'] ?? []">
                <v-text-field
                  class="pt-2"
                  v-model="groupName"
                  :readonly="loading"
                  :error="errors['group_name']?.length ?? 0 > 0"
                />
              </ValidationErrors>
            </v-col>
          </v-row>
          <v-row class="text-form pl-4 pr-3 mb-3">
            <v-col cols="2">
              <h6 class="pl-4">グループ種類</h6>
            </v-col>
            <v-col cols="10" class="pt-0">
              <v-radio-group
                v-model="groupType"
                class="radio-group-row"
                :disabled="loading"
              >
                <v-radio
                  value="driver"
                  label="ドライバー"
                  color="blue"
                  style="max-width: 130px; font-weight: bold"
                ></v-radio>
                <v-radio
                  value="vehicle"
                  label="車両"
                  color="blue"
                  style="max-width: 100px; font-weight: bold"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="text-form pl-4 pr-3 mb-3">
            <v-col cols="2">
              <h6 class="pl-4">所属{{ groupTypes[groupType] }}</h6>
            </v-col>
            <v-col cols="10" class="pt-0">
              <v-row>
                <v-col cols="5">
                  <div>
                    <div
                      style="font-weight: bold; font-size: 14px"
                      class="pt-2"
                    >
                      一覧
                    </div>
                    <select
                      :disabled="loading"
                      class="form-control"
                      style="height: 200px"
                      multiple="multiple"
                      v-model="selecting[this.groupType]['list']"
                    >
                      <option
                        v-for="(item, index) in groupList[groupType]['list']"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </v-col>
                <v-col>
                  <div class="text-center" style="margin-top: 40px">
                    <div>
                      <v-btn
                        style="
                          color: white;
                          font-weight: bold;
                          background-color: #3388dc;
                        "
                        class="switch-btn mb-2"
                        @click="switchItems('list', 'selected')"
                        :disabled="loading"
                      >
                        ▶︎
                      </v-btn>
                    </div>
                    <div>
                      <v-btn
                        class="switch-btn"
                        style="
                          color: white;
                          font-weight: bold;
                          background-color: #3388dc;
                        "
                        @click="switchItems('selected', 'list')"
                        :disabled="loading"
                      >
                        ◀︎
                      </v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="5">
                  <div>
                    <div
                      style="font-weight: bold; font-size: 14px"
                      class="pt-2"
                    >
                      選択済
                    </div>
                    <select
                      :disabled="loading"
                      class="form-control"
                      style="height: 200px"
                      multiple="multiple"
                      v-model="selecting[this.groupType]['selected']"
                    >
                      <option
                        v-for="(item, index) in groupList[groupType][
                          'selected'
                        ]"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="text-form pl-4 pr-3">
            <v-col cols="2">
              <h6 class="pl-4">コメント</h6>
            </v-col>
            <v-col cols="10" class="pt-0">
              <ValidationErrors :messages="errors['comment'] ?? []">
                <v-textarea
                  class="small pt-2"
                  v-model="comment"
                  rows="3"
                  :readonly="loading"
                  :error="errors['comment']?.length ?? 0 > 0"
                />
              </ValidationErrors>
            </v-col>
          </v-row>
        </div>
        <div class="d-flex justify-content-end">
          <v-btn
            class="exec-btn"
            :loading="running"
            :disabled="loading"
            @click="createOrUpdate"
            v-show="createAndUpdateAuthority"
          >
            保存
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/services/api/ApiServiceFabrick';
import 'gitart-vue-dialog/dist/style.css';
import ManagementOfficeAutoComplete from '@/components/AutoCompletes/ManagementOfficeAutoComplete';
import SingleOperationOfficeAutoComplete from '@/components/AutoCompletes/SingleOperationOfficeAutoComplete';
import ValidationErrors from '@/components/Common/ValidationErrors';
import AuthorityCacheApi from '@/services/models/AuthorityCacheApi';
import { useAuth } from '@/services/user/Auth0UserProvider';
import { scrollToErrorComponent } from '@/services/validators';

export default {
  props: {
    showFlash: {
      type: Function,
    },
    switchContentLoading: {
      type: Function,
    },
  },
  data: () => ({
    title: '',
    groupId: null,
    managementOffice: undefined,
    operationOffice: undefined,
    groupName: null,
    groupType: 'driver',
    groupTypes: { driver: 'ドライバー', vehicle: '車両' },
    groupList: {
      driver: { list: [], selected: [] },
      vehicle: { list: [], selected: [] },
    },
    comment: null,
    errors: {},
    loading: false,
    running: false,
    createAndUpdateAuthority: false,
    loginWithRedirect: null,
    selecting: {
      driver: { list: [], selected: [] },
      vehicle: { list: [], selected: [] },
    },
    initialManagementId: null,
    initialManagementType: null,
    initialOperationId: null,
  }),
  mounted() {
    this.switchContentLoading(true);
    this.groupId = this.$route.params.id;
    this.title = this.groupId ? 'グループ編集' : 'グループ作成';
    document.title = this.title;

    (async () => {
      const authorityList = await AuthorityCacheApi.getWithParse();
      if (this.groupId) {
        this.createAndUpdateAuthority = authorityList['octlink.group.update'];
      } else {
        this.createAndUpdateAuthority = authorityList['octlink.group.create'];
      }

      if (!this.groupId && !this.createAndUpdateAuthority) {
        this.showFlash('権限がありません', 'error', false);
        this.$router.push('/groups');
        this.switchContentLoading(false);
        return;
      }

      const { loginWithRedirect } = useAuth();
      this.loginWithRedirect = loginWithRedirect;

      if (this.groupId) return this.getGroup()
      this.switchContentLoading(false);
    })();
  },
  methods: {
    createOrUpdate() {
      this.running = true;
      const accountIds = this.groupList['driver']['selected'].map(
        (account) => account['id']
      );
      const vehicleIds = this.groupList['vehicle']['selected'].map(
        (vehicle) => vehicle['id']
      );

      const params = {
        id: this.groupId,
        management_officeable_id: this.managementOffice?.id,
        management_officeable_type: this.managementOffice?.type,
        operation_office_id: this.operationOffice?.id,
        group_type: this.groupType,
        group_name: this.groupName,
        account_ids: accountIds,
        vehicle_ids: vehicleIds,
        comment: this.comment,
      };

      const apiRequetFunc = this.groupId
        ? Api().updateGroup
        : Api().createGroup;
      apiRequetFunc(params)
        .then((_data) => {
          this.running = false;
          this.showFlash(`保存に成功しました。`, 'success');
          this.$router.push('/groups');
        })
        .catch((error) => {
          const res = error.response;
          switch (res.status) {
            case 401:
              this.loginWithRedirect({
                appState: { targetUrl: location.pathname },
              });
              break;
            case 403:
              location.reload();
              break;
            case 422:
              this.errors = res.data.errors;
              scrollToErrorComponent();
          }
          this.running = false;
        });
    },
    getGroup() {
      Api()
        .getGroup(this.groupId)
        .then((data) => {
          this.managementOffice = {
            id: data.management_officeable_id,
            type: data.management_officeable_type,
          }
          this.initialManagementId = data.management_officeable_id;
          this.initialManagementType = data.management_officeable_type;
          this.initialOperationId = data.operation_office_id;

          this.groupName = data.group_name;
          this.groupType = data.group_type;
          data.accounts.forEach((account) => {
            this.groupList['driver']['selected'].push({
              id: account.id,
              name: account.last_name + ' ' + account.first_name,
            });
          });
          data.vehicles.forEach((vehicle) => {
            this.groupList['vehicle']['selected'].push({
              id: vehicle.id,
              name: vehicle.number_plate,
            });
          });
          this.comment = data.comment;
        })
        .catch((error) => {
          const res = error.response;
          if (res.status == 403) {
            this.showFlash('編集権限がありません。', 'error', true);
            this.$router.push('/groups/');
          }
        });
    },
    async getDriversAndVehicles() {
      this.loading = true;
      const driversParams = this.createParams('driver');
      const vehiclesParams = this.createParams('vehicle');

      const [drivers, vehicles] = await Promise.all([
        this.getDrivers(driversParams),
        this.getVehicles(vehiclesParams),
      ]);
      this.setDriversToGroupList(drivers);
      this.setVehiclesToGroupList(vehicles);
      this.switchContentLoading(false);

      if (this.createAndUpdateAuthority) this.loading = false;
    },
    getDrivers(params) {
      return Api().getDrivers(params);
    },
    setDriversToGroupList(drivers) {
      const selectedIds = this.groupList['driver']['selected'].map(
        (account) => account['id']
      );
      drivers.forEach((account) => {
        if (!selectedIds.includes(account.id)) {
          this.groupList['driver']['list'].push({
            id: account.id,
            name: account.last_name + ' ' + account.first_name,
          });
        }
      });
    },
    getVehicles(params) {
      return Api().autoComplete.vehicles(params, true);
    },
    setVehiclesToGroupList(vehicles) {
      const selectedIds = this.groupList['vehicle']['selected'].map(
        (vehicle) => vehicle['id']
      );
      vehicles.forEach((vehicle) => {
        if (!selectedIds.includes(vehicle.id)) {
          this.groupList['vehicle']['list'].push({
            id: vehicle.id,
            name: vehicle.number_plate,
          });
        }
      });
    },
    createParams(groupType) {
      const params = {
        management_officeable_id: this.managementOffice?.id,
        management_officeable_type: this.managementOffice?.type,
      };

      if (groupType == 'driver') {
        params['status'] = true;
        params['id'] = this.operationOffice?.id;
        return params;
      }

      if (groupType == 'vehicle') {
        params['status'] = 'all';
        params['operation_office_id'] = this.operationOffice?.id;
        return params;
      }
    },
    resetGroupList() {
      this.selecting = {
        driver: { list: [], selected: [] },
        vehicle: { list: [], selected: [] },
      };
      this.groupList = {
        driver: { list: [], selected: [] },
        vehicle: { list: [], selected: [] },
      };
    },
    switchItems(sender, recipient) {
      const selecting = this.selecting[this.groupType][sender];
      if (!selecting.length) return;

      const selectingItems = [];
      const remainingItems = [];
      this.groupList[this.groupType][sender].forEach((item) => {
        if (selecting.includes(item['id'])) {
          selectingItems.push(item);
        } else {
          remainingItems.push(item);
        }
      });
      this.groupList[this.groupType][recipient] =
        this.groupList[this.groupType][recipient].concat(selectingItems);
      this.groupList[this.groupType][sender] = remainingItems;
    },
  },
  watch: {
    groupType: {
      handler() {
        this.selecting = {
          driver: { list: [], selected: [] },
          vehicle: { list: [], selected: [] },
        };
      },
    },
    operationOffice: {
      handler(office, before) {
        if (before) this.resetGroupList();
        if (office) this.getDriversAndVehicles();
      },
    },
  },
  beforeUnmount() {
    this.switchContentLoading(false);
  },
  components: {
    ManagementOfficeAutoComplete,
    SingleOperationOfficeAutoComplete,
    ValidationErrors,
  },
};
</script>

<style scoped lang="scss">
.modal-header {
  font-weight: 600;
}
.group-form .switch-btn:hover {
  opacity: 0.5;
}

.custom-rotate-transition {
  &-enter-from {
    transform: translate(0, 30px) rotate(20deg);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, 30px) rotate(10deg);
    opacity: 0;
  }
}

.custom-from-bottom-transition {
  &-enter-from {
    transform: translate(0, 100%);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, -30%);
    opacity: 0;
  }
}
.error *,
.v-input--error .v-messages__message {
  color: rgb(var(--v-theme-error));
  opacity: 1;
}
</style>
