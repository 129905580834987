<template>
  <template v-if="totalPage > 1">
    <div class="d-flex justify-content-end pagenate">

      <button class="link-div">
        <span class="material-symbols-outlined" @click="changePage(1)">
          keyboard_double_arrow_left
        </span>
      </button>
      <button class="link-div" @click="changePage(currentPage - 1)">
        <span class="material-symbols-outlined">
          keyboard_arrow_left
        </span>
      </button>
      <button v-for="page in pages" :key="page" class="link-div" :class="page==currentPage ? 'selected' : ''" @click="changePage(page)">
        {{page}}
      </button>
      <button class="link-div" @click="changePage(currentPage + 1)">
        <span class="material-symbols-outlined">
          keyboard_arrow_right
        </span>
      </button>
      <button class="link-div" @click="changePage(totalPage)">
        <span class="material-symbols-outlined">
          keyboard_double_arrow_right
        </span>
      </button>
    </div>
  </template>
</template>

<script>
  export default {
    props: {
      range: {
        type: Number,
        default: 2
      },
      totalPage: {
        type: Number,
        default: 1
      },
      modelValue: null,
    },
    data() {
      return {
        currentPage: 1,
      }
    },
    computed: {
      pages() {
        const pages = []
        const startIndex = this.startIndex()

        for(let i = startIndex; i <= startIndex + this.range * 2; i++) {
          if (i > 0 && i <= this.totalPage) {
            pages.push(i)
          }
        }
        return pages
      },
      existPrevPages() {
        const startIndex = this.startIndex()
        return startIndex > 1
      },
      existNextPages() {
        const startIndex = this.startIndex()
        return startIndex + this.range * 2 < this.totalPage
      },
    },
    methods: {
      startIndex() {
        let startIndex = this.currentPage - this.range
        if (this.currentPage + this.range > this.totalPage) {
          startIndex = this.totalPage - this.range * 2
        }
        startIndex = startIndex <= 0 ? 1 : startIndex
        return startIndex
      },
      changePage(page) {
        if (page < 1) {
          this.currentPage = 1
        } else if (page > this.totalPage) {
          this.currentPage = this.totalPage
        } else {
          this.currentPage = page
        }
      },
    },
    watch: {
      currentPage: {
        handler() {
          this.$emit('update:modelValue', this.currentPage)
        }
      },
      modelValue: {
        handler() {
          this.currentPage = this.modelValue
        }
      }
    },
    components: {
    }
  }

</script>

<style>
.pagenate {
  margin: 2px;
}
.pagenate .link-div {
  height: 2rem;
  width: 2rem;
}
.pagenate .selected {
  border: 1px solid gray;
  border-radius: 5px;
  background-color: ivory;
}
.pagenate span {
  display: block;
}
</style>
