import conn from "@/services/ApiConnection"
import ApiProvider from "@/services/api/providers/AutoCompletes/ApiProvider";

import getDailyReportLayoutResponse from "@/types/responses/AutoCompletes/getDailyReportLayout"
import getApiDailyReportLayoutResponse from "@/types/apiresponses/AutoCompletes/getApiDailyReportLayout"
import getDeviceResponse from "@/types/responses/getDevice"
import getVehicleResponse from "@/types/responses/getVehicle"
import { dailyReportPageStructures } from "@/services/consts"

class AutoComplete implements ApiProvider {
  async dailyReportLayouts():Promise<getDailyReportLayoutResponse[]> {
    const res = await conn.get(`/api/v1/daily_report_layouts`)
    return new Promise((resolve) => {
      const data = res?.data || {}
      const ret = data.map((d:getApiDailyReportLayoutResponse) => {
        const page_structure = dailyReportPageStructures[d.page_structure] ?? '';
        const keys = Object.keys(d)
        const chartDisplay = [] as string[]
        keys.forEach((key:string) => {
          if (d[key as keyof typeof d] == 1) chartDisplay.push(key)
        })
        return {
          name: d.name,
          selected: {
            page_structure: page_structure,
            chartDisplay: chartDisplay,
          }
        }
      })
      resolve(ret)
    })
  }
  public async devices(params = {}, cache = false):Promise<getDeviceResponse[]> {
    const url = '/api/v1/auto_completes/devices'

    const res = await conn.get(url, {params: params, cache: cache})
    return new Promise((resolve) => {
      resolve(res?.data || [])
    })
  }
  public async vehicles(params = {}, cache = false,):Promise<getVehicleResponse[]> {
    const url = '/api/v1/auto_completes/vehicles'

    const res = await conn.get(url, {params: params, cache: cache})
    return new Promise((resolve) => {
      resolve(res?.data || [])
    })
  }
}

export default AutoComplete;
