import { createI18n } from 'vue-i18n'
import ja from '@/lang/ja'
import en from '@/lang/en'

const messages = {
  en: en,
  ja: ja,
}

// 2. Create i18n instance with options
export default createI18n({
  locale: 'ja', // set locale
  fallbackLocale: 'ja', // set fallback locale
  globalInjection: true, // Refer a global scope Composer instance of i18n
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
})