<script setup>
import StatusChangeConfirmModal from "@/components/Common/StatusCangeConfirmModal"
import { formatDate } from "@/services/functions"
import { deviceTypes } from "@/services/consts"
import EditLinkIcon from "@/components/Common/EditLinkIcon"

const props = defineProps({
  selfRoleId: {
    type: Number,
    defaulf: 2
  },
  devices: [],
  changeStatusAuthority: {
    type: Boolean,
    default: false
  },
  listLoading: {
    type: Boolean,
    default: true
  },
  updatingStatus: {
    type: Boolean,
    default: false
  },
  modelValue: null,
})

const emit = defineEmits(['changeSortOrder', 'changeStatus'])
const header = ['ID', 'メーカー', '製造型番', 'デバイスID', 'SIM ID', 'IMEI', '型番', 'F/W', '管理会社名', '運営会社名', '設置車両', 'ステータス', '最終更新日', '最終更新者', '編集']
const sortableItems = {'ID': 'id', 'メーカー': 'maker',  '製造型番': 'model_number', 'デバイスID': 'device_id', '管理会社名': 'officeable_name', '運営会社名': 'operation_office', '設置車両': 'installation_car', 'ステータス': 'status', '最終更新日': 'updated_at', '最終更新者': 'updated_by'}
const currentSort = { item: 'id', type: 'ASC' }
const sortTypes = ['ASC', 'DESC']

function datetimeFormat (datetime) {
  const d = new Date(datetime)
  return formatDate(d)
}

function firmwareVersion (installationVehicle) {
  return installationVehicle?.firmwares?.[0]?.version
}

function emitChangeSortOrder (sortItem, sortType = null) {
  if (!sortableItem(sortItem)) return
  const item = sortableItems[sortItem]
  if (sortType) {
    currentSort.type = sortType
  } else {
    if (currentSort.item == item) {
      const type = sortTypes.find(type => type != currentSort.type)
      currentSort.type = type
    } else {
      currentSort.type = sortTypes[0]
    }
  }
  currentSort.item = item
  emit('changeSortOrder', currentSort.item, currentSort.type)
}

function sortableItem (itemName) {
  return Object.keys(sortableItems).includes(itemName)
}

function addClassIfSortable (itemName) {
  if (!sortableItem(itemName)) return
  return 'sort-icon pl-2 pt-1';
}

function highLightIfCurrentSort (item, type) {
  if (currentSort.item != sortableItems[item] || currentSort.type != type) return
  return 'font-weight: bold;'
}

function applyLeftStyle (index) {
  if (index == 0) return 'left: 0;'
}

function emitChangeStatus (params, key) { emit('changeStatus', params, key) }

function modelType (model) {
  if (!model) return ''
  const modelType = deviceTypes.find(type => type.id == model)
  return modelType.type
}
</script>

<template>
  <div class="list mb-2">
    <template v-if="listLoading">
      <div class="list-loading">
        <label>読み込み中</label>
      </div>
    </template>
    <v-table>
      <thead>
        <tr class="table-header-row">
          <th v-for="(item, index) in header" :key="index" class="table-header column-space" :style="applyLeftStyle(index)">
            <div class="d-flex">
              <span v-show="sortableItem(item)" style="display: inline-block;">
                <div class="nc-icon nc-stre-up sort-icon" @click="emitChangeSortOrder(item, sortTypes[0])" :style="highLightIfCurrentSort(item, sortTypes[0])" style="display: block;" />
                <div class="nc-icon nc-stre-down sort-icon" @click="emitChangeSortOrder(item, sortTypes[1])" :style="highLightIfCurrentSort(item, sortTypes[1])" style="display: block;" />
              </span>
              <span :class="addClassIfSortable(item)" @click="emitChangeSortOrder(item)">{{item}}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="devices?.length != 0">
          <tr v-for="device in devices" :key="device.id">
            <td class="column-space fix-td">{{ device.id }}</td>
            <td class="column-space">{{ device.maker.name }}</td>
            <td class="column-space">{{ device.model_number }}</td>
            <td class="column-space">{{ device.device_id }}</td>
            <td class="column-space">{{ device.sim_id }}</td>
            <td class="column-space">{{ device.imei }}</td>
            <td class="column-space">{{ modelType(device.model_type) }}</td>
            <td class="column-space">{{ firmwareVersion(device.installation_vehicle) }}</td>
            <td class="column-space">{{ device.management_officeable.company_name }}</td>
            <td class="column-space">{{ device.operation_office.operation_office_name }}</td>
            <td class="column-space">{{ device.installation_vehicle?.number_plate}}</td>
            <td class="column-space">
              <StatusChangeConfirmModal
                :item="device"
                itemName="デバイス"
                :updatableStatusButton="changeStatusAuthority"
                :updatingStatus="updatingStatus"
                @submit="emitChangeStatus"
              />
            </td>
            <td class="column-space">{{datetimeFormat(device.updated_at)}}</td>
            <td class="column-space">{{device.updated_by.last_name + ' ' + device.updated_by.first_name}}</td>
            <td v-show="selfRoleId == 1" class="column-space">
              <EditLinkIcon :name="'DeviceForm'" :id="device.id" />
            </td>
          </tr>
        </template>
        <template v-else-if="devices?.length == 0 && !listLoading">
          <tr>
            <td colspan="9">
              表にデータがありません。
            </td>
          </tr>
        </template>
      </tbody>
    </v-table>
  </div>
</template>
<style>
.list {
  position: relative;
}
.list .v-table__wrapper {
  max-height: 45em;
  min-height: 20vw;
  overflow: auto;
}
.list .v-table__wrapper::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.list .v-table__wrapper::-webkit-scrollbar-thumb {
  background-color: rgb(150,150,150);
  border-radius: 5px;
  box-shadow: inset 3px 3px 3px rgba(255,255,255,0.2);
}
.list .v-table__wrapper::-webkit-scrollbar-track {
  background-color: rgb(220,220,220);
  border-radius: 5px;
  box-shadow: inset 3px -3px 5px rgba(0,0,0,0.2);
}
.list table th {
  background-color: #f8f9fa;
  position: sticky;
  top: 0;
}
.list table .fix-td {
  background-color: white;
  position: sticky;
  left: 0;
}
.list table .table-header-row th {
  z-index: 1;
}
.list table .table-header-row th:first-child {
  z-index: 2;
}
.list-loading {
  height: 100%;
  position: absolute;
  z-index: 1000;
  background-color: rgba(80,80,80,0.5);
  right: 0;
  left: 0;
}
.list-loading>label {
  font-weight: bold;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.column-space {
  white-space: nowrap;
}
.list .sort-icon:hover {
  cursor: pointer;
  opacity: 0.5;
}
</style>