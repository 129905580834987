<template>
  <v-row class="text-form pl-4 pr-3 mb-3">
    <v-col cols="3">
      <h6 class="pt-2 pl-4">
        {{ title }}<span v-if="required" style="color: red">✴︎</span>
      </h6>
    </v-col>
    <v-col cols="9" class="pt-0">
      <v-row>
        <v-col cols="2">
          <h6 class="pt-2 pl-4">{{ labels?.[0] }}</h6>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-text-field
            class="small pt-2"
            v-model="firstTextValue"
            density="compact"
            :error-messages="errors[0]"
          />
        </v-col>
        <v-col cols="2">
          <h6 class="pt-2 pl-4">{{ labels?.[1] }}</h6>
        </v-col>
        <v-col cols="4" class="pt-0">
          <v-text-field
            class="small pt-2"
            v-model="lastTextValue"
            density="compact"
            :error-messages="errors[1]"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: String,
    errors: Array,
    labels: Array,
    items: Array,
    itemKeys: Array,
    required: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    firstTextValue: null,
    lastTextValue: null,
  }),
  watch: {
    items: {
      handler(items) {
        this.firstTextValue = items[0];
        this.lastTextValue = items[1];
      },
      deep: true,
    },
    firstTextValue: {
      handler(newTextValue) {
        if (newTextValue == this.items[0]) return;

        this.$emit('updateItem', this.itemKeys[0], newTextValue);
      },
    },
    lastTextValue: {
      handler(newTextValue) {
        if (newTextValue == this.items[1]) return;

        this.$emit('updateItem', this.itemKeys[1], newTextValue);
      },
    },
  },
  components: {},
};
</script>

<style></style>
